/*
*****
1. variables
*************************
*/


// Font Family 
$roboto: 'Roboto', sans-serif;
$caveat: 'Caveat', cursive;


/*    Responsive css   */

// Responsive Variables
$over: 'only screen and (min-width: 1601px)';
$xxl: 'only screen and (min-width: 1401px) and (max-width: 1600px)';
$xl: 'only screen and (min-width: 1200px) and (max-width: 1400px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md:'only screen and (min-width: 768px) and (max-width: 991px)';
$xs:'(max-width: 767px)';
$sm: 'only screen and (min-width: 576px) and (max-width: 767px)';