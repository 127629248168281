/*!
 * Bootstrap v5.1.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=Roboto:wght@100;200;300;400;500;700;900&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: underline; }
  a:hover {
    color: #0a58ca; }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 5rem; } }

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.5rem; } }

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 3rem; } }

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: #6c757d; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem; } }

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6; }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }
  .table > :not(:last-child) > :last-child > * {
    border-bottom-color: currentColor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: 1px 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 1px; }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color); }

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color); }

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color); }

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6; }

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce; }

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7; }

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3; }

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9; }

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4; }

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1; }

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e; }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-control::-webkit-date-and-time-value {
    height: 1.5em; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3; }
  .form-control::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::-webkit-file-upload-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }
  .form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }
  .form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }
  .form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }
  .form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem; }

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px); }

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px); }

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px); }

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }
  .form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 0.25rem; }

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    background-color: #e9ecef; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #212529; }

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    border-color: #0d6efd;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: 0.5; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #b6d4fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-select {
    height: calc(3.5rem + 2px);
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control {
    padding: 1rem 0.75rem; }
    .form-floating > .form-control::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus {
    z-index: 3; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 3; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem; }

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754; }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #198754;
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #198754; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #198754; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1; }
  .was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
  .input-group .form-select:valid:focus,
  .input-group .form-select.is-valid:focus {
    z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #dc3545; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2; }
  .was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
  .input-group .form-select:invalid:focus,
  .input-group .form-select.is-invalid:focus {
    z-index: 3; }

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529; }
  .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65; }

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca; }
  .btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-check:checked + .btn-primary,
  .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be; }
    .btn-check:checked + .btn-primary:focus,
    .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); }
  .btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64; }
  .btn-check:focus + .btn-secondary, .btn-secondary:focus {
    color: #fff;
    background-color: #5c636a;
    border-color: #565e64;
    box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-check:checked + .btn-secondary,
  .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #565e64;
    border-color: #51585e; }
    .btn-check:checked + .btn-secondary:focus,
    .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5); }
  .btn-secondary:disabled, .btn-secondary.disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754; }
  .btn-success:hover {
    color: #fff;
    background-color: #157347;
    border-color: #146c43; }
  .btn-check:focus + .btn-success, .btn-success:focus {
    color: #fff;
    background-color: #157347;
    border-color: #146c43;
    box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-check:checked + .btn-success,
  .btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #146c43;
    border-color: #13653f; }
    .btn-check:checked + .btn-success:focus,
    .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5); }
  .btn-success:disabled, .btn-success.disabled {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-info:hover {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2; }
  .btn-check:focus + .btn-info, .btn-info:focus {
    color: #000;
    background-color: #31d2f2;
    border-color: #25cff2;
    box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-check:checked + .btn-info,
  .btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
  .show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #3dd5f3;
    border-color: #25cff2; }
    .btn-check:checked + .btn-info:focus,
    .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5); }
  .btn-info:disabled, .btn-info.disabled {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720; }
  .btn-check:focus + .btn-warning, .btn-warning:focus {
    color: #000;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-check:checked + .btn-warning,
  .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
  .show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffcd39;
    border-color: #ffc720; }
    .btn-check:checked + .btn-warning:focus,
    .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5); }
  .btn-warning:disabled, .btn-warning.disabled {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37; }
  .btn-check:focus + .btn-danger, .btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-check:checked + .btn-danger,
  .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834; }
    .btn-check:checked + .btn-danger:focus,
    .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5); }
  .btn-danger:disabled, .btn-danger.disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
  .btn-check:focus + .btn-light, .btn-light:focus {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-check:checked + .btn-light,
  .btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
  .show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #f9fafb;
    border-color: #f9fafb; }
    .btn-check:checked + .btn-light:focus,
    .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5); }
  .btn-light:disabled, .btn-light.disabled {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-dark:hover {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21; }
  .btn-check:focus + .btn-dark, .btn-dark:focus {
    color: #fff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-check:checked + .btn-dark,
  .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1a1e21;
    border-color: #191c1f; }
    .btn-check:checked + .btn-dark:focus,
    .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5); }
  .btn-dark:disabled, .btn-dark.disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-check:checked + .btn-outline-primary,
  .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
    .btn-check:checked + .btn-outline-primary:focus,
    .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5); }
  .btn-outline-primary:disabled, .btn-outline-primary.disabled {
    color: #0d6efd;
    background-color: transparent; }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-check:checked + .btn-outline-secondary,
  .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-check:checked + .btn-outline-secondary:focus,
    .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
    color: #6c757d;
    background-color: transparent; }

.btn-outline-success {
  color: #198754;
  border-color: #198754; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
  .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
    box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-check:checked + .btn-outline-success,
  .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
    color: #fff;
    background-color: #198754;
    border-color: #198754; }
    .btn-check:checked + .btn-outline-success:focus,
    .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5); }
  .btn-outline-success:disabled, .btn-outline-success.disabled {
    color: #198754;
    background-color: transparent; }

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0; }
  .btn-outline-info:hover {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
  .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-check:checked + .btn-outline-info,
  .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #0dcaf0;
    border-color: #0dcaf0; }
    .btn-check:checked + .btn-outline-info:focus,
    .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5); }
  .btn-outline-info:disabled, .btn-outline-info.disabled {
    color: #0dcaf0;
    background-color: transparent; }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-check:checked + .btn-outline-warning,
  .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-check:checked + .btn-outline-warning:focus,
    .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning:disabled, .btn-outline-warning.disabled {
    color: #ffc107;
    background-color: transparent; }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-check:checked + .btn-outline-danger,
  .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-check:checked + .btn-outline-danger:focus,
    .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger:disabled, .btn-outline-danger.disabled {
    color: #dc3545;
    background-color: transparent; }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
    box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-check:checked + .btn-outline-light,
  .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-check:checked + .btn-outline-light:focus,
    .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light:disabled, .btn-outline-light.disabled {
    color: #f8f9fa;
    background-color: transparent; }

.btn-outline-dark {
  color: #212529;
  border-color: #212529; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
    box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-check:checked + .btn-outline-dark,
  .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-check:checked + .btn-outline-dark:focus,
    .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
      box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5); }
  .btn-outline-dark:disabled, .btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent; }

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline; }
  .btn-link:hover {
    color: #0a58ca; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  line-height: 2rem;
  z-index: 1000;
  display: none;
  min-width: 12rem;
  padding: 0.5rem 0;
  padding-top: 1rem;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem; }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529; }

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item {
    color: #dee2e6; }
    .dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.15); }
    .dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
      color: #fff;
      background-color: #0d6efd; }
    .dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
      color: #adb5bd; }
  .dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15); }
  .dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6; }
  .dropdown-menu-dark .dropdown-header {
    color: #adb5bd; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn ~ .btn,
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: #0a58ca; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6;
      isolation: isolate; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0.25rem; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas-header {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas-header {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas-header {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas-header {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas-header {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: inherit;
      bottom: 0;
      z-index: 1000;
      flex-grow: 1;
      visibility: visible !important;
      background-color: transparent;
      border-right: 0;
      border-left: 0;
      transition: none;
      transform: none; }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
      height: auto;
      border-top: 0;
      border-bottom: 0; }
    .navbar-expand-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas-header {
    display: none; }
  .navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none; }
  .navbar-expand .offcanvas-top,
  .navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0; }
  .navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55); }
  .navbar-light .navbar-text a,
  .navbar-light .navbar-text a:hover,
  .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .navbar-text a,
  .navbar-dark .navbar-text a:hover,
  .navbar-dark .navbar-text a:focus {
    color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: 1rem; }

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-group > .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: #0c63e4;
    background-color: #e7f1ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125); }
    .accordion-button:not(.collapsed)::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      transform: rotate(-180deg); }
  .accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .accordion-item:first-of-type {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .accordion-item:first-of-type .accordion-button {
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }
  .accordion-item:last-of-type {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
    .accordion-item:last-of-type .accordion-button.collapsed {
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
    .accordion-item:last-of-type .accordion-collapse {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem; }

.accordion-body {
  padding: 1rem 1.25rem; }

.accordion-flush .accordion-collapse {
  border-width: 0; }

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .accordion-flush .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush .accordion-item:last-child {
    border-bottom: 0; }
  .accordion-flush .accordion-item .accordion-button {
    border-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: #0a58ca;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    color: #0a58ca;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25); }

.page-item:not(:first-child) .page-link {
  margin-left: -1px; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6; }

.page-link {
  padding: 0.375rem 0.75rem; }

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe; }
  .alert-primary .alert-link {
    color: #06357a; }

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8; }
  .alert-secondary .alert-link {
    color: #34383c; }

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc; }
  .alert-success .alert-link {
    color: #0c4128; }

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb; }
  .alert-info .alert-link {
    color: #04414d; }

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5; }
  .alert-warning .alert-link {
    color: #523e02; }

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7; }
  .alert-danger .alert-link {
    color: #6a1a21; }

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light .alert-link {
    color: #4f5050; }

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf; }
  .alert-dark .alert-link {
    color: #101214; }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > li::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #084298;
    background-color: #bacbe6; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298; }

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #41464b;
    background-color: #cbccce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #41464b;
    border-color: #41464b; }

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #0f5132;
    background-color: #bcd0c7; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #0f5132;
    border-color: #0f5132; }

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #055160;
    background-color: #badce3; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #055160;
    border-color: #055160; }

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #e6dbb9; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #664d03;
    border-color: #664d03; }

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #842029;
    background-color: #dfc2c4; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029; }

.list-group-item-light {
  color: #636464;
  background-color: #fefefe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #636464;
    background-color: #e5e5e5; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #636464;
    border-color: #636464; }

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #141619;
    border-color: #141619; }

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5; }
  .btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75; }
  .btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    opacity: 1; }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25; }

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%); }

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: 0.75rem; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }
  .toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem; }

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0; }
  .modal-fullscreen .modal-header {
    border-radius: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }
  .modal-fullscreen .modal-footer {
    border-radius: 0; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-sm-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-md-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-lg-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xl-down .modal-footer {
      border-radius: 0; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-header {
      border-radius: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; }
    .modal-fullscreen-xxl-down .modal-footer {
      border-radius: 0; } }

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
    bottom: 0; }
    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
      top: -1px;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
      right: -1px;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
    top: 0; }
    .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
      bottom: -1px;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
      left: -1px;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem; }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff; }

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #fff; }

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #fff; }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0; }

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem; }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 1rem 1rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem; }
  .offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5; }

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto; }

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%); }

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%); }

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%); }

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

.offcanvas.show {
  transform: none; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.link-primary {
  color: #0d6efd; }
  .link-primary:hover, .link-primary:focus {
    color: #0a58ca; }

.link-secondary {
  color: #6c757d; }
  .link-secondary:hover, .link-secondary:focus {
    color: #565e64; }

.link-success {
  color: #198754; }
  .link-success:hover, .link-success:focus {
    color: #146c43; }

.link-info {
  color: #0dcaf0; }
  .link-info:hover, .link-info:focus {
    color: #3dd5f3; }

.link-warning {
  color: #ffc107; }
  .link-warning:hover, .link-warning:focus {
    color: #ffcd39; }

.link-danger {
  color: #dc3545; }
  .link-danger:hover, .link-danger:focus {
    color: #b02a37; }

.link-light {
  color: #f8f9fa; }
  .link-light:hover, .link-light:focus {
    color: #f9fafb; }

.link-dark {
  color: #212529; }
  .link-dark:hover, .link-dark:focus {
    color: #1a1e21; }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-100 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: 1px solid #dee2e6 !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: 1px solid #dee2e6 !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #198754 !important; }

.border-info {
  border-color: #0dcaf0 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #212529 !important; }

.border-white {
  border-color: #fff !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 3rem !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 3rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 3rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 3rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 3rem !important; }

.ms-auto {
  margin-left: auto !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 3rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 3rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 3rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 3rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important; }

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important; }

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important; }

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important; }

.fs-5 {
  font-size: 1.25rem !important; }

.fs-6 {
  font-size: 1rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: #6c757d !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-25 {
  --bs-text-opacity: 0.25; }

.text-opacity-50 {
  --bs-text-opacity: 0.5; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-100 {
  --bs-text-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-25 {
  --bs-bg-opacity: 0.25; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-75 {
  --bs-bg-opacity: 0.75; }

.bg-opacity-100 {
  --bs-bg-opacity: 1; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: 0.2rem !important; }

.rounded-2 {
  border-radius: 0.25rem !important; }

.rounded-3 {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 3rem !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 3rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 3rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 3rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 3rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 3rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 3rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 3rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 3rem !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 3rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 3rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 3rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 3rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 3rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 3rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 3rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 3rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 3rem !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 3rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 3rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 3rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 3rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 3rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 3rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 3rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 3rem !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 3rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 3rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 3rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 3rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 3rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 3rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 3rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 3rem !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 3rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 3rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 3rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 3rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 3rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 3rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 3rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 3rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 3rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 3rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important; }
  .fs-2 {
    font-size: 2rem !important; }
  .fs-3 {
    font-size: 1.75rem !important; }
  .fs-4 {
    font-size: 1.5rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

/*-----------------------------------------------------------------------------------

    Template Name: Donacion - Fundraising & Charity HTML Template
    Description: Donacion - Fundraising & Charity HTML Template
    Author: Theme_Pure
    Version: 1.0

-----------------------------------------------------------------------------------

CSS INDEX
===================

01. common
02. Header
03. Navigation
04. Slider
05. Accordion
06. Breadcrumb
07. Contact
08. Mission
09. Cta
10. About
11. Section Title
12. Cause
13. Support
14. Choose Single Card
15. Feature Event
16. Newsfeed
17. Iconbox
18. Eventcount
19. Donation
20. Team
21. Choose
22. Circular Progress
23. Funfact
24. Services
25. Events
26. Testimonial
27. Back-to-top
28. Sidebar
29. Footer
-----------------------------------------------------------------------------------*/
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
01. common
*************************
*/
body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--clr-body-text-color); }

.img, img {
  max-width: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }

.border_radius10 img {
  border-radius: 10px; }

.f-left {
  float: left; }

.f-right {
  float: right; }

.fix {
  overflow: hidden; }

a,
.button, button {
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }

a:focus,
.button:focus {
  text-decoration: none;
  outline: none; }

a:focus,
a:hover {
  color: inherit;
  text-decoration: none; }

a,
button {
  color: inherit;
  outline: medium none; }

button {
  border: 0; }

button:focus, input:focus, input:focus, textarea, textarea:focus {
  outline: 0; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Roboto", sans-serif;
  color: var(--clr-body-heading-color);
  margin-top: 0px;
  font-weight: 700;
  text-transform: normal;
  line-height: 1.16;
  margin-bottom: 15px; }

h1 a, .h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: inherit;
  text-decoration: none; }

h1, .h1 {
  font-size: 80px;
  font-weight: 700; }

h2, .h2 {
  font-size: 60px; }

h3, .h3 {
  font-size: 45px; }

h4, .h4 {
  font-size: 36px; }

h5, .h5 {
  font-size: 20px; }

h6, .h6 {
  font-size: 16px; }

ul {
  margin: 0px;
  padding: 0px; }

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  color: var(--clr-common-body-text-color);
  margin-bottom: 15px; }
  @media (max-width: 767px) {
    p {
      font-size: 16px; } }

hr {
  border-bottom: 1px solid var(--clr-common-hr-border-color);
  border-top: 0 none;
  padding: 0;
  opacity: 1; }

label {
  color: var(--clr-soft-soft-black-soft);
  cursor: pointer;
  font-size: 14px;
  font-weight: var(--fw-fweight-regular); }

*::-moz-selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none; }

::-moz-selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none; }

::selection {
  background: var(--clr-common-black);
  color: var(--clr-common-white);
  text-shadow: none; }

/*--
    - Input Placeholder
-----------------------------------------*/
input::-webkit-input-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
  transition: 0.3s; }

input:-moz-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
  transition: 0.3s; }

input::-moz-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
  transition: 0.3s; }

input:-ms-input-placeholder {
  color: var(--clr-common-black);
  font-size: 14px;
  opacity: 1;
  transition: 0.3s; }

input:focus::-webkit-input-placeholder {
  opacity: 0;
  visibility: hidden; }

input:focus:-moz-placeholder {
  opacity: 0;
  visibility: hidden; }

input:focus::-moz-placeholder {
  opacity: 0;
  visibility: hidden; }

input:focus:-ms-input-placeholder {
  opacity: 0;
  visibility: hidden; }

/*--
    - Common Classes
-----------------------------------------*/
.fix {
  overflow: hidden; }

.clear {
  clear: both; }

.w-200 {
  width: 200px; }

.p-rel {
  position: relative; }

.p-abs {
  position: absolute; }

.rad-5 {
  border-radius: 5px; }

.rad-20 {
  border-radius: 20px; }

.rad-30 {
  border-radius: 30px; }

.rad-50 {
  border-radius: 50px; }

.rad-50p {
  border-radius: 50%; }

.p-35 {
  padding-left: 35px;
  padding-right: 35px; }

.p-40 {
  padding-left: 40px;
  padding-right: 40px; }

.p-45 {
  padding-left: 45px;
  padding-right: 45px; }

.p-55 {
  padding-left: 55px;
  padding-right: 55px; }

.p-60 {
  padding-right: 60px;
  padding-left: 60px; }

.pt-205 {
  padding-top: 205px; }

.pt-210 {
  padding-top: 210px; }

.p-250 {
  padding-top: 250px;
  padding-bottom: 250px; }

.pb-385 {
  padding-bottom: 385px; }

.pb-390 {
  padding-bottom: 390px; }

.pb-420 {
  padding-bottom: 420px; }

.pl-165 {
  padding-left: 165px; }

.cta-bg {
  background-color: var(--clr-common-cta-bg); }

.grey-bg {
  background-color: var(--clr-common-grey-bg); }

.about-bg {
  background-color: var(--clr-common-gray-bg); }

.gray-bg {
  background-color: var(--clr-soft-gray-bg); }

.blue-bg {
  background-color: var(--clr-theme-7); }

.white-bg {
  background-color: var(--clr-common-white); }

.soft-grey-bg {
  background-color: var(--clr-soft-grey-bg); }

.soft-grey-2 {
  background-color: var(--clr-soft-grey-2); }

.brand-bg {
  background-color: var(--clr-soft-brand); }

.text_space {
  margin-bottom: 165px; }

.w_img > img {
  width: 100%; }

.mtm-294 {
  margin-top: -294px; }

.mtm-300 {
  margin-top: -300px; }

.bg_cover {
  background-size: cover; }

.gutter_40 {
  --bs-gutter-x: 40px; }

@media only screen and (min-width: 1401px) and (max-width: 1600px) {
  .gutter_85 {
    --bs-gutter-x: 85px; } }

@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .gutter_85 {
    --bs-gutter-x: 30px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .sm_mb0 {
    margin-bottom: 0; } }

@media (max-width: 767px) {
  br {
    display: none; } }

/*--
    - Margin & Padding
-----------------------------------------*/
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px; }

.mt-10 {
  margin-top: 10px; }

.mt-15 {
  margin-top: 15px; }

.mt-20 {
  margin-top: 20px; }

.mt-25 {
  margin-top: 25px; }

.mt-30 {
  margin-top: 30px; }

.mt-35 {
  margin-top: 35px; }

.mt-40 {
  margin-top: 40px; }

.mt-45 {
  margin-top: 45px; }

.mt-50 {
  margin-top: 50px; }

.mt-55 {
  margin-top: 55px; }

.mt-60 {
  margin-top: 60px; }

.mt-65 {
  margin-top: 65px; }

.mt-70 {
  margin-top: 70px; }

.mt-75 {
  margin-top: 75px; }

.mt-80 {
  margin-top: 80px; }

.mt-85 {
  margin-top: 85px; }

.mt-90 {
  margin-top: 90px; }

.mt-95 {
  margin-top: 95px; }

.mt-100 {
  margin-top: 100px; }

.mt-105 {
  margin-top: 105px; }

.mt-110 {
  margin-top: 110px; }

.mt-115 {
  margin-top: 115px; }

.mt-120 {
  margin-top: 120px; }

.mt-125 {
  margin-top: 125px; }

.mt-130 {
  margin-top: 130px; }

.mt-135 {
  margin-top: 135px; }

.mt-140 {
  margin-top: 140px; }

.mt-145 {
  margin-top: 145px; }

.mt-150 {
  margin-top: 150px; }

.mt-155 {
  margin-top: 155px; }

.mt-160 {
  margin-top: 160px; }

.mt-165 {
  margin-top: 165px; }

.mt-170 {
  margin-top: 170px; }

.mt-175 {
  margin-top: 175px; }

.mt-180 {
  margin-top: 180px; }

.mt-185 {
  margin-top: 185px; }

.mt-190 {
  margin-top: 190px; }

.mt-195 {
  margin-top: 195px; }

.mt-200 {
  margin-top: 200px; }

/*-- Margin Bottom --*/
.mb-5 {
  margin-bottom: 5px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-25 {
  margin-bottom: 25px; }

.mb-30 {
  margin-bottom: 30px; }

.mb-35 {
  margin-bottom: 35px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-45 {
  margin-bottom: 45px; }

.mb-50 {
  margin-bottom: 50px; }

.mb-55 {
  margin-bottom: 55px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-65 {
  margin-bottom: 65px; }

.mb-70 {
  margin-bottom: 70px; }

.mb-75 {
  margin-bottom: 75px; }

.mb-80 {
  margin-bottom: 80px; }

.mb-85 {
  margin-bottom: 85px; }

.mb-90 {
  margin-bottom: 90px; }

.mb-95 {
  margin-bottom: 95px; }

.mb-100 {
  margin-bottom: 100px; }

.mb-105 {
  margin-bottom: 105px; }

.mb-110 {
  margin-bottom: 110px; }

.mb-115 {
  margin-bottom: 115px; }

.mb-120 {
  margin-bottom: 120px; }

.mb-125 {
  margin-bottom: 125px; }

.mb-130 {
  margin-bottom: 130px; }

.mb-135 {
  margin-bottom: 135px; }

.mb-140 {
  margin-bottom: 140px; }

.mb-145 {
  margin-bottom: 145px; }

.mb-150 {
  margin-bottom: 150px; }

.mb-155 {
  margin-bottom: 155px; }

.mb-160 {
  margin-bottom: 160px; }

.mb-165 {
  margin-bottom: 165px; }

.mb-170 {
  margin-bottom: 170px; }

.mb-175 {
  margin-bottom: 175px; }

.mb-180 {
  margin-bottom: 180px; }

.mb-185 {
  margin-bottom: 185px; }

.mb-190 {
  margin-bottom: 190px; }

.mb-195 {
  margin-bottom: 195px; }

.mb-200 {
  margin-bottom: 200px; }

/*-- Margin Left --*/
.ml-5 {
  margin-left: 5px; }

.ml-10 {
  margin-left: 10px; }

.ml-15 {
  margin-left: 15px; }

.ml-20 {
  margin-left: 20px; }

.ml-25 {
  margin-left: 25px; }

.ml-30 {
  margin-left: 30px; }

.ml-35 {
  margin-left: 35px; }

.ml-40 {
  margin-left: 40px; }

.ml-45 {
  margin-left: 45px; }

.ml-50 {
  margin-left: 50px; }

.ml-55 {
  margin-left: 55px; }

.ml-60 {
  margin-left: 60px; }

.ml-65 {
  margin-left: 65px; }

.ml-70 {
  margin-left: 70px; }

.ml-75 {
  margin-left: 75px; }

.ml-80 {
  margin-left: 80px; }

.ml-85 {
  margin-left: 85px; }

.ml-90 {
  margin-left: 90px; }

.ml-95 {
  margin-left: 95px; }

.ml-100 {
  margin-left: 100px; }

.ml-105 {
  margin-left: 105px; }

.ml-110 {
  margin-left: 110px; }

.ml-115 {
  margin-left: 115px; }

.ml-120 {
  margin-left: 120px; }

.ml-125 {
  margin-left: 125px; }

.ml-130 {
  margin-left: 130px; }

.ml-135 {
  margin-left: 135px; }

.ml-140 {
  margin-left: 140px; }

.ml-145 {
  margin-left: 145px; }

.ml-150 {
  margin-left: 150px; }

.ml-155 {
  margin-left: 155px; }

.ml-160 {
  margin-left: 160px; }

.ml-165 {
  margin-left: 165px; }

.ml-170 {
  margin-left: 170px; }

.ml-175 {
  margin-left: 175px; }

.ml-180 {
  margin-left: 180px; }

.ml-185 {
  margin-left: 185px; }

.ml-190 {
  margin-left: 190px; }

.ml-195 {
  margin-left: 195px; }

.ml-200 {
  margin-left: 200px; }

/*-- Margin Right --*/
.mr-5 {
  margin-right: 5px; }

.mr-10 {
  margin-right: 10px; }

.mr-15 {
  margin-right: 15px; }

.mr-20 {
  margin-right: 20px; }

.mr-25 {
  margin-right: 25px; }

.mr-30 {
  margin-right: 30px; }

.mr-35 {
  margin-right: 35px; }

.mr-40 {
  margin-right: 40px; }

.mr-45 {
  margin-right: 45px; }

.mr-50 {
  margin-right: 50px; }

.mr-55 {
  margin-right: 55px; }

.mr-60 {
  margin-right: 60px; }

.mr-65 {
  margin-right: 65px; }

.mr-70 {
  margin-right: 70px; }

.mr-75 {
  margin-right: 75px; }

.mr-80 {
  margin-right: 80px; }

.mr-85 {
  margin-right: 85px; }

.mr-90 {
  margin-right: 90px; }

.mr-95 {
  margin-right: 95px; }

.mr-100 {
  margin-right: 100px; }

.mr-105 {
  margin-right: 105px; }

.mr-110 {
  margin-right: 110px; }

.mr-115 {
  margin-right: 115px; }

.mr-120 {
  margin-right: 120px; }

.mr-125 {
  margin-right: 125px; }

.mr-130 {
  margin-right: 130px; }

.mr-135 {
  margin-right: 135px; }

.mr-140 {
  margin-right: 140px; }

.mr-145 {
  margin-right: 145px; }

.mr-150 {
  margin-right: 150px; }

.mr-155 {
  margin-right: 155px; }

.mr-160 {
  margin-right: 160px; }

.mr-165 {
  margin-right: 165px; }

.mr-170 {
  margin-right: 170px; }

.mr-175 {
  margin-right: 175px; }

.mr-180 {
  margin-right: 180px; }

.mr-185 {
  margin-right: 185px; }

.mr-190 {
  margin-right: 190px; }

.mr-195 {
  margin-right: 195px; }

.mr-200 {
  margin-right: 200px; }

/*-- Padding Top --*/
.pt-5 {
  padding-top: 5px; }

.pt-10 {
  padding-top: 10px; }

.pt-15 {
  padding-top: 15px; }

.pt-20 {
  padding-top: 20px; }

.pt-25 {
  padding-top: 25px; }

.pt-30 {
  padding-top: 30px; }

.pt-35 {
  padding-top: 35px; }

.pt-40 {
  padding-top: 40px; }

.pt-45 {
  padding-top: 45px; }

.pt-50 {
  padding-top: 50px; }

.pt-55 {
  padding-top: 55px; }

.pt-60 {
  padding-top: 60px; }

.pt-65 {
  padding-top: 65px; }

.pt-70 {
  padding-top: 70px; }

.pt-75 {
  padding-top: 75px; }

.pt-80 {
  padding-top: 80px; }

.pt-85 {
  padding-top: 85px; }

.pt-90 {
  padding-top: 90px; }

.pt-95 {
  padding-top: 95px; }

.pt-100 {
  padding-top: 100px; }

.pt-105 {
  padding-top: 105px; }

.pt-110 {
  padding-top: 110px; }

.pt-115 {
  padding-top: 115px; }

.pt-120 {
  padding-top: 120px; }

.pt-125 {
  padding-top: 125px; }

.pt-130 {
  padding-top: 130px; }

.pt-135 {
  padding-top: 135px; }

.pt-140 {
  padding-top: 140px; }

.pt-145 {
  padding-top: 145px; }

.pt-150 {
  padding-top: 150px; }

.pt-155 {
  padding-top: 155px; }

.pt-160 {
  padding-top: 160px; }

.pt-165 {
  padding-top: 165px; }

.pt-170 {
  padding-top: 170px; }

.pt-175 {
  padding-top: 175px; }

.pt-180 {
  padding-top: 180px; }

.pt-185 {
  padding-top: 185px; }

.pt-190 {
  padding-top: 190px; }

.pt-195 {
  padding-top: 195px; }

.pt-200 {
  padding-top: 200px; }

/*-- Padding Bottom --*/
.pb-5 {
  padding-bottom: 5px; }

.pb-10 {
  padding-bottom: 10px; }

.pb-15 {
  padding-bottom: 15px; }

.pb-20 {
  padding-bottom: 20px; }

.pb-25 {
  padding-bottom: 25px; }

.pb-30 {
  padding-bottom: 30px; }

.pb-35 {
  padding-bottom: 35px; }

.pb-40 {
  padding-bottom: 40px; }

.pb-45 {
  padding-bottom: 45px; }

.pb-50 {
  padding-bottom: 50px; }

.pb-55 {
  padding-bottom: 55px; }

.pb-60 {
  padding-bottom: 60px; }

.pb-65 {
  padding-bottom: 65px; }

.pb-70 {
  padding-bottom: 70px; }

.pb-75 {
  padding-bottom: 75px; }

.pb-80 {
  padding-bottom: 80px; }

.pb-85 {
  padding-bottom: 85px; }

.pb-90 {
  padding-bottom: 90px; }

.pb-95 {
  padding-bottom: 95px; }

.pb-100 {
  padding-bottom: 100px; }

.pb-105 {
  padding-bottom: 105px; }

.pb-110 {
  padding-bottom: 110px; }

.pb-115 {
  padding-bottom: 115px; }

.pb-120 {
  padding-bottom: 120px; }

.pb-125 {
  padding-bottom: 125px; }

.pb-130 {
  padding-bottom: 130px; }

.pb-135 {
  padding-bottom: 135px; }

.pb-140 {
  padding-bottom: 140px; }

.pb-145 {
  padding-bottom: 145px; }

.pb-150 {
  padding-bottom: 150px; }

.pb-155 {
  padding-bottom: 155px; }

.pb-160 {
  padding-bottom: 160px; }

.pb-165 {
  padding-bottom: 165px; }

.pb-170 {
  padding-bottom: 170px; }

.pb-175 {
  padding-bottom: 175px; }

.pb-180 {
  padding-bottom: 180px; }

.pb-185 {
  padding-bottom: 185px; }

.pb-190 {
  padding-bottom: 190px; }

.pb-195 {
  padding-bottom: 195px; }

.pb-200 {
  padding-bottom: 200px; }

/*-- Padding Left --*/
.pl-5 {
  padding-left: 5px; }

.pl-10 {
  padding-left: 10px; }

.pl-15 {
  padding-left: 15px; }

.pl-20 {
  padding-left: 20px; }

.pl-25 {
  padding-left: 25px; }

.pl-30 {
  padding-left: 30px; }

.pl-35 {
  padding-left: 35px; }

.pl-40 {
  padding-left: 40px; }

.pl-45 {
  padding-left: 45px; }

.pl-50 {
  padding-left: 50px; }

.pl-55 {
  padding-left: 55px; }

.pl-60 {
  padding-left: 60px; }

.pl-65 {
  padding-left: 65px; }

.pl-70 {
  padding-left: 70px; }

.pl-75 {
  padding-left: 75px; }

.pl-80 {
  padding-left: 80px; }

.pl-85 {
  padding-left: 85px; }

.pl-90 {
  padding-left: 90px; }

.pl-95 {
  padding-left: 95px; }

.pl-100 {
  padding-left: 100px; }

.pl-105 {
  padding-left: 105px; }

.pl-110 {
  padding-left: 110px; }

.pl-115 {
  padding-left: 115px; }

.pl-120 {
  padding-left: 120px; }

.pl-125 {
  padding-left: 125px; }

.pl-130 {
  padding-left: 130px; }

.pl-135 {
  padding-left: 135px; }

.pl-140 {
  padding-left: 140px; }

.pl-145 {
  padding-left: 145px; }

.pl-150 {
  padding-left: 150px; }

.pl-155 {
  padding-left: 155px; }

.pl-160 {
  padding-left: 160px; }

.pl-165 {
  padding-left: 165px; }

.pl-170 {
  padding-left: 170px; }

.pl-175 {
  padding-left: 175px; }

.pl-180 {
  padding-left: 180px; }

.pl-185 {
  padding-left: 185px; }

.pl-190 {
  padding-left: 190px; }

.pl-195 {
  padding-left: 195px; }

.pl-200 {
  padding-left: 200px; }

/*-- Padding Right --*/
.pr-5 {
  padding-right: 5px; }

.pr-10 {
  padding-right: 10px; }

.pr-15 {
  padding-right: 15px; }

.pr-20 {
  padding-right: 20px; }

.pr-25 {
  padding-right: 25px; }

.pr-30 {
  padding-right: 30px; }

.pr-35 {
  padding-right: 35px; }

.pr-40 {
  padding-right: 40px; }

.pr-45 {
  padding-right: 45px; }

.pr-50 {
  padding-right: 50px; }

.pr-55 {
  padding-right: 55px; }

.pr-60 {
  padding-right: 60px; }

.pr-65 {
  padding-right: 65px; }

.pr-70 {
  padding-right: 70px; }

.pr-75 {
  padding-right: 75px; }

.pr-80 {
  padding-right: 80px; }

.pr-85 {
  padding-right: 85px; }

.pr-90 {
  padding-right: 90px; }

.pr-95 {
  padding-right: 95px; }

.pr-100 {
  padding-right: 100px; }

.pr-105 {
  padding-right: 105px; }

.pr-110 {
  padding-right: 110px; }

.pr-115 {
  padding-right: 115px; }

.pr-120 {
  padding-right: 120px; }

.pr-125 {
  padding-right: 125px; }

.pr-130 {
  padding-right: 130px; }

.pr-135 {
  padding-right: 135px; }

.pr-140 {
  padding-right: 140px; }

.pr-145 {
  padding-right: 145px; }

.pr-150 {
  padding-right: 150px; }

.pr-155 {
  padding-right: 155px; }

.pr-160 {
  padding-right: 160px; }

.pr-165 {
  padding-right: 165px; }

.pr-170 {
  padding-right: 170px; }

.pr-175 {
  padding-right: 175px; }

.pr-180 {
  padding-right: 180px; }

.pr-185 {
  padding-right: 185px; }

.pr-190 {
  padding-right: 190px; }

.pr-195 {
  padding-right: 195px; }

.pr-200 {
  padding-right: 200px; }

:root {
  /**
    @color declaration
    */
  --clr-common-white: #fff;
  --clr-common-black: #000;
  --clr-common-grey: #999;
  --clr-common-grey-bg: #f9f9f9;
  --clr-common-gray-bg: #f8f8f8;
  --clr-common-cta-bg: #2f4158;
  --clr-common-menu-color: #888f9a;
  --clr-common-hr-border-color: #f3f3f3;
  --clr-common-slider-bg-color: #f0edec;
  --clr-common-border-color: #51647c;
  --clr-common-bg-overlay: #ff9731;
  --clr-body-text-color: #777777;
  --clr-body-heading-color: #263b5c;
  --clr-footer-1: #a2aec1;
  --clr-soft-black: #7e7e7e;
  --clr-soft-grey: #ebebeb;
  --clr-soft-grey-2: #f3f3f3;
  --clr-soft-dot: #e3e3e3;
  --clr-soft-gray-bg: #f9f9f9;
  --clr-soft-grey-bg: #f6f6f6;
  --clr-soft-brand: #f9f5f2;
  --clr-theme-1: #f15b43;
  --clr-theme-2: #65c9bb;
  --clr-theme-3: #ff9a39;
  --clr-theme-4: #132642;
  --clr-theme-5: #2984ff;
  --clr-theme-6: #63b55f;
  --clr-theme-7: #142645;
  --clr-theme-8: #5fb35b; }

/*--
    - Overlay
------------------------------------------*/
[data-overlay] {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  [data-overlay]::before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: ""; }

/*-- Overlay Color --*/
[data-overlay="light"]::before {
  background-color: var(--clr-common-white); }

[data-overlay="theme"]::before {
  background-color: var(--clr-theme-1); }

[data-overlay="dark"]::before {
  background-color: var(--clr-common-black); }

/*-- Overlay Opacity --*/
[data-opacity="1"]::before {
  opacity: 0.1; }

[data-opacity="2"]::before {
  opacity: 0.2; }

[data-opacity="3"]::before {
  opacity: 0.3; }

[data-opacity="4"]::before {
  opacity: 0.4; }

[data-opacity="5"]::before {
  opacity: 0.5; }

[data-opacity="6"]::before {
  opacity: 0.6; }

[data-opacity="7"]::before {
  opacity: 0.7; }

[data-opacity="8"]::before {
  opacity: 0.8; }

[data-opacity="9"]::before {
  opacity: 0.9; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*      General Style of Buttons       */
.g_btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  min-width: 140px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  z-index: 1;
  color: var(--clr-common-white);
  /* For Styling Button */
  /* To Left Right Style */
  /* To Right Style */
  /* To Left Style */
  /* To Top Style */
  /* To Bottom Style */
  /* To Diagonal Style */
  /* ###################  
  Hover start  
  ###################### */ }
  .g_btn:is(.hbtn_1) {
    padding: 0 45px;
    background-color: var(--clr-theme-2);
    color: var(--clr-common-white); }
  .g_btn:is(.theme1_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-1);
    padding: 0 45px; }
  @media (max-width: 767px) {
    .g_btn:is(.slider_btn) {
      padding: 0 45px; } }
  .g_btn:is(.theme2_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-2);
    padding: 0 45px; }
  .g_btn:is(.heading_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-body-heading-color);
    padding: 0 45px; }
  .g_btn:is(.cause_btn) {
    background-color: #ff9a39;
    color: #fff;
    padding: 0 50px; }
    .g_btn:is(.cause_btn) i {
      margin-left: 10px; }
  .g_btn:is(.trans_btn) {
    color: var(--clr-body-heading-color);
    border: 2px solid #ececec;
    line-height: 56px; }
    .g_btn:is(.trans_btn):hover {
      color: var(--clr-common-white);
      background-color: var(--clr-theme-1);
      border-color: var(--clr-theme-1); }
  .g_btn:is(.red_bg) {
    color: var(--clr-common-white);
    background-color: #a51d25; }
  .g_btn:is(.donation_btn) {
    height: 56px;
    line-height: 50px;
    background-color: #3d7d3c;
    border-radius: 50px;
    border: 2px solid transparent; }
    .g_btn:is(.donation_btn):hover {
      background-color: transparent;
      border-color: var(--clr-common-white); }
  .g_btn:is(.i_left) i {
    margin-right: 5px;
    display: inline-block;
    transform: translateY(1px); }
  .g_btn:is(.i_right) i {
    margin-left: 10px;
    display: inline; }
  .g_btn span {
    position: absolute;
    z-index: -1;
    transition: 0.5s ease; }
  .g_btn:is(.fbtn_2) {
    background-color: #5fb35b; }
  .g_btn:is(.fca_btn) {
    background-color: var(--clr-theme-2);
    height: 50px;
    line-height: 50px;
    min-width: auto; }
  .g_btn:is(.fca_btn1) {
    background-color: var(--clr-theme-1);
    height: 50px;
    line-height: 50px; }
  .g_btn:is(.to_leftright) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_leftright) span {
      top: 50%;
      left: 50%;
      width: 0;
      height: 100%;
      transform: translate(-50%, -50%);
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_topbottom) {
    border-color: transparent; }
    .g_btn:is(.to_topbottom) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%);
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_diagonal_lr) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_diagonal_lr) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_diagonal_rl) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_diagonal_rl) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_right1) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_right1) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_right2) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_right2) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_right3) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_right3) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_right4) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_right4) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_rightheading) {
    border-color: var(--clr-body-heading-color); }
    .g_btn:is(.to_rightheading) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-body-heading-color); }
  .g_btn:is(.to_rightout) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_rightout) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_rightout_heading) {
    border-color: var(--clr-body-heading-color); }
    .g_btn:is(.to_rightout_heading) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-body-heading-color); }
  .g_btn:is(.to_left) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_left) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_leftout) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_leftout) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_top) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_top):is(.trans_btn) {
      border-color: #ececec; }
    .g_btn:is(.to_top) span {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_topout) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_topout) span {
      right: 0;
      top: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_bottom) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_bottom) span {
      right: 0;
      top: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_bottomout) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_bottomout) span {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_diagonal) {
    border-color: var(--clr-soft-black); }
    .g_btn:is(.to_diagonal) span {
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      background-color: var(--clr-soft-black); }
  .g_btn:is(.to_diagonalout) {
    border-color: var(--clr-soft-black); }
    .g_btn:is(.to_diagonalout) span {
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      background-color: var(--clr-soft-black); }
  .g_btn:hover {
    color: var(--clr-common-white);
    /* To left Right style */
    /* To Right Style Hover */
    /* To Left Style */
    /* To Top Style */
    /* To Bottom Style */
    /* To Diagonal Style */ }
    .g_btn:hover:is(.to_leftright) {
      color: var(--clr-body-heading); }
      .g_btn:hover:is(.to_leftright) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_topbottom) {
      color: var(--clr-common-white);
      border-color: transparent; }
      .g_btn:hover:is(.to_topbottom) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonal_lr) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal_lr) span {
        width: 100%;
        height: 380%; }
    .g_btn:hover:is(.to_diagonal_rl) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal_rl) span {
        width: 100%;
        height: 380%; }
    .g_btn:hover:is(.to_right1, .to_right2, .to_right3, .to_right4, .to_rightheading) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_right1, .to_right2, .to_right3, .to_right4, .to_rightheading) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_rightout, .to_rightout_heading) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_rightout, .to_rightout_heading) span {
        right: auto;
        left: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_left) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_left) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_leftout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_leftout) span {
        left: auto;
        right: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_top) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_top) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_topout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_topout) span {
        bottom: 0;
        top: auto;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_bottom) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_bottom) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_bottomout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_bottomout) span {
        bottom: auto;
        top: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonal) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonalout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonalout) span {
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
03. Navigation
*************************
*/
.main-menu ul li {
  display: inline-block;
  margin: 0px 20px;
  list-style: none;
  position: relative;
  /* sub-menu here*/ }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .main-menu ul li {
      margin: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu ul li {
      margin: 0 15px; } }
  .main-menu ul li.menu-item-has-children > a::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    margin-left: 3px;
    display: inline-block;
    transform: translateY(1px);
    opacity: 0;
    visibility: hidden; }
  .main-menu ul li.active > a {
    color: var(--clr-body-heading-color); }
  .main-menu ul li a {
    padding: 62px 0;
    display: block;
    text-decoration: none;
    color: var(--clr-common-menu-color);
    font-weight: 700;
    font-size: 16px; }
  .main-menu ul li:hover > a {
    color: var(--clr-body-heading-color); }
  .main-menu ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%; }
  .main-menu ul li ul.sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 250px;
    border-top: 4px solid var(--clr-theme-1);
    background-color: var(--clr-common-white);
    text-align: start;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 3; }
    .main-menu ul li ul.sub-menu li {
      margin: 0;
      display: block;
      padding: 0 30px; }
      .main-menu ul li ul.sub-menu li:last-child {
        border-bottom: 0; }
      .main-menu ul li ul.sub-menu li a {
        padding: 12px 0;
        text-align: start;
        display: block;
        border-bottom: 1px solid rgba(90, 90, 90, 0.11);
        position: relative;
        color: var(--clr-common-menu-color); }
        .main-menu ul li ul.sub-menu li a:after {
          content: "";
          width: 10px;
          height: 2px;
          background-color: var(--clr-theme-1);
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translate(0, -50%);
          opacity: 0;
          transition: all 220ms linear 0ms; }
        .main-menu ul li ul.sub-menu li a:hover {
          padding-left: 18px;
          color: var(--clr-body-heading-color); }
          .main-menu ul li ul.sub-menu li a:hover:after {
            opacity: 1;
            visibility: visible;
            left: 0; }
  .main-menu ul li:hover .sub-menu {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible; }

.main-menu:is(.menu_2) ul li.menu-item-has-children ul.sub-menu {
  z-index: 3; }

.main-menu:is(.menu_2) ul li a {
  padding: 43px 0; }

.main-menu .metismenu:is(.theme-1) ul li:hover > a {
  color: var(--clr-theme-1); }

.main-menu .metismenu:is(.theme-1) ul li .sub-menu li a:hover {
  color: var(--clr-theme-1); }

.metismenu li, .metismenu ul li {
  list-style: none;
  list-style-type: none; }
  .metismenu li a, .metismenu ul li a {
    display: block;
    width: 100%;
    padding: 10px 0;
    color: #101a23;
    border-top: 1px solid #ebebeb;
    font-size: 14px;
    font-weight: 700; }
    .metismenu li a:hover, .metismenu ul li a:hover {
      color: #f15b43; }

.metismenu li:first-child > a {
  border-top: 0px; }

.metismenu li ul {
  padding-left: 15px; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
02. Header
*************************
*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .res_padd {
    padding: 30px 0; } }

@media (max-width: 767px) {
  .res_padd {
    padding: 15px 0; } }

.logo img {
  min-width: 160px; }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .header_menu_area_2 {
    padding: 15px 0; } }

.header-area:is(.header-transparent) {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  background-color: transparent; }
  @media (max-width: 767px) {
    .header-area:is(.header-transparent) {
      position: static; } }

.header-sing {
  height: 60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .header-sing .hbtn_1 {
      padding: 0 32px; } }

.menu-bar button {
  width: 60px;
  height: 60px;
  background: var(--clr-common-white);
  display: inline-block;
  border: 0;
  line-height: 60px;
  text-align: center;
  border-radius: 50%;
  color: var(--clr-body-heading-color);
  font-size: 18px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .menu-bar button {
      border: 1px solid var(--clr-body-heading-color); } }
  .menu-bar button:hover {
    background-color: var(--clr-theme-2);
    color: var(--clr-common-white); }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
      .menu-bar button:hover {
        border: 1px solid var(--clr-theme-2); } }

.header-icon a {
  color: var(--clr-common-white); }

/* Header top area   */
.header_top_area {
  background-color: var(--clr-body-heading-color); }
  @media (max-width: 767px) {
    .header_top_area {
      padding: 30px 0; } }

.top_social a {
  width: 50px;
  height: 50px;
  display: inline-block;
  line-height: 50px;
  background-color: #23344f;
  text-align: center;
  font-size: 14px;
  color: #c0c0c0; }
  .top_social a:is(.facebook):hover {
    background-color: #385398;
    color: var(--clr-common-white); }
  .top_social a:is(.twitter):hover {
    background-color: #55acee;
    color: var(--clr-common-white); }
  .top_social a:is(.behance):hover {
    background-color: #0057FF;
    color: var(--clr-common-white); }
  .top_social a:is(.youtube):hover {
    background-color: #C3271A;
    color: var(--clr-common-white); }
  .top_social a:is(.google):hover {
    background-color: #E34133;
    color: var(--clr-common-white); }
  .top_social a:is(.pinterest):hover {
    background-color: #C51F26;
    color: var(--clr-common-white); }
  .top_social a:is(.linkedin):hover {
    background-color: #0073B1;
    color: var(--clr-common-white); }
  .top_social a:is(.instagram):hover {
    background-color: #DC001A;
    color: var(--clr-common-white); }

.top_social:is(.offset_social) a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--clr-body-heading-color);
  color: #fff; }

@media (max-width: 767px) {
  .top_mailing {
    margin-bottom: 25px; } }

.top_mailing a {
  position: relative;
  padding-right: 20px;
  margin-right: 20px;
  display: inline-block;
  text-decoration: none;
  color: #c5c5c5;
  font-size: 14px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .top_mailing a {
      padding-right: 8px;
      margin-right: 8px; } }
  .top_mailing a:after {
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 10px;
    transition: translateY(-50%);
    background-color: #3d5170;
    content: "";
    margin-top: -5px; }
  .top_mailing a:last-child {
    margin-right: 0;
    padding-right: 0; }
    .top_mailing a:last-child:after {
      display: none; }
  .top_mailing a:is(.theme-1) i {
    color: var(--clr-theme-1); }
  .top_mailing a:is(.theme-1):hover {
    color: var(--clr-theme-1); }
  .top_mailing a:is(.theme-2) i {
    color: var(--clr-theme-2); }
  .top_mailing a:is(.theme-2):hover {
    color: var(--clr-theme-2); }
  .top_mailing a:is(.theme-3) i {
    color: var(--clr-theme-3); }
  .top_mailing a:is(.theme-3):hover {
    color: var(--clr-theme-3); }
  .top_mailing a i {
    display: inline-block;
    margin-right: 8px; }

/*  Hero Area 2 */
.slide-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s linear 0s;
  -moz-transition: all 8s linear 0s;
  -ms-transition: all 8s linear 0s;
  -o-transition: all 8s linear 0s;
  transition: all 8s linear 0s;
  filter: grayscale(100%); }

.swiper-slide-active .slide-bg {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  transform: scale(1.2); }

.swiper-slide-active .img_center {
  left: 50%;
  opacity: 1;
  visibility: visible;
  z-index: 2; }

.hero_wrapper:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: var(--clr-common-black);
  opacity: 0.5;
  z-index: 1; }

.hero_single {
  position: relative;
  z-index: 3; }

.img_center {
  position: absolute;
  left: 40%;
  top: 0;
  margin-left: -25%;
  height: 100%;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden; }
  .img_center img {
    height: 100%; }
  @media (max-width: 767px) {
    .img_center {
      display: none; } }

.hero_height {
  min-height: 750px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero_height {
      min-height: 650px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_height {
      min-height: 550px; } }
  @media (max-width: 767px) {
    .hero_height {
      min-height: 500px; } }

.hero_height_3 {
  min-height: 980px; }

@media (max-width: 767px) {
  .hero_meta {
    margin-bottom: 5px; } }

.hero_meta a {
  display: inline-block;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding-right: 20px;
  margin-right: 30px;
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    .hero_meta a {
      font-size: 15px;
      padding-right: 10px;
      margin-right: 15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_meta a {
      font-size: 16px;
      padding-right: 15px;
      margin-right: 25px; } }
  .hero_meta a:before {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -7.5px;
    width: 1px;
    background-color: #c57163;
    content: "";
    height: 15px; }
    @media (max-width: 767px) {
      .hero_meta a:before {
        height: 10px;
        margin-top: -5px;
        background-color: #eeecec; } }
  .hero_meta a:last-child {
    margin-right: 0;
    padding-right: 0; }
    .hero_meta a:last-child:before {
      display: none; }

.hero_content p {
  font-size: 24px;
  color: #fff; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero_content p {
      font-size: 22px; } }
  @media (max-width: 767px) {
    .hero_content p {
      font-size: 19px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_content p {
      font-size: 21px; } }

.hero_title {
  font-size: 80px;
  color: #fff;
  line-height: 1.4; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero_title {
      font-size: 70px; } }
  @media (max-width: 767px) {
    .hero_title {
      font-size: 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_title {
      font-size: 50px; } }

.hero_buttons a {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  color: #fff; }
  @media (max-width: 767px) {
    .hero_buttons a:is(.g_btn) {
      display: block;
      margin-right: 0;
      margin-bottom: 25px; }
      .hero_buttons a:is(.g_btn):last-child {
        margin-bottom: 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_buttons a:is(.g_btn) {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 0; } }

/*   Header style 3  */
.menu_3 {
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  transform: translateY(-50px); }
  .menu_3:is(.menu_block) {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

.hero_wrapper_3 {
  z-index: 1;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 378px;
  padding-bottom: 354px;
  background-size: cover; }

.header_area_3 {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  text-align: center; }

.spacial_logo {
  width: 200px;
  height: 200px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0 auto; }
  .spacial_logo:hover .logo_absicon {
    animation: icon-bounce 0.8s 1; }

.logo_absicon {
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  background-color: #d13942;
  line-height: 50px;
  color: #fff;
  margin-left: -25px;
  margin-bottom: -25px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 3; }
  .logo_absicon i {
    display: block;
    transition: 0.3s;
    width: 50px;
    height: 50px;
    line-height: 50px; }
    .logo_absicon i:is(.fa-minus) {
      transform: translateY(-150%);
      opacity: 0;
      visibility: hidden; }
  .logo_absicon:is(.icon_block) i.fa-minus {
    display: block;
    transform: translateY(-100%);
    opacity: 1;
    visibility: visible; }
  .logo_absicon:is(.icon_block) i.fa-plus {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden; }

.sub_content {
  font-size: 24px;
  color: #b38a85;
  margin-bottom: 10px;
  display: block; }

.hero_title3 {
  font-size: 200px;
  font-weight: 700;
  color: #142645;
  line-height: 1;
  margin-bottom: 0; }
  @media (max-width: 767px) {
    .hero_title3 {
      font-size: 72px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero_title3 {
      font-size: 120px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero_title3 {
      font-size: 150px; } }

/* Menu Search
-------------------------------------------------------*/
.nav-search {
  position: relative;
  display: block;
  color: inherit; }

.nav-search:hover {
  color: inherit; }

.search-wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 121;
  background: rgba(0, 0, 0, 0.9); }

.search-wrap .search-inner {
  position: relative;
  width: 100%;
  height: 100%; }

.search-wrap .search-cell {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%); }

.search-wrap .search-field-holder {
  width: 50%;
  margin: auto;
  position: relative;
  animation: slideInUp .3s; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .search-wrap .search-field-holder {
      width: 80%; } }

.search-wrap .main-search-input {
  width: 100%;
  height: 70px;
  border: 0;
  padding: 0 50px;
  text-transform: uppercase;
  background: transparent;
  font-size: 25px;
  color: #fff;
  border-bottom: 2px solid #898989;
  text-align: center;
  letter-spacing: 2px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .search-wrap .main-search-input {
      padding: 0;
      font-size: 20px; } }
  @media (max-width: 767px) {
    .search-wrap .main-search-input {
      padding: 0;
      font-size: 20px; } }

.search-wrap input.form-control,
.search-wrap input.form-control:focus {
  background-color: #fff; }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

input.main-search-input::-webkit-input-placeholder {
  color: #fff;
  font-size: 25px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    input.main-search-input::-webkit-input-placeholder {
      font-size: 20px; } }
  @media (max-width: 767px) {
    input.main-search-input::-webkit-input-placeholder {
      font-size: 16px; } }

input.main-search-input:-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-size: 25px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    input.main-search-input:-moz-placeholder {
      font-size: 20px; } }
  @media (max-width: 767px) {
    input.main-search-input:-moz-placeholder {
      font-size: 16px; } }

input.main-search-input::-moz-placeholder {
  color: #fff;
  opacity: 1;
  font-size: 25px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    input.main-search-input::-moz-placeholder {
      font-size: 20px; } }
  @media (max-width: 767px) {
    input.main-search-input::-moz-placeholder {
      font-size: 16px; } }

input.main-search-input:-ms-input-placeholder {
  color: #fff;
  font-size: 25px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    input.main-search-input:-ms-input-placeholder {
      font-size: 20px; } }
  @media (max-width: 767px) {
    input.main-search-input:-ms-input-placeholder {
      font-size: 16px; } }

.search-close {
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer; }

.mobile-links li > a {
  padding: 15px 0 15px 15px;
  line-height: 20px;
  border-bottom: 1px solid #ebebeb;
  color: #7f7f7f;
  display: block; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
03. Navigation
*************************
*/
.main-menu ul li {
  display: inline-block;
  margin: 0px 20px;
  list-style: none;
  position: relative;
  /* sub-menu here*/ }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .main-menu ul li {
      margin: 0 15px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu ul li {
      margin: 0 15px; } }
  .main-menu ul li.menu-item-has-children > a::after {
    content: "\f107";
    font-family: "Font Awesome 5 Pro";
    margin-left: 3px;
    display: inline-block;
    transform: translateY(1px);
    opacity: 0;
    visibility: hidden; }
  .main-menu ul li.active > a {
    color: var(--clr-body-heading-color); }
  .main-menu ul li a {
    padding: 62px 0;
    display: block;
    text-decoration: none;
    color: var(--clr-common-menu-color);
    font-weight: 700;
    font-size: 16px; }
  .main-menu ul li:hover > a {
    color: var(--clr-body-heading-color); }
  .main-menu ul li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%; }
  .main-menu ul li ul.sub-menu {
    position: absolute;
    left: 0;
    top: 100%;
    width: 250px;
    border-top: 4px solid var(--clr-theme-1);
    background-color: var(--clr-common-white);
    text-align: start;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    transform: scaleY(0);
    transform-origin: top;
    z-index: 3; }
    .main-menu ul li ul.sub-menu li {
      margin: 0;
      display: block;
      padding: 0 30px; }
      .main-menu ul li ul.sub-menu li:last-child {
        border-bottom: 0; }
      .main-menu ul li ul.sub-menu li a {
        padding: 12px 0;
        text-align: start;
        display: block;
        border-bottom: 1px solid rgba(90, 90, 90, 0.11);
        position: relative;
        color: var(--clr-common-menu-color); }
        .main-menu ul li ul.sub-menu li a:after {
          content: "";
          width: 10px;
          height: 2px;
          background-color: var(--clr-theme-1);
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translate(0, -50%);
          opacity: 0;
          transition: all 220ms linear 0ms; }
        .main-menu ul li ul.sub-menu li a:hover {
          padding-left: 18px;
          color: var(--clr-body-heading-color); }
          .main-menu ul li ul.sub-menu li a:hover:after {
            opacity: 1;
            visibility: visible;
            left: 0; }
  .main-menu ul li:hover .sub-menu {
    transform: scaleY(1);
    opacity: 1;
    visibility: visible; }

.main-menu:is(.menu_2) ul li.menu-item-has-children ul.sub-menu {
  z-index: 3; }

.main-menu:is(.menu_2) ul li a {
  padding: 43px 0; }

.main-menu .metismenu:is(.theme-1) ul li:hover > a {
  color: var(--clr-theme-1); }

.main-menu .metismenu:is(.theme-1) ul li .sub-menu li a:hover {
  color: var(--clr-theme-1); }

.metismenu li, .metismenu ul li {
  list-style: none;
  list-style-type: none; }
  .metismenu li a, .metismenu ul li a {
    display: block;
    width: 100%;
    padding: 10px 0;
    color: #101a23;
    border-top: 1px solid #ebebeb;
    font-size: 14px;
    font-weight: 700; }
    .metismenu li a:hover, .metismenu ul li a:hover {
      color: #f15b43; }

.metismenu li:first-child > a {
  border-top: 0px; }

.metismenu li ul {
  padding-left: 15px; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
04. Slider
*************************
*/
.img_topleft {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 50%; }

.img_topright {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%; }

.img_bottomleft {
  position: absolute;
  left: -60px;
  bottom: -125px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .img_bottomleft {
      max-width: 50%; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .img_bottomleft {
      max-width: 50%; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .img_bottomleft {
      max-width: 50%; } }
  @media (max-width: 767px) {
    .img_bottomleft {
      max-width: 100%; } }

.img_bottomright {
  position: absolute;
  bottom: 0;
  right: 60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .img_bottomright {
      right: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .img_bottomright {
      right: 0px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .img_bottomright {
      right: 0px; } }
  @media (max-width: 767px) {
    .img_bottomright {
      display: none; } }

.slider_area {
  position: relative;
  background-color: var(--clr-common-slider-bg-color);
  overflow: hidden; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    .slider_content:is(.text_space) {
      margin-bottom: 158px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .slider_content:is(.text_space) {
      margin-bottom: 158px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .slider_content:is(.text_space) {
      margin-bottom: 158px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider_content:is(.text_space) {
      margin-bottom: 158px; } }
  @media (max-width: 767px) {
    .slider_content:is(.text_space) {
      margin-bottom: 0; } }
  .slider_content .slider_button {
    position: absolute;
    right: 70px;
    top: 60px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .slider_content .slider_button {
        position: static;
        margin-top: 30px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .slider_content .slider_button {
        position: static;
        margin-top: 30px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .slider_content .slider_button {
        position: static;
        margin-top: 30px; } }
    @media (max-width: 767px) {
      .slider_content .slider_button {
        position: static;
        margin-top: 20px; } }
  .slider_area .slider_text {
    position: relative;
    z-index: 1; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
      .slider_area .slider_text:is(.text_overlay) {
        padding: 30px;
        padding-bottom: 45px; }
        .slider_area .slider_text:is(.text_overlay):before {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background-color: #fff;
          opacity: 0.6;
          z-index: -1;
          border-radius: 0 30px 30px 30px; } }
    .slider_area .slider_text .slider_title {
      font-size: 152px;
      line-height: 1;
      font-weight: 700;
      color: var(--clr-body-heading-color); }
      @media only screen and (min-width: 1200px) and (max-width: 1400px) {
        .slider_area .slider_text .slider_title {
          font-size: 145px; } }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .slider_area .slider_text .slider_title {
          font-size: 125px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .slider_area .slider_text .slider_title {
          font-size: 95px; } }
      @media (max-width: 767px) {
        .slider_area .slider_text .slider_title {
          font-size: 75px; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .slider_area .slider_text .slider_title {
          font-size: 90px; } }
      .slider_area .slider_text .slider_title span {
        font-size: 120px;
        font-weight: 100;
        line-height: 1.12; }
        @media only screen and (min-width: 1200px) and (max-width: 1400px) {
          .slider_area .slider_text .slider_title span {
            font-size: 105px; } }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .slider_area .slider_text .slider_title span {
            font-size: 95px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .slider_area .slider_text .slider_title span {
            font-size: 85px; } }
        @media (max-width: 767px) {
          .slider_area .slider_text .slider_title span {
            font-size: 65px;
            display: block; } }

.slider_height {
  min-height: 700px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .slider_height {
      min-height: 800px; } }
  @media (max-width: 767px) {
    .slider_height {
      min-height: 570px; } }

.slider_height_2 {
  min-height: 750px; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
05. Accordion
*************************
*/
.accordion-item:first-of-type .accordion-button:is(.faq_button) {
  border-radius: 10px;
  padding-right: 70px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), (max-width: 767px) {
    .accordion-item:first-of-type .accordion-button:is(.faq_button) {
      padding-right: 50px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .accordion-item:first-of-type .accordion-button:is(.faq_button) {
      padding-right: 70px; } }

.accordion-item:last-of-type .accordion-button:is(.faq_button) {
  border-radius: 10px; }
  .accordion-item:last-of-type .accordion-button:is(.faq_button):is(.collapsed) {
    border-radius: 10px; }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .accordion_wrapper {
    margin-right: 15px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .accordion_wrapper {
    margin-right: 0; } }

.accordion_wrapper:is(.padd_80) {
  padding: 80px;
  border-radius: 10px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .accordion_wrapper:is(.padd_80) {
      padding: 50px 30px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .accordion_wrapper:is(.padd_80) {
      padding: 70px 50px; } }
  @media (max-width: 767px) {
    .accordion_wrapper:is(.padd_80) {
      padding: 30px 15px; } }
  @media (max-width: 767px) {
    .accordion_wrapper:is(.padd_80) {
      padding: 40px 25px; } }

.accordion_wrapper .accordion-item {
  border: 2px solid #efefef;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 16px; }
  .accordion_wrapper .accordion-item:last-of-type {
    border-radius: 10px;
    margin-bottom: 0; }
  .accordion_wrapper .accordion-item:first-of-type {
    border-radius: 10px; }

.accordion_wrapper .accordion-body {
  padding: 26px;
  padding-right: 28px;
  padding-top: 0;
  background-color: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.75; }

.accordion-button:is(.faq_button) {
  padding: 22px 26px;
  font-size: 18px;
  color: var(--clr-body-heading-color);
  border-radius: 10px;
  padding-right: 50px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), (max-width: 767px) {
    .accordion-button:is(.faq_button) {
      padding-right: 50px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .accordion-button:is(.faq_button) {
      padding-right: 50px; } }
  .accordion-button:is(.faq_button):after {
    color: #bebebe;
    font-size: 18px;
    position: absolute;
    right: 20px;
    top: 50%;
    font-family: 'font awesome 5 pro';
    content: "\f067";
    transform: translateY(-50%);
    background-image: none;
    transition: .5s ease-in-out; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), (max-width: 767px) {
      .accordion-button:is(.faq_button):after {
        right: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .accordion-button:is(.faq_button):after {
        right: 20px; } }
  .accordion-button:is(.faq_button):not(.collapsed) {
    color: var(--clr-body-heading-color);
    background-color: transparent;
    box-shadow: none; }
    .accordion-button:is(.faq_button):not(.collapsed)::after {
      content: "\f068";
      transition: .5s ease-in-out; }
  .accordion-button:is(.faq_button):focus {
    border-color: #bebebe;
    box-shadow: none; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
06. Breadcrumb
*************************
*/
.breadcrumb_area {
  padding-top: 20px;
  padding-bottom: 20px; }

.breadcrumb_overlay {
  position: relative;
  background-size: cover;
  background-position: center center;
  z-index: 1; }
  .breadcrumb_overlay:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    /* opacity: 0.5; */
    z-index: -1; }

.breadcrumb-list li {
  display: inline-block;
  position: relative;
  padding-right: 18px;
  margin-right: 20px; }
  .breadcrumb-list li:before {
    position: absolute;
    right: 0;
    top: 50%;
    width: 2px;
    height: 15px;
    content: "";
    background-color: #adadad;
    transform: translateY(-50%); }
  .breadcrumb-list li:last-child {
    padding-right: 0;
    margin-right: 0; }
    .breadcrumb-list li:last-child a {
      color: var(--clr-theme-1); }
    .breadcrumb-list li:last-child:before {
      display: none; }
  .breadcrumb-list li a {
    text-decoration: none;
    color: #fff;
    font-size: 16px; }
  .breadcrumb-list li span {
    color: var(--clr-theme-1);
    font-size: 16px; }

.breadcrumb-list:is(.volunteer_breadcrumb) li span.active {
  color: #ffae00; }

.breadcrumb_title h2, .breadcrumb_title .h2 {
  font-size: 80px;
  color: #fff; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .breadcrumb_title h2, .breadcrumb_title .h2 {
      font-size: 70px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .breadcrumb_title h2, .breadcrumb_title .h2 {
      font-size: 60px; } }
  @media (max-width: 767px) {
    .breadcrumb_title h2, .breadcrumb_title .h2 {
      font-size: 38px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .breadcrumb_title h2, .breadcrumb_title .h2 {
      font-size: 50px; } }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
07. Contact
*************************
*/
.contact_map {
  height: 700px; }
  .contact_map iframe {
    width: 100%;
    height: 100%; }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .get_intouch_area {
    padding-top: 105px; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .contact_location_section {
    padding-left: 0px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact_location_section {
    padding-left: 25px; } }

.contact_section {
  padding: 58px;
  padding-top: 52px;
  background-color: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  border-radius: 10px; }
  @media (max-width: 767px) {
    .contact_section {
      padding: 40px 30px;
      padding-top: 34px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact_section {
      padding: 50px;
      padding-top: 44px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact_section:is(.contact_contact) {
      padding: 50px 40px;
      padding-top: 44px; } }
  .contact_section input {
    width: 100%;
    padding-bottom: 13px;
    border: 0;
    border-bottom: 1px solid #d3d3d3;
    margin-bottom: 23px;
    color: #999; }
    .contact_section input::-webkit-input-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .contact_section input:-moz-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .contact_section input::-moz-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .contact_section input:-ms-input-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
  .contact_section textarea {
    border: 0;
    border-bottom: 1px solid #d3d3d3;
    width: 100%;
    margin-bottom: 25px;
    color: #999;
    font-size: 14px;
    height: 180px; }
    .contact_section textarea::-webkit-input-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      opacity: 1; }
    .contact_section textarea:-moz-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      opacity: 1; }
    .contact_section textarea::-moz-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      opacity: 1; }
    .contact_section textarea:-ms-input-placeholder {
      color: #c3c3c3;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      opacity: 1; }
    .contact_section textarea:focus::-webkit-input-placeholder {
      opacity: 0;
      visibility: hidden; }
    .contact_section textarea:focus:-moz-placeholder {
      opacity: 0;
      visibility: hidden; }
    .contact_section textarea:focus::-moz-placeholder {
      opacity: 0;
      visibility: hidden; }
    .contact_section textarea:focus:-ms-input-placeholder {
      opacity: 0;
      visibility: hidden; }
    @media (max-width: 767px) {
      .contact_section textarea {
        height: 120px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .contact_section textarea {
        height: 180px; } }
  .contact_section:is(.has_border) {
    border: 2px solid #efefef; }
  .contact_section:is(.bottom_radius0) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

#contact_select {
  width: 100%;
  border: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
  color: #999;
  font-size: 14px;
  line-height: 1;
  appearance: none;
  -moz-appearance: none; }

.contact_btn {
  height: 60px;
  padding: 0 50px;
  border-radius: 30px;
  color: #fff;
  font-size: 16px;
  background-color: #5fb35b; }
  .contact_btn:hover {
    background-color: var(--clr-theme-1); }

.single_contact_location {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  .single_contact_location .icon {
    flex: 0 0 80px;
    height: 80px;
    background-color: #fff;
    line-height: 80px;
    text-align: center;
    font-size: 24px;
    border-radius: 50%;
    margin-right: 30px;
    transition: 0.5s;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
    @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_contact_location .icon {
        margin-right: 20px;
        flex: 0 0 60px;
        height: 60px;
        font-size: 20px;
        line-height: 60px; } }
  .single_contact_location:is(.theme-1) .icon {
    color: var(--clr-theme-1); }
    .single_contact_location:is(.theme-1) .icon:hover {
      background-color: var(--clr-theme-1);
      color: var(--clr-common-white); }
  .single_contact_location:is(.theme-1) .contact_address_title:hover {
    color: var(--clr-theme-1); }
  .single_contact_location:is(.theme-2) .icon {
    color: var(--clr-theme-2); }
    .single_contact_location:is(.theme-2) .icon:hover {
      background-color: var(--clr-theme-2);
      color: var(--clr-common-white); }
  .single_contact_location:is(.theme-2) .contact_address_title:hover {
    color: var(--clr-theme-2); }
  .single_contact_location:is(.theme-6) .icon {
    color: var(--clr-theme-6); }
    .single_contact_location:is(.theme-6) .icon:hover {
      background-color: var(--clr-theme-6);
      color: var(--clr-common-white); }
  .single_contact_location:is(.theme-6) .contact_address_title {
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s; }
    .single_contact_location:is(.theme-6) .contact_address_title:hover {
      color: var(--clr-theme-6); }

.contact_location_text span {
  font-size: 16px;
  display: block; }
  @media (max-width: 767px) {
    .contact_location_text span {
      font-size: 14px; } }

.contact_address_title {
  font-size: 24px;
  margin-bottom: 0;
  text-decoration: none;
  color: var(--clr-body-heading-color);
  font-weight: 700; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact_address_title {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .contact_address_title {
      font-size: 17px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .contact_address_title {
      font-size: 20px; } }
  .contact_address_title:hover {
    color: var(--clr-body-heading-color); }

/*  Contact nice Select Style   */
.nice-select.contact_select .option:hover, .nice-select.contact_select .option.focus, .nice-select.contact_select .option.selected.focus {
  background-color: #257F26; }

.nice-select:is(.contact_select) {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #d3d3d3;
  padding: 0;
  border-radius: 0;
  height: 39px;
  line-height: 30px;
  margin-bottom: 23px; }
  .nice-select:is(.contact_select) span.current {
    color: #999; }
  .nice-select:is(.contact_select):after {
    right: 0px;
    color: #999;
    font-size: 16px;
    margin-top: -3px; }
  .nice-select:is(.contact_select) .list {
    background-color: #5FB35B;
    color: #fff;
    border: 0; }
    .nice-select:is(.contact_select) .list li:hover {
      background-color: #257F26; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
08. Mission
*************************
*/
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_wrapper {
    padding-left: 0; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_wrapper :is(.title_2):is(.no_br) br {
    display: none; } }

.about_signle_video {
  position: relative;
  border-radius: 30px;
  overflow: hidden; }
  .about_signle_video img {
    transition: 0.5s; }
  .about_signle_video:hover img {
    transform: scale(1.1); }

.video_icon {
  width: 100px;
  height: 100px;
  background-color: var(--clr-theme-1);
  line-height: 100px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 1; }
  .video_icon i {
    font-size: 16px;
    color: var(--clr-common-white);
    font-weight: 700;
    margin-left: 5px;
    transition: 0.3s; }
  .video_icon:hover {
    background-color: var(--clr-common-white); }
    .video_icon:hover i {
      color: var(--clr-theme-1); }

.about_subscribe {
  position: relative; }
  .about_subscribe > img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(50% - 15px);
    object-fit: cover; }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .about_subscribe > img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: -1; } }

.about_subscribe {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .about_subscribe:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #000;
      opacity: 0.5; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe_mission {
    position: relative;
    color: #fff; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe_mission :is(.title_2):is(.text_white) {
    color: #fff; } }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
09. Cta
*************************
*/
.sub_title_2 {
  display: block;
  font-size: 14px;
  line-height: 32px;
  color: var(--clr-theme-2); }

.section_title_2 {
  font-size: 50px;
  line-height: 1;
  color: var(--clr-common-white); }
  @media (max-width: 767px) {
    .section_title_2 {
      font-size: 38px; } }

.donar_input {
  height: 60px;
  background: transparent;
  border-radius: 50px;
  padding: 15px 15px;
  padding-left: 30px;
  line-height: 60px;
  display: inline-block;
  border: 2px solid var(--clr-common-border-color);
  width: 166px;
  color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1); }
  .donar_input:focus {
    border-color: var(--clr-common-white);
    transition: 0.9s; }
  @media (max-width: 767px) {
    .donar_input:last-child {
      margin-right: 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .donar_input:last-child {
      margin-right: 15px; } }
  @media (max-width: 767px) {
    .donar_input {
      width: 135px; } }

.donar_section input::-webkit-input-placeholder {
  color: #758394;
  font-size: 14px; }

.donar_section input:-moz-placeholder {
  color: #758394;
  font-size: 14px; }

.donar_section input::-moz-placeholder {
  color: #758394;
  font-size: 14px; }

.donar_section input:-ms-input-placeholder {
  color: #758394;
  font-size: 14px; }

.currency {
  min-width: 60px;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  border-radius: 50px;
  text-align: center;
  padding: 0 20px;
  border: 2px solid var(--clr-common-border-color);
  background: #35485f;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1); }
  .currency:is(.custom_padd) {
    padding: 0 30px; }
    @media (max-width: 767px) {
      .currency:is(.custom_padd) {
        padding: 0 27px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .currency:is(.custom_padd) {
        padding: 0 30px; } }
  .currency:is(.funfact) {
    border-color: #f5f5f5;
    background-color: transparent;
    color: #142645;
    margin-right: 5px; }
    @media (max-width: 767px) {
      .currency:is(.funfact) {
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 2px;
        padding: 0; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .currency:is(.funfact) {
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin-right: 5px; } }
    .currency:is(.funfact):last-child {
      margin-right: 0; }
    .currency:is(.funfact):hover {
      background-color: var(--clr-theme-2);
      color: #fff; }
  .currency:hover {
    background-color: var(--clr-theme-2);
    border-color: var(--clr-theme-2); }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .donation {
    width: 300px; } }

@media (max-width: 767px) {
  .donation {
    width: 300px; } }

/*  cta fluid area   */
.container_spance0 {
  padding-left: 0;
  padding-right: 0; }

.cta_single {
  padding: 55px 60px;
  height: 100%; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px), (max-width: 767px) {
    .cta_single {
      padding: 55px 30px; } }
  .cta_single:is(.cta_color_1) {
    background-color: #d13942; }
  .cta_single:is(.cta_color_2) {
    background-color: #142645; }
  .cta_single p {
    font-size: 30px;
    color: #fff;
    line-height: 1.33;
    margin-bottom: 52px; }
    @media only screen and (min-width: 1401px) and (max-width: 1600px), (max-width: 767px) {
      .cta_single p {
        font-size: 25px;
        margin-bottom: 50px; } }

.cta_sub {
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  display: block; }

.video_wrapper_3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-size: cover;
  background-position: center center;
  padding: 140px 0; }
  .video_wrapper_3 .play_btn {
    background-color: #fff;
    color: var(--clr-theme-1);
    transition: 0.5s; }
    .video_wrapper_3 .play_btn:hover {
      background-color: var(--clr-theme-1);
      color: var(--clr-common-white); }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*      General Style of Buttons       */
.g_btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  min-width: 140px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
  z-index: 1;
  color: var(--clr-common-white);
  /* For Styling Button */
  /* To Left Right Style */
  /* To Right Style */
  /* To Left Style */
  /* To Top Style */
  /* To Bottom Style */
  /* To Diagonal Style */
  /* ###################  
  Hover start  
  ###################### */ }
  .g_btn:is(.hbtn_1) {
    padding: 0 45px;
    background-color: var(--clr-theme-2);
    color: var(--clr-common-white); }
  .g_btn:is(.theme1_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-1);
    padding: 0 45px; }
  @media (max-width: 767px) {
    .g_btn:is(.slider_btn) {
      padding: 0 45px; } }
  .g_btn:is(.theme2_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-2);
    padding: 0 45px; }
  .g_btn:is(.heading_bg) {
    color: var(--clr-common-white);
    background-color: var(--clr-body-heading-color);
    padding: 0 45px; }
  .g_btn:is(.cause_btn) {
    background-color: #ff9a39;
    color: #fff;
    padding: 0 50px; }
    .g_btn:is(.cause_btn) i {
      margin-left: 10px; }
  .g_btn:is(.trans_btn) {
    color: var(--clr-body-heading-color);
    border: 2px solid #ececec;
    line-height: 56px; }
    .g_btn:is(.trans_btn):hover {
      color: var(--clr-common-white);
      background-color: var(--clr-theme-1);
      border-color: var(--clr-theme-1); }
  .g_btn:is(.red_bg) {
    color: var(--clr-common-white);
    background-color: #a51d25; }
  .g_btn:is(.donation_btn) {
    height: 56px;
    line-height: 50px;
    background-color: #3d7d3c;
    border-radius: 50px;
    border: 2px solid transparent; }
    .g_btn:is(.donation_btn):hover {
      background-color: transparent;
      border-color: var(--clr-common-white); }
  .g_btn:is(.i_left) i {
    margin-right: 5px;
    display: inline-block;
    transform: translateY(1px); }
  .g_btn:is(.i_right) i {
    margin-left: 10px;
    display: inline; }
  .g_btn span {
    position: absolute;
    z-index: -1;
    transition: 0.5s ease; }
  .g_btn:is(.fbtn_2) {
    background-color: #5fb35b; }
  .g_btn:is(.fca_btn) {
    background-color: var(--clr-theme-2);
    height: 50px;
    line-height: 50px;
    min-width: auto; }
  .g_btn:is(.fca_btn1) {
    background-color: var(--clr-theme-1);
    height: 50px;
    line-height: 50px; }
  .g_btn:is(.to_leftright) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_leftright) span {
      top: 50%;
      left: 50%;
      width: 0;
      height: 100%;
      transform: translate(-50%, -50%);
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_topbottom) {
    border-color: transparent; }
    .g_btn:is(.to_topbottom) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%);
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_diagonal_lr) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_diagonal_lr) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%) rotate(45deg);
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_diagonal_rl) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_diagonal_rl) span {
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0;
      transform: translate(-50%, -50%) rotate(-45deg);
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_right1) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_right1) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_right2) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_right2) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_right3) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_right3) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_right4) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_right4) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_rightheading) {
    border-color: var(--clr-body-heading-color); }
    .g_btn:is(.to_rightheading) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-body-heading-color); }
  .g_btn:is(.to_rightout) {
    border-color: var(--clr-theme-2); }
    .g_btn:is(.to_rightout) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-2); }
  .g_btn:is(.to_rightout_heading) {
    border-color: var(--clr-body-heading-color); }
    .g_btn:is(.to_rightout_heading) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-body-heading-color); }
  .g_btn:is(.to_left) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_left) span {
      right: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_leftout) {
    border-color: var(--clr-theme-3); }
    .g_btn:is(.to_leftout) span {
      left: 0;
      top: 0;
      width: 0;
      height: 100%;
      background-color: var(--clr-theme-3); }
  .g_btn:is(.to_top) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_top):is(.trans_btn) {
      border-color: #ececec; }
    .g_btn:is(.to_top) span {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_topout) {
    border-color: var(--clr-theme-4); }
    .g_btn:is(.to_topout) span {
      right: 0;
      top: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-4); }
  .g_btn:is(.to_bottom) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_bottom) span {
      right: 0;
      top: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_bottomout) {
    border-color: var(--clr-theme-1); }
    .g_btn:is(.to_bottomout) span {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 0;
      background-color: var(--clr-theme-1); }
  .g_btn:is(.to_diagonal) {
    border-color: var(--clr-soft-black); }
    .g_btn:is(.to_diagonal) span {
      left: 0;
      bottom: 0;
      width: 0;
      height: 0;
      background-color: var(--clr-soft-black); }
  .g_btn:is(.to_diagonalout) {
    border-color: var(--clr-soft-black); }
    .g_btn:is(.to_diagonalout) span {
      right: 0;
      top: 0;
      width: 0;
      height: 0;
      background-color: var(--clr-soft-black); }
  .g_btn:hover {
    color: var(--clr-common-white);
    /* To left Right style */
    /* To Right Style Hover */
    /* To Left Style */
    /* To Top Style */
    /* To Bottom Style */
    /* To Diagonal Style */ }
    .g_btn:hover:is(.to_leftright) {
      color: var(--clr-body-heading); }
      .g_btn:hover:is(.to_leftright) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_topbottom) {
      color: var(--clr-common-white);
      border-color: transparent; }
      .g_btn:hover:is(.to_topbottom) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonal_lr) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal_lr) span {
        width: 100%;
        height: 380%; }
    .g_btn:hover:is(.to_diagonal_rl) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal_rl) span {
        width: 100%;
        height: 380%; }
    .g_btn:hover:is(.to_right1, .to_right2, .to_right3, .to_right4, .to_rightheading) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_right1, .to_right2, .to_right3, .to_right4, .to_rightheading) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_rightout, .to_rightout_heading) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_rightout, .to_rightout_heading) span {
        right: auto;
        left: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_left) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_left) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_leftout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_leftout) span {
        left: auto;
        right: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_top) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_top) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_topout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_topout) span {
        bottom: 0;
        top: auto;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_bottom) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_bottom) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_bottomout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_bottomout) span {
        bottom: auto;
        top: 0;
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonal) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonal) span {
        width: 100%;
        height: 100%; }
    .g_btn:hover:is(.to_diagonalout) {
      color: var(--clr-common-white); }
      .g_btn:hover:is(.to_diagonalout) span {
        right: auto;
        top: auto;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
10. About
*************************
*/
@media (max-width: 767px) {
  .about_area:is(.about_area_2) {
    padding-top: 190px; } }

@media (max-width: 767px), only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_img_wrapper {
    margin-right: 0; } }

.about_list li {
  list-style: none;
  position: relative;
  padding-left: 37px;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  color: var(--clr-body-heading-color);
  transition: 0.3s; }
  .about_list li:before {
    position: absolute;
    left: 0;
    top: 50%;
    content: "\f00c";
    font-family: 'Font Awesome 5 Pro';
    display: inline-block;
    transform: translateY(-50%);
    color: var(--clr-theme-2);
    font-weight: 400; }

.about_list:is(.about_single_list) li:before {
  font-weight: 300;
  color: var(--clr-theme-1); }

.about_review {
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 60px 30px;
  width: 250px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about_review {
      padding: 50px 10px;
      width: auto; } }
  .about_review::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--clr-common-bg-overlay);
    opacity: 0.9; }
  .about_review .author {
    font-size: 14px;
    margin-bottom: 10px; }
  .about_review .review_text {
    line-height: 1.4;
    margin-bottom: 0;
    font-size: 20px; }

.about_review * {
  position: relative;
  color: #fff; }

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 767px) {
  .about_wrapper {
    padding-left: 0; } }

.about_wrapper p:is(.n_mb20) {
  margin-bottom: 20px; }

.about_img {
  position: relative;
  text-align: center;
  z-index: 1;
  display: block;
  overflow: hidden;
  z-index: 1; }
  .about_img:before {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.70196) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.70196) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.70196) 0%, rgba(0, 0, 0, 0) 100%); }
  .about_img .about_img_text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 8%;
    z-index: 3; }
    .about_img .about_img_text i {
      font-size: 80px;
      color: #fff;
      line-height: 1.1; }
      @media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
        .about_img .about_img_text i {
          font-size: 60px; } }
    .about_img .about_img_text .img_text {
      font-size: 16px;
      color: var(--clr-common-white); }
    .about_img .about_img_text:is(.theme-2) i {
      color: var(--clr-theme-2); }
    .about_img .about_img_text:is(.theme-2) .img_text:hover {
      color: var(--clr-theme-2); }
    .about_img .about_img_text:is(.theme-no1) i {
      color: #fecc00; }
    .about_img .about_img_text:is(.theme-no1) .img_text:hover {
      color: #fecc00; }
    .about_img .about_img_text:is(.theme-no) i {
      color: #ffb974; }
    .about_img .about_img_text:is(.theme-no) .img_text:hover {
      color: #ffb974; }
  .about_img img {
    transform: scale(1);
    transition: 0.5s; }
  .about_img:hover > img {
    transform: scale(1.1); }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .about_wrapper_home {
    padding-left: 0; } }

/*   About are  2  */
.about_summary {
  margin-bottom: 50px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about_summary {
      margin-bottom: 30px; } }
  .about_summary p {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 0;
    position: relative;
    padding-left: 34px;
    line-height: 1.54; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
      .about_summary p {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .about_summary p {
        font-size: 16px;
        padding-left: 25px; } }
    .about_summary p:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      content: "";
      background-color: #eee; }
    .about_summary p span:is(.theme-1) {
      color: var(--clr-theme-1);
      font-weight: 700; }
    .about_summary p span:is(.theme-2) {
      color: var(--clr-theme-2);
      font-weight: 700; }
    .about_summary p span:is(.theme-4) {
      color: var(--clr-theme-4);
      font-weight: 700; }

.about_img_wrapper_2 {
  position: relative; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about_img_wrapper_2 {
      margin-left: 0; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about_img_wrapper_2 {
      margin-bottom: 40px; } }
  @media (max-width: 767px) {
    .about_img_wrapper_2 {
      margin-bottom: 40px;
      margin-left: 0; } }

.about_champ_card {
  background-color: #5fb35b;
  color: #fff;
  padding: 40px 25px;
  padding-bottom: 35px;
  text-align: center;
  border: 10px solid #fff;
  border-radius: 5px;
  width: 180px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about_champ_card {
      padding: 20px 15px;
      padding-bottom: 15px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .about_champ_card {
      border-color: #f1f1f1; } }
  @media (max-width: 767px) {
    .about_champ_card {
      padding: 20px 15px;
      padding-bottom: 15px; } }
  .about_champ_card:is(.about_abs2) {
    position: absolute;
    left: -100px;
    bottom: 51px;
    z-index: 2; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .about_champ_card:is(.about_abs2) {
        left: 51px;
        bottom: -51px; } }
    @media (max-width: 767px) {
      .about_champ_card:is(.about_abs2) {
        left: 50%;
        bottom: auto;
        top: -90px;
        margin-left: -90px; } }
  .about_champ_card i {
    font-size: 70px;
    line-height: 1; }
  .about_champ_card span {
    font-size: 14px; }
  .about_champ_card:is(.about_abs_single) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    bottom: auto; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
      .about_champ_card:is(.about_abs_single) {
        left: 50%;
        border-color: #f1f1f1; } }

.abs_numb {
  font-size: 60px;
  color: #fff;
  position: relative;
  line-height: 1;
  margin-bottom: 0; }
  .abs_numb span {
    font-size: 18px;
    position: absolute;
    bottom: 10px; }

/* about single */
@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about_features_area {
    padding-top: 105px; } }

.about_wrapper > p {
  line-height: 1.67; }

.about_white_bg {
  background-color: #fff;
  max-width: 1520px;
  margin: 0 auto;
  padding-bottom: 150px;
  margin-bottom: -100px;
  position: relative; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .about_white_bg {
      margin-bottom: 0;
      padding-bottom: 120px; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .about_team_img {
    margin-bottom: 40px;
    padding-right: 0; } }

.about_signle_img {
  position: relative;
  display: inline-block; }
  .about_signle_img .small_single {
    position: absolute;
    left: 295px;
    top: 50%;
    transform: translateY(-50%); }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .about_signle_img .small_single {
        left: 185px;
        display: block; } }
    @media (max-width: 767px) {
      .about_signle_img .small_single {
        display: none; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .about_signle_img .small_single {
        left: 155px;
        display: block; } }

.about_feature_icon {
  position: relative;
  margin-bottom: 35px; }
  .about_feature_icon i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 120px;
    line-height: 1;
    background: -webkit-linear-gradient(#999, #fff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text; }

.about_feature_wrapper {
  padding: 45px 30px;
  padding-top: 48px;
  background-color: #fff;
  text-align: center;
  border: 2px solid #e9e9e9;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s; }
  .about_feature_wrapper:hover {
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
    .about_feature_wrapper:hover .feature_number {
      animation: icon-bounce 0.8s 1; }
    .about_feature_wrapper:hover .about_feature_title:is(.theme-1) {
      color: var(--clr-theme-1); }
    .about_feature_wrapper:hover .about_feature_title:is(.theme-2) {
      color: var(--clr-theme-2); }
    .about_feature_wrapper:hover .about_feature_title:is(.theme-3) {
      color: var(--clr-theme-3); }

.about_feature_title {
  font-size: 20px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }

.about_feature_text p {
  font-size: 16px;
  margin-bottom: 0; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
11. Section Title
*************************
*/
.sub_title {
  font-size: 18px;
  color: #f15b43;
  line-height: 2.2;
  font-weight: 400; }
  .sub_title i {
    margin-right: 15px;
    font-size: 17px; }
  .sub_title:is(.sub_title_2) {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 400;
    line-height: 2.7;
    text-decoration: none; }

.title {
  color: var(--clr-body-heading-color);
  font-size: 60px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .title {
      font-size: 45px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .title {
      font-size: 35px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .title {
      font-size: 50px; } }
  @media (max-width: 767px) {
    .title {
      font-size: 24px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .title {
      font-size: 40px; } }
  .title:is(.title_2) {
    font-size: 55px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .title:is(.title_2) {
        font-size: 45px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .title:is(.title_2) {
        font-size: 35px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .title:is(.title_2) {
        font-size: 35px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .title:is(.title_2) {
        font-size: 25px; } }
    @media (max-width: 767px) {
      .title:is(.title_2) {
        font-size: 23px; } }

.section_title {
  position: relative;
  z-index: 1; }
  .section_title .back_title {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -65px;
    transform: translateY(0);
    font-size: 200px;
    font-weight: 700;
    background: -webkit-linear-gradient(#ffffff, #e3ecef);
    background-clip: border-box;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    z-index: -1; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .section_title .back_title {
        font-size: 160px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .section_title .back_title {
        font-size: 120px;
        bottom: -40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .section_title .back_title {
        font-size: 100px;
        bottom: -35px; } }
    @media (max-width: 767px) {
      .section_title .back_title {
        bottom: -25px;
        font-size: 75px; } }

.choose_card_title {
  font-size: 20px; }

.donation_title {
  font-size: 36px; }
  @media (max-width: 767px) {
    .donation_title {
      font-size: 23px; } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
12. Cause
*************************
*/
.donate_progress_wrapper label {
  font-size: 16px;
  margin-bottom: 10px;
  color: #fff; }
  .donate_progress_wrapper label span {
    font-size: 28px;
    font-weight: 700; }
  .donate_progress_wrapper label:is(.event_lab) {
    color: #a4b1c4; }

.progress:is(.cause_progress) {
  background-color: transparent;
  height: 4px;
  border-radius: 0;
  overflow: visible;
  position: relative; }
  .progress:is(.cause_progress):after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--clr-theme-1);
    opacity: 0.2; }
  .progress:is(.cause_progress) .progress-bar {
    background-color: var(--clr-theme-1);
    position: relative;
    overflow: visible; }
    .progress:is(.cause_progress) .progress-bar span {
      position: absolute;
      font-size: 16px;
      background-color: var(--clr-theme-1);
      right: 0;
      font-size: 14px;
      color: var(--clr-common-white);
      top: 0;
      background-color: var(--clr-theme-1);
      width: 42px;
      height: 26px;
      line-height: 26px; }

.progress:is(.donate_progress) {
  background-color: transparent;
  height: 15px;
  border-radius: 50px;
  overflow: visible;
  position: relative; }
  .progress:is(.donate_progress):after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--clr-common-white);
    opacity: 0.2;
    border-radius: 50px; }
  .progress:is(.donate_progress) .progress-bar {
    background-color: #3d7d3c;
    position: relative;
    overflow: visible;
    border-radius: 50px 0 0 50px;
    position: relative;
    z-index: 1;
    opacity: 1; }
    .progress:is(.donate_progress) .progress-bar:is(.theme-3) {
      background-color: var(--clr-theme-3); }

.progress:is(.feature_progress) {
  background-color: transparent;
  height: 10px;
  overflow: visible;
  position: relative; }
  .progress:is(.feature_progress):after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #fff0f1; }
  .progress:is(.feature_progress) .progress-bar {
    background-color: var(--clr-theme-1);
    overflow: visible;
    border-radius: 0;
    position: relative;
    z-index: 1;
    opacity: 1;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }

.progress:is(.team_progress) {
  margin-top: 15px;
  height: 4px;
  background-color: #f1f1f1; }
  .progress:is(.team_progress) .progress-bar:is(.theme-1) {
    background-color: var(--clr-theme-1); }
  .progress:is(.team_progress) .progress-bar:is(.theme-2) {
    background-color: var(--clr-theme-2); }
  .progress:is(.team_progress) .progress-bar:is(.theme-6) {
    background-color: var(--clr-theme-6); }

.progress_value {
  float: right; }
  .progress_value:is(.theme-1) {
    color: var(--clr-theme-1); }
  .progress_value:is(.theme-2) {
    color: var(--clr-theme-2); }
  .progress_value:is(.theme-6) {
    color: var(--clr-theme-6); }

.support_bg {
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.04); }

.single_cause_content {
  padding: 35px 37px;
  padding-top: 27px;
  border: 2px solid;
  border-top: 0;
  border-color: #eeeeee;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
  background-color: var(--clr-common-white); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_cause_content {
      padding: 30px;
      padding-top: 25px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_cause_content {
      padding: 30px;
      padding-top: 25px; } }
  @media (max-width: 767px) {
    .single_cause_content {
      padding: 25px;
      padding-top: 20px; } }
  .single_cause_content:is(.single_cause_content_2) {
    padding: 38px;
    padding-top: 33px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_cause_content:is(.single_cause_content_2) {
        padding: 26px;
        padding-top: 22px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single_cause_content:is(.single_cause_content_2) {
        padding: 33px;
        padding-top: 28px; } }
    @media (max-width: 767px) {
      .single_cause_content:is(.single_cause_content_2) {
        padding: 25px;
        padding-top: 20px; } }

.single_cause .sub_title {
  font-size: 18px;
  color: var(--clr-theme-2); }

.single_cause .title {
  font-size: 24px;
  line-height: 1.42;
  font-weight: 500; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single_cause .title {
      font-size: 21px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_cause .title {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_cause .title {
      font-size: 21px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_cause .title {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .single_cause .title {
      font-size: 20px; } }
  .single_cause .title a {
    text-decoration: none; }
    .single_cause .title a:hover {
      color: var(--clr-theme-1); }

.single_cause:hover a:after {
  animation: shine 800ms; }

.single_cause:hover a img {
  filter: grayscale(90%);
  transform: scale(1.1); }

.single_cause_img {
  overflow: hidden; }

.img_effect_white {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px; }
  .img_effect_white a:after {
    content: '';
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg); }
  .img_effect_white a img {
    transition: 0.5s;
    width: 100%; }

.support_img_single:hover a:after, .single_nfeed:hover a:after, .single_team:hover a:after, .events_img:hover a:after, .nfeed_single_3:hover a:after, .about_video_image_wrapper:hover a:after {
  animation: shine .8s; }

.support_img_single:hover a img, .single_nfeed:hover a img, .single_team:hover a img, .events_img:hover a img, .nfeed_single_3:hover a img, .about_video_image_wrapper:hover a img {
  filter: grayscale(90%);
  transform: scale(1.1); }

.events_img {
  border-radius: 10px; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.single_meta {
  padding-right: 45px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single_meta {
      padding-right: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_meta {
      padding-right: 20px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single_meta {
      padding-right: 20px; } }
  @media (max-width: 767px) {
    .single_meta {
      padding-right: 19px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_meta {
      padding-right: 45px; } }
  .single_meta:last-child {
    padding-right: 0; }
  .single_meta .meta_text {
    font-size: 14px;
    color: var(--clr-common-grey);
    display: block;
    line-height: 2; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_meta .meta_text {
        font-size: 13px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single_meta .meta_text {
        font-size: 14px; } }
    .single_meta .meta_text i {
      display: inline-block;
      margin-right: 6px;
      cursor: pointer;
      transition: 0.3s; }
    .single_meta .meta_text:is(.clr_theme1):hover i {
      color: var(--clr-theme-1); }
    .single_meta .meta_text:is(.clr_theme2):hover i {
      color: var(--clr-theme-2); }
    .single_meta .meta_text:is(.clr_theme3):hover i {
      color: var(--clr-theme-3); }
    .single_meta .meta_text:is(.red_clr):hover {
      color: #d13942; }
  .single_meta .meta_price {
    font-size: 18px;
    color: var(--clr-theme-1);
    font-weight: 700; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_meta .meta_price {
        font-size: 15px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .single_meta .meta_price {
        font-size: 17px; } }
    @media (max-width: 767px) {
      .single_meta .meta_price {
        font-size: 15px; } }
    .single_meta .meta_price:is(.clr_theme1) {
      color: var(--clr-theme-1); }
    .single_meta .meta_price:is(.clr_theme2) {
      color: var(--clr-theme-2); }
    .single_meta .meta_price:is(.clr_theme3) {
      color: var(--clr-theme-3); }
    .single_meta .meta_price:is(.red_clr) {
      color: #d13942; }
    .single_meta .meta_price:is(.single_price) {
      font-size: 24px; }

/* Cause tab button style */
.tab_buttons {
  justify-content: center; }
  .tab_buttons button {
    background-color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
    padding: 0 45px;
    height: 60px;
    line-height: 60px;
    margin-right: 30px;
    border-radius: 30px;
    font-size: 16px;
    color: #777;
    margin-bottom: 15px; }
    @media (max-width: 767px) {
      .tab_buttons button {
        margin-right: 20px;
        padding: 0 33px;
        height: 45px;
        line-height: 45px;
        font-size: 14px; } }
    .tab_buttons button:is(.active) {
      background-color: var(--clr-body-heading-color);
      color: #fff; }
    .tab_buttons button:hover {
      background-color: var(--clr-body-heading-color);
      color: #fff; }
    .tab_buttons button:last-child {
      margin-right: 0; }
  .tab_buttons:is(.feature_buttons) button {
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04); }

/*  Feature cause 3  */
.feature_single {
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .feature_single {
      display: block;
      flex-direction: column;
      align-items: flex-start;
      padding: 70px; } }
  @media (max-width: 767px) {
    .feature_single {
      display: block;
      flex-direction: column;
      align-items: flex-start;
      padding: 50px 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feature_single {
      display: block;
      padding: 50px 40px; } }
  .feature_single .feature_single_img {
    flex: 0 0 498px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .feature_single .feature_single_img {
        flex: 0 0 420px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .feature_single .feature_single_img {
        flex: 0 0 auto;
        margin-bottom: 30px; } }
  .feature_single .feature_single_content {
    padding: 70px;
    padding-right: 100px;
    padding-top: 65px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .feature_single .feature_single_content {
        padding-right: 70px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .feature_single .feature_single_content {
        padding: 0 40px;
        padding-right: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .feature_single .feature_single_content {
        padding: 0; } }
    .feature_single .feature_single_content h4, .feature_single .feature_single_content .h4 {
      font-size: 35px;
      color: #142645;
      line-height: 1.2; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .feature_single .feature_single_content h4, .feature_single .feature_single_content .h4 {
          font-size: 26px; } }
      @media (max-width: 767px) {
        .feature_single .feature_single_content h4, .feature_single .feature_single_content .h4 {
          font-size: 23px; } }
    .feature_single .feature_single_content p {
      font-size: 16px; }

.feature_cause_wrapper {
  padding: 0 44px;
  margin-top: 25px;
  margin-bottom: 43px; }
  @media (max-width: 767px) {
    .feature_cause_wrapper {
      padding: 0 1px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feature_cause_wrapper {
      padding: 0 25px; } }

.feature_buttons {
  display: flex; }
  .feature_buttons:is(.widget_buttons) {
    justify-content: center; }
    .feature_buttons:is(.widget_buttons) :is(.reply_btn) i {
      transform: rotateY(180deg); }
    .feature_buttons:is(.widget_buttons) .hbtn_1 {
      padding: 0 35px; }
  @media (max-width: 767px) {
    .feature_buttons .reply_btn {
      display: none; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feature_buttons .reply_btn {
      display: block; } }

.reply_btn {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border: 1px solid #e7e7e7;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-left: 15px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  .reply_btn:is(.theme-2):hover {
    border-color: var(--clr-theme-2);
    background-color: var(--clr-theme-2);
    color: var(--clr-common-white); }
  .reply_btn:hover {
    border-color: var(--clr-theme-1);
    background-color: var(--clr-theme-1);
    color: var(--clr-common-white); }

.feature_meta:is(.feature_border) {
  padding-right: 110px;
  position: relative; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .feature_meta:is(.feature_border) {
      padding-right: 85px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .feature_meta:is(.feature_border) {
      padding-right: 70px; } }
  @media (max-width: 767px) {
    .feature_meta:is(.feature_border) {
      padding-right: 19px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feature_meta:is(.feature_border) {
      padding-right: 25px; } }
  .feature_meta:is(.feature_border):after {
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    background-color: #dce8eb;
    width: 60px;
    height: 2px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .feature_meta:is(.feature_border):after {
        right: 13px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .feature_meta:is(.feature_border):after {
        width: 50px;
        right: 10px; } }
    @media (max-width: 767px) {
      .feature_meta:is(.feature_border):after {
        display: none; } }

/* cause single page css */
.single_details_content {
  border: 2px solid #eeeeee;
  border-top: 0;
  padding: 80px;
  padding-top: 70px; }
  .single_details_content p {
    font-size: 16px;
    line-height: 1.75; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    .single_details_content {
      padding: 60px 40px;
      padding-top: 50px; } }
  @media (max-width: 767px) {
    .single_details_content {
      padding: 40px 25px;
      padding-top: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single_details_content {
      padding: 40px 30px;
      padding-top: 30px; } }

.details_title {
  font-size: 36px;
  line-height: 1.28; }
  @media (max-width: 767px) {
    .details_title {
      font-size: 20px; } }

.sub_title_details {
  font-size: 14px;
  color: var(--clr-theme-1);
  display: block;
  line-height: 2.2;
  margin-bottom: 8px;
  text-decoration: none; }

.single_border {
  border-bottom: 1px solid #dce8eb; }

@media (max-width: 767px) {
  .submit_info_wrapper {
    flex-wrap: wrap; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .submit_info_wrapper {
    flex-wrap: nowrap; } }

.single_person_quote {
  text-align: center;
  border: 2px solid #eeeeee;
  padding: 58px 70px;
  border-radius: 10px;
  margin-bottom: 45px;
  position: relative; }
  @media (max-width: 767px) {
    .single_person_quote {
      padding: 58px 15px;
      margin-bottom: 45px; } }
  .single_person_quote i {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 290px;
    top: 0;
    background-color: transparent;
    color: #eeee;
    z-index: -1; }
    @media (max-width: 767px) {
      .single_person_quote i {
        font-size: 220px; } }
  .single_person_quote:hover .person_image img {
    animation: icon-bounce 0.8s 1; }

.person_image {
  margin-bottom: 30px; }
  .person_image img {
    border-radius: 50%; }

.person_quote_text p {
  font-size: 20px;
  line-height: 1.7; }
  @media (max-width: 767px) {
    .person_quote_text p {
      font-size: 14px; } }

.person_nam {
  font-size: 18px;
  color: #111c2d;
  line-height: 1.88;
  margin-bottom: 0; }
  .person_nam:is(.f_size24) {
    font-size: 24px;
    line-height: 1.5; }
  .person_nam:hover {
    color: var(--clr-theme-1); }

.person_surname {
  font-size: 14px;
  color: var(--clr-theme-1); }

.page_pagination_withimg {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767px) {
    .page_pagination_withimg {
      flex-wrap: wrap; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .page_pagination_withimg {
      flex-wrap: nowrap; } }

.img_pagination {
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: 0.3s; }
  .img_pagination .left_img {
    margin-right: 25px; }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
      .img_pagination .left_img {
        margin-right: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .img_pagination .left_img {
        margin-bottom: 15px; } }
  .img_pagination .right_img {
    margin-left: 25px; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
      .img_pagination .right_img {
        margin-left: 15px; } }
    @media (max-width: 767px) {
      .img_pagination .right_img {
        margin-left: 0;
        margin-right: 15px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .img_pagination .right_img {
        margin-bottom: 15px;
        margin-right: 0; } }
  .img_pagination:hover img {
    filter: grayscale(100%); }
  @media (max-width: 767px) {
    .img_pagination:is(.img_pagination_left) {
      margin-bottom: 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .img_pagination:is(.img_pagination_left) {
      margin-bottom: 0px;
      flex-direction: column;
      align-items: flex-start; } }
  @media (max-width: 767px) {
    .img_pagination:is(.img_pagination_right) {
      flex-direction: row-reverse; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .img_pagination:is(.img_pagination_right) {
      margin-bottom: 0px;
      flex-direction: column-reverse;
      align-items: flex-end; } }

.sub_pagination {
  font-size: 14px;
  color: var(--clr-common-grey);
  display: block;
  margin-bottom: 5px; }

.pagination_title {
  font-size: 24px;
  color: var(--clr-theme-7);
  margin-bottom: 0; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .pagination_title {
      font-size: 18px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .pagination_title {
      font-size: 16px; } }

.widget_person_img {
  position: relative;
  display: inline-block;
  margin-bottom: 16px; }

.check_sign {
  display: inline-block;
  width: 28px;
  height: 28px;
  background-color: var(--clr-theme-8);
  color: var(--clr-common-white);
  font-size: 14px;
  line-height: 28px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 7px; }

.person_designation:is(.widget_mb25) {
  margin-bottom: 25px; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
13. Support
*************************
*/
.support_img_single:is(.img_effect_white) {
  border-top-right-radius: 0; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .support_img_single:is(.img_effect_white) {
      border-top-right-radius: 10px; } }

.single-support {
  padding: 0px 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  min-height: 480px;
  color: var(--clr-common-white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
  border-radius: 20px;
  overflow: hidden; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single-support {
      padding: 0px 33px;
      margin-left: 20px; } }
  @media (max-width: 767px) {
    .single-support {
      padding: 0px 10px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .single-support {
      margin-left: 0; } }
  .single-support::before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--clr-body-heading-color);
    opacity: 0.9; }
  .single-support .support_title {
    line-height: 1;
    color: var(--clr-common-white);
    font-size: 40px; }
    @media (max-width: 767px) {
      .single-support .support_title {
        font-size: 28px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .single-support .support_title {
        font-size: 33px; } }
  .single-support p {
    color: var(--clr-common-white);
    font-size: 16px; }
  .single-support .nice-select .current {
    line-height: 60px;
    font-weight: 700; }

.opacity_6 {
  opacity: 0.6; }

.support_search_section {
  position: relative;
  z-index: 3;
  color: var(--clr-common-white); }
  .support_search_section #Support {
    width: 100%;
    height: 60px;
    border: 2px solid #69778d;
    border-radius: 30px;
    padding-left: 150px;
    padding-right: 120px;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 700; }
    .support_search_section #Support::-webkit-input-placeholder {
      color: var(--clr-common-white);
      font-size: 16px; }
    .support_search_section #Support:-moz-placeholder {
      color: var(--clr-common-white);
      font-size: 16px; }
    .support_search_section #Support::-moz-placeholder {
      color: var(--clr-common-white);
      font-size: 16px; }
    .support_search_section #Support:-ms-input-placeholder {
      color: var(--clr-common-white);
      font-size: 16px; }
    @media (max-width: 767px) {
      .support_search_section #Support {
        padding-left: 105px;
        padding-right: 95px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .support_search_section #Support {
        padding-left: 150px;
        padding-right: 120px; } }
  .support_search_section .support_btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 112px;
    height: 60px;
    border-radius: 30px;
    background-color: var(--clr-theme-2);
    color: var(--clr-common-white);
    z-index: 1;
    border-color: transparent;
    padding-left: 32px;
    line-height: 60px;
    appearance: none;
    -moz-appearance: none; }
    @media (max-width: 767px) {
      .support_search_section .support_btn {
        width: 90px;
        padding-left: 25px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .support_search_section .support_btn {
        width: 112px;
        padding-left: 32px; } }
  .support_search_section .amount_btn {
    left: 0;
    position: absolute;
    top: 0;
    width: 120px;
    height: 60px;
    border-radius: 30px;
    background-color: var(--clr-theme-4);
    color: var(--clr-common-white);
    z-index: 3;
    font-weight: 700; }
    @media (max-width: 767px) {
      .support_search_section .amount_btn {
        width: 100px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .support_search_section .amount_btn {
        width: 120px; } }
    .support_search_section .amount_btn:is(.funfact_amount) {
      background-color: transparent;
      color: #142645;
      border: 2px solid #eeeeee;
      border-radius: 30px; }
      @media (max-width: 767px) {
        .support_search_section .amount_btn:is(.funfact_amount) {
          width: 90px; } }
  .support_search_section:is(.donate_section) #Support {
    padding-left: 35px;
    border: 2px solid #a4cea3; }
  .support_search_section:is(.donate_section) .support_btn {
    border-color: transparent;
    padding-left: 20px;
    width: 80px;
    background-color: #3d7d3c; }
  .support_search_section:is(.event_single) #Support {
    padding-left: 35px;
    border: 2px solid #334867; }
  .support_search_section:is(.event_single) .support_btn {
    border-color: transparent;
    padding-left: 20px;
    width: 80px;
    background-color: #102442; }
  @media (max-width: 767px) {
    .support_search_section:is(.funfact_input) #Support {
      padding-left: 100px;
      padding-right: 95px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .support_search_section:is(.funfact_input) #Support {
      padding-left: 110px;
      padding-right: 125px; } }

.support_currency .currency {
  background-color: var(--clr-theme-4);
  border: 0; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .support_currency .currency {
      padding: 0 20px;
      min-width: 60px;
      height: 60px;
      line-height: 60px; } }
  @media (max-width: 767px) {
    .support_currency .currency {
      padding: 0 15px;
      min-width: 55px;
      height: 55px;
      line-height: 55px; } }
  .support_currency .currency:hover {
    background-color: var(--clr-theme-2); }

.support_currency .curr_btn {
  background-color: var(--clr-theme-1); }
  .support_currency .curr_btn:hover {
    background-color: var(--clr-theme-4); }

.support_wrapper {
  padding: 30px 48px;
  padding-bottom: 35px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .support_wrapper {
      padding: 0px 25px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .support_wrapper {
      padding: 0px 15px; } }
  @media (max-width: 767px) {
    .support_wrapper {
      padding: 30px;
      padding-bottom: 35px; } }
  .support_wrapper p {
    padding-right: 30px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .support_wrapper p {
        padding-right: 0; } }

.funfact_input #Support {
  border: 2px solid #eeeeee;
  color: #cacaca; }
  .funfact_input #Support::-webkit-input-placeholder {
    color: #cacaca; }
  .funfact_input #Support:-moz-placeholder {
    color: #cacaca; }
  .funfact_input #Support::-moz-placeholder {
    color: #cacaca; }
  .funfact_input #Support:-ms-input-placeholder {
    color: #cacaca; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .funfact_input #Support {
      padding-left: 128px; } }

.dots_style .swiper-pagination-bullets.swiper-pagination-horizontal {
  width: 175px;
  height: 50px;
  background-color: var(--clr-body-heading-color);
  bottom: 0;
  left: 50%;
  margin-left: -87.5px;
  border-radius: 30px 30px 0 0;
  position: absolute;
  z-index: 3; }
  .dots_style .swiper-pagination-bullets.swiper-pagination-horizontal span.swiper-pagination-bullet {
    display: inline-block;
    margin: 23px 5px;
    background-color: #465874;
    opacity: 1;
    width: 15px;
    height: 4px;
    border-radius: 5px; }
  .dots_style .swiper-pagination-bullets.swiper-pagination-horizontal .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--clr-theme-2); }

.single_brand {
  transition: 0.3s;
  cursor: pointer; }
  .single_brand:hover {
    opacity: 0.7; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
14. Choose Single Card
*************************
*/
.choose_single_card {
  border: 2px solid #eeeeee;
  padding: 35px 45px;
  padding-top: 15px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  overflow: hidden;
  -webkit-transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -ms-transition: all 0.5s linear 0s;
  -o-transition: all 0.5s linear 0s;
  transition: all 0.5s linear 0s; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .choose_single_card {
      padding: 20px 30px;
      padding-top: 0px; } }
  @media (max-width: 767px) {
    .choose_single_card {
      padding: 35px 25px;
      padding-top: 15px; } }
  .choose_single_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 23px 29px rgba(0, 0, 0, 0.09); }
  .choose_single_card .choose_abs {
    position: absolute;
    right: -77px;
    top: -77px;
    width: 200px;
    height: 200px;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: flex-end; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 576px) and (max-width: 767px) {
      .choose_single_card .choose_abs {
        right: -90px;
        top: -90px; } }
    .choose_single_card .choose_abs i {
      font-size: 60px;
      position: relative;
      z-index: 9;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      padding-bottom: 25px;
      padding-left: 40px;
      color: var(--clr-common-white); }
      @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 576px) and (max-width: 767px) {
        .choose_single_card .choose_abs i {
          font-size: 45px;
          padding-bottom: 30px; } }
    .choose_single_card .choose_abs:is(.theme-2) {
      background-color: var(--clr-theme-2); }
      .choose_single_card .choose_abs:is(.theme-2) i {
        color: var(--clr-common-white); }
    .choose_single_card .choose_abs:is(.theme-3) {
      background-color: var(--clr-theme-3); }
      .choose_single_card .choose_abs:is(.theme-3) i {
        color: var(--clr-common-white); }
    .choose_single_card .choose_abs:is(.theme-4) {
      background-color: var(--clr-theme-5); }
      .choose_single_card .choose_abs:is(.theme-4) i {
        color: var(--clr-common-white); }
    .choose_single_card .choose_abs:is(.theme-5) {
      background-color: var(--clr-theme-8); }
      .choose_single_card .choose_abs:is(.theme-5) i {
        color: var(--clr-common-white); }
  .card_number {
    font-size: 80px;
    font-weight: 700;
    -webkit-text-fill-color: var(--clr-common-white);
    -webkit-text-stroke: 1px var(--clr-theme-2);
    position: relative;
    line-height: 1.6; }
    .card_number:is(.clr-theme-3) {
      -webkit-text-stroke: 1px var(--clr-theme-3); }
    .card_number:is(.clr-theme-4) {
      -webkit-text-stroke: 1px var(--clr-theme-5); }
    .card_number:is(.clr-theme-5) {
      -webkit-text-stroke: 1px var(--clr-theme-8); }
    .card_number:is(.feature_number) {
      width: 100px;
      height: 100px;
      background-color: #fff;
      position: relative;
      font-size: 50px;
      display: inline-block;
      line-height: 100px;
      border-radius: 50%;
      box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
      .card_number:is(.feature_number):is(.theme-1) {
        -webkit-text-stroke: 1px var(--clr-theme-1); }
      .card_number:is(.feature_number):is(.theme-2) {
        -webkit-text-stroke: 1px var(--clr-theme-2); }
      .card_number:is(.feature_number):is(.theme-3) {
        -webkit-text-stroke: 1px var(--clr-theme-3); }
  .choose_single_card .choose_card_content p {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0; }
  .choose_single_card:hover :is(.choose_abs) i {
    color: var(--clr-common-white);
    animation: icon-bounce 0.8s infinite; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
15. Feature Event
*************************
*/
.mb-12 {
  margin-bottom: 12px; }

.mb-17 {
  margin-bottom: 18px; }

.event_border {
  border-bottom: 1px solid var(--clr-soft-grey); }

.f_event_area:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: var(--clr-common-white);
  opacity: 0.9; }

.f_event_area > * {
  position: relative;
  z-index: 2; }

.fbooking {
  width: 243px;
  padding-left: 60px;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fbooking {
      padding-left: 30px;
      width: 230px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .fbooking {
      padding-left: 15px;
      width: 170px; } }

.fdate {
  width: 140px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .fdate {
      width: 110px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .fdate {
      width: 70px; } }

.fauthor {
  flex: .7 0 0%;
  padding-left: 60px;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fauthor {
      padding-left: 30px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .fauthor {
      padding-left: 15px;
      flex: 1 0 0%; } }

.fcontent {
  flex: 1 0 0%;
  padding-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fcontent {
      padding-left: 30px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .fcontent {
      padding-left: 15px; } }
  .fcontent .sub_title {
    font-size: 18px;
    text-decoration: none; }
    .fcontent .sub_title:is(.theme-1) {
      color: var(--clr-theme-1); }
    .fcontent .sub_title:is(.theme-2) {
      color: var(--clr-theme-2); }
    .fcontent .sub_title:is(.theme-3) {
      color: var(--clr-theme-3); }
  .fcontent .title {
    font-size: 28px;
    transition: 0.3s; }
    .fcontent .title:is(.theme-1):hover {
      color: var(--clr-theme-1); }
    .fcontent .title:is(.theme-2):hover {
      color: var(--clr-theme-2); }
    .fcontent .title:is(.theme-3):hover {
      color: var(--clr-theme-3); }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .fcontent .title {
        font-size: 23px; } }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
      .fcontent .title {
        font-size: 16px; } }
    .fcontent .title:hover {
      color: var(--clr-theme-1); }

.fevent_heading {
  font-size: 18px;
  padding: 27px 60px;
  color: var(--clr-common-white); }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fevent_heading {
      padding: 20px 30px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .fevent_heading {
      padding: 20px 25px; } }

.single_fdate {
  width: 140px;
  height: 140px;
  color: var(--clr-common-white);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_fdate {
      width: 110px;
      height: 110px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .single_fdate {
      width: 70px;
      height: 70px; } }
  .single_fdate span {
    text-transform: uppercase;
    font-weight: 700; }
    @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .single_fdate span {
        font-size: 12px; } }
  .single_fdate .date {
    font-size: 50px;
    font-weight: 100;
    line-height: 1;
    color: #fff;
    opacity: 1; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .single_fdate .date {
        font-size: 40px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .single_fdate .date {
        font-size: 25px; } }
  .single_fdate:is(.theme-1) {
    background-color: var(--clr-theme-1); }
  .single_fdate:is(.theme-2) {
    background-color: var(--clr-theme-2); }
  .single_fdate:is(.theme-3) {
    background-color: var(--clr-theme-3); }

.booking_btn.g_btn {
  border-color: #eaeaea; }

.fauthor_address {
  padding-left: 20px;
  font-size: 16px;
  color: var(--clr-body-heading-color); }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fauthor_address {
      padding-left: 10px;
      font-size: 15px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .fauthor_address {
      padding-left: 10px;
      font-size: 15px; } }
  .fauthor_address a {
    text-decoration: none; }
  .fauthor_address .fauthor_location {
    padding-left: 15px;
    color: var(--clr-body-text-color); }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
      .fauthor_address .fauthor_location {
        padding-left: 0px;
        display: block; } }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
      .fauthor_address .fauthor_location {
        font-size: 13px;
        padding-left: 0px;
        display: block; } }
    .fauthor_address .fauthor_location i {
      color: var(--clr-body-heading-color);
      margin-right: 3px; }

.fdot img {
  max-width: 50px;
  border-radius: 50%; }

.booking_btn {
  border: 2px solid var(--clr-soft-grey);
  color: var(--clr-body-heading-color);
  text-decoration: none;
  height: 56px;
  font-weight: 700;
  line-height: 52px;
  width: 100%;
  text-align: center; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .booking_btn {
      font-size: 14px; } }
  .booking_btn:is(.theme-1) span {
    background-color: var(--clr-theme-1); }
  .booking_btn:is(.theme-1):hover {
    border-color: var(--clr-theme-1);
    color: var(--clr-common-white); }
  .booking_btn:is(.theme-2) span {
    background-color: var(--clr-theme-2); }
  .booking_btn:is(.theme-2):hover {
    border-color: var(--clr-theme-2);
    color: var(--clr-common-white); }
  .booking_btn:is(.theme-3) span {
    background-color: var(--clr-theme-3); }
  .booking_btn:is(.theme-3):hover {
    border-color: var(--clr-theme-3);
    color: var(--clr-common-white); }

.single_fevent {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  align-items: center; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px), (max-width: 767px) {
    .single_fevent {
      padding-top: 30px;
      padding-bottom: 30px; } }

.single_fevent_wrapper {
  background-color: var(--clr-common-white);
  padding: 20px 60px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single_fevent_wrapper {
      padding: 0px 30px; } }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .single_fevent_wrapper {
      padding: 0px 25px; } }

.fevent_table {
  overflow-x: auto; }
  @media (max-width: 767px) {
    .fevent_table .fevent_wrapper {
      min-width: 770px; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .fevent_heading_wrapper {
    font-size: 16px; } }

/*  Single Event page CSS  */
.details_meta_abs {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
  @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .details_meta_abs {
      box-shadow: none;
      position: static; } }

.details_img_meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0 40px;
  padding: 33px 40px;
  align-items: center; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .details_img_meta {
      margin: 0px;
      padding: 10px 30px;
      padding-top: 40px;
      border-right: 2px solid #eee;
      border-left: 2px solid #eee; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .details_img_meta {
      margin: 0 30px; } }
  @media (max-width: 767px) {
    .details_img_meta {
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 25px;
      margin: 0;
      border-right: 2px solid #eee;
      border-left: 2px solid #eee; } }

.donar_post_event_details {
  flex: 0 0 auto;
  margin-right: 25px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .donar_post_event_details {
      margin-right: 0; } }

.donar_category {
  flex: 0 0 auto;
  margin-right: 25px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .donar_category {
      margin-right: 0; } }

.meta_speaker {
  display: flex;
  flex: 0 0 auto;
  margin-right: 40px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .meta_speaker {
      margin-right: 0; } }

.details_meta_button {
  flex: 1 0 0%; }

.details_meta_button .g_btn {
  width: 100%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .details_meta_button .g_btn {
      width: auto; } }

.donar_time {
  font-size: 14px;
  text-decoration: none; }
  .donar_time:is(.theme-8) i {
    color: var(--clr-theme-8); }
  .donar_time:is(.theme-8):hover {
    color: var(--clr-theme-8); }

.speaker_img {
  margin-right: 12px; }

.speaker_info .donar_name {
  line-height: 1;
  margin-bottom: 0; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
16. Newsfeed
*************************
*/
.gutter_85 {
  --bs-gutter-x: 85px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .gutter_85 {
      --bs-gutter-x: 30px; } }

.nfeed_title .nsub_title {
  color: var(--clr-theme-1);
  font-size: 16px;
  margin-bottom: 10px;
  display: block; }
  .nfeed_title .nsub_title:is(.nsub_title_2) {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: var(--clr-body-text-color); }

.nfeed_title .n_title {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 24px; }
  .nfeed_title .n_title a {
    text-decoration: none;
    color: var(--clr-body-heading-color); }
    .nfeed_title .n_title a:hover {
      color: var(--clr-theme-1); }
  .nfeed_title .n_title:is(.n_title_single) {
    font-size: 30px; }
    @media (max-width: 767px) {
      .nfeed_title .n_title:is(.n_title_single) {
        font-size: 20px; } }

.nfeed_title:is(.nfeed_title_single) .sub_title_2 {
  line-height: 2; }

.single_button .g_btn {
  padding: 0 50px; }

.single_nfeed {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-color: #eeeeee;
  border-width: 2px;
  border-radius: 20px; }
  .single_nfeed:is(.single_nfeed_2):is(.has_before) {
    position: relative; }
    .single_nfeed:is(.single_nfeed_2):is(.has_before):before {
      position: absolute;
      right: -42px;
      top: 50%;
      height: 350px;
      width: 1px;
      background-color: #ececec;
      content: "";
      transform: translateY(-50%); }
      @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
        .single_nfeed:is(.single_nfeed_2):is(.has_before):before {
          display: none; } }
  .single_nfeed .nfeed_content {
    padding: 28px;
    padding-top: 24px;
    border: 2px solid #eee;
    border-top: 0;
    border-radius: 0 0 20px 20px; }
    @media (max-width: 767px) {
      .single_nfeed .nfeed_content {
        padding: 25px;
        padding-top: 21px; } }
    .single_nfeed .nfeed_content:is(.nfeed_content_2) {
      padding: 36px; }
      @media (max-width: 767px) {
        .single_nfeed .nfeed_content:is(.nfeed_content_2) {
          padding: 25px;
          padding-top: 21px; } }
    @media (max-width: 767px) {
      .nfeed_meta {
        font-size: 15px;
        margin-bottom: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .nfeed_meta {
        font-size: 15px;
        margin-bottom: 0px; } }
    .nfeed_meta:last-child {
      margin-bottom: 0; }
    .nfeed_meta:is(.nfeed_meta_3) {
      color: #8a9cba; }
    .nfeed_meta .nmeta_icon {
      margin-right: 5px;
      transition: 0.3s;
      text-decoration: none; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .nfeed_meta .nmeta_icon {
          font-size: 14px;
          margin-right: 3px; } }
      @media (max-width: 767px) {
        .nfeed_meta .nmeta_icon {
          font-size: 15px; } }
      .nfeed_meta .nmeta_icon:is(.theme-1) {
        color: var(--clr-theme-1); }
      .nfeed_meta .nmeta_icon:is(.theme-2) {
        color: var(--clr-theme-2); }
    .nfeed_meta .nmeta_text {
      text-decoration: none;
      transition: 0.3s; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .nfeed_meta .nmeta_text {
          font-size: 14px; } }
      @media (max-width: 767px) {
        .nfeed_meta .nmeta_text {
          font-size: 15px; } }
      .nfeed_meta .nmeta_text:is(.theme-1):hover {
        color: var(--clr-theme-1); }
      .nfeed_meta .nmeta_text:is(.theme-2):hover {
        color: var(--clr-theme-2); }
      .nfeed_meta .nmeta_text .nmeta_by {
        color: #d0d0d0;
        margin-right: 5px; }
        @media (max-width: 767px) {
          .nfeed_meta .nmeta_text .nmeta_by {
            font-size: 15px; } }

.newsletter_feed {
  padding: 50px 38px;
  border: 2px solid #eee;
  border-radius: 20px;
  padding-top: 44px; }
  @media (max-width: 767px) {
    .newsletter_feed {
      padding: 40px 25px;
      padding-top: 34px; } }

.newsletter_cta_wrapper {
  display: flex;
  align-items: center;
  padding: 50px;
  border-style: solid;
  border-width: 2px;
  border-color: #eeeeee;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: -85px; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .newsletter_cta_wrapper {
      flex-wrap: wrap;
      padding: 50px 40px;
      padding-top: 40px; } }
  @media (max-width: 767px) {
    .newsletter_cta_wrapper {
      flex-wrap: wrap;
      padding: 40px 30px;
      padding-top: 30px; } }

.nfeed_title_single {
  flex: 0 0 346px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .nfeed_title_single {
      flex: 0 0 100%;
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .nfeed_title_single {
      flex: 0 0 auto; } }

.newsletter_content input {
  width: 100%;
  height: 60px;
  padding: 0 30px;
  background-color: #f6f6f6;
  color: var(--clr-body-heading-color);
  font-size: 14px;
  border: 0; }
  .newsletter_content input::-webkit-input-placeholder {
    color: #9c9c9c; }
  .newsletter_content input:-moz-placeholder {
    color: #9c9c9c; }
  .newsletter_content input::-moz-placeholder {
    color: #9c9c9c; }
  .newsletter_content input:-ms-input-placeholder {
    color: #9c9c9c; }

.newsletter_content button {
  width: 100%;
  height: 60px;
  padding: 0 15px;
  text-align: center;
  background-color: var(--clr-theme-2);
  color: var(--clr-common-white); }

.newsletter_content:is(.newsletter_content_single) {
  display: flex; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 1401px) and (max-width: 1600px) {
    .newsletter_content:is(.newsletter_content_single) {
      flex-wrap: nowrap; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .newsletter_content:is(.newsletter_content_single) {
      flex-wrap: wrap; } }
  @media (max-width: 767px) {
    .newsletter_content:is(.newsletter_content_single) {
      flex-wrap: wrap; } }
  .newsletter_content:is(.newsletter_content_single) input {
    flex: 0 0 285px;
    margin-left: 20px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .newsletter_content:is(.newsletter_content_single) input {
        margin-left: 0;
        margin-right: 20px;
        flex: 0 0 calc(50% - 24px); } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .newsletter_content:is(.newsletter_content_single) input {
        margin-left: 0;
        margin-right: 20px;
        flex: 0 0 calc(50% - 24px); } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .newsletter_content:is(.newsletter_content_single) input {
        margin-left: 0;
        margin-right: 20px;
        margin-bottom: 20px;
        flex: 0 0 calc(50% - 10px); } }
    @media (max-width: 767px) {
      .newsletter_content:is(.newsletter_content_single) input {
        margin-left: 0;
        margin-right: 0px;
        flex: 0 0 100%;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .newsletter_content:is(.newsletter_content_single) input[type="email"] {
        margin-right: 0; } }
  .newsletter_content:is(.newsletter_content_single) button {
    flex: 0 0 240px;
    margin-left: 20px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
      .newsletter_content:is(.newsletter_content_single) button {
        flex: 0 0 235px;
        margin-left: 0; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .newsletter_content:is(.newsletter_content_single) button {
        flex: 0 0 auto;
        margin-left: 0; } }
    @media (max-width: 767px) {
      .newsletter_content:is(.newsletter_content_single) button {
        flex: 0 0 auto;
        margin-left: 0; } }

.newsletter_content:is(.newsletter_widget) input:is(.has_border) {
  height: 60px;
  margin-bottom: 16px;
  border: 2px solid #eee;
  background-color: #fff;
  color: #b8b8b8;
  font-size: 14px;
  border-radius: 30px; }
  .newsletter_content:is(.newsletter_widget) input:is(.has_border)::-webkit-input-placeholder {
    color: #b8b8b8; }
  .newsletter_content:is(.newsletter_widget) input:is(.has_border):-moz-placeholder {
    color: #b8b8b8; }
  .newsletter_content:is(.newsletter_widget) input:is(.has_border)::-moz-placeholder {
    color: #b8b8b8; }
  .newsletter_content:is(.newsletter_widget) input:is(.has_border):-ms-input-placeholder {
    color: #b8b8b8; }

.newsletter_content:is(.newsletter_widget) select:is(.has_border) {
  height: 60px;
  width: 100%;
  border-radius: 30px;
  border: 2px solid #eee;
  margin-bottom: 16px;
  padding: 0 30px;
  color: #b8b8b8;
  appearance: none; }

.newsletter_content:is(.newsletter_widget) select:focus {
  outline: 0; }

/* News feed style 3 */
.nfeed_content_wrapper_3 {
  border: 2px solid #f2f2f2;
  border-top: 0;
  padding: 38px;
  padding-top: 32px;
  border-radius: 0 0 10px 10px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .nfeed_content_wrapper_3 {
      padding: 30px;
      padding-top: 24px; } }

.nfeed_by {
  color: #c4c4c4;
  margin-right: 5px; }

.nfeed_content_3 p {
  margin-bottom: 0;
  font-size: 16px; }

.nfeed_admin:is(.sep) {
  position: relative;
  margin-right: 15px;
  padding-right: 20px;
  z-index: 1; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .nfeed_admin:is(.sep) {
      padding-right: 15px;
      margin-right: 10px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .nfeed_admin:is(.sep) {
      padding-right: 10px;
      margin-right: 5px; } }
  @media (max-width: 767px) {
    .nfeed_admin:is(.sep) {
      padding-right: 0px;
      margin-right: 0px; } }
  .nfeed_admin:is(.sep):before {
    position: absolute;
    right: 0;
    top: 50%;
    width: 2px;
    height: 15px;
    content: "";
    background-color: #f1f1f1;
    margin-top: -7.5px; }
    @media (max-width: 767px) {
      .nfeed_admin:is(.sep):before {
        display: none; } }

.nfeed_admin:hover {
  color: var(--clr-theme-1); }

.nfeed_cattext {
  color: var(--clr-theme-1); }

.nfeed_cat {
  margin-bottom: 8px;
  margin-right: 90px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .nfeed_cat {
      margin-right: 80px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .nfeed_cat {
      margin-right: 85px; } }
  .nfeed_cat a {
    text-decoration: none;
    font-size: 16px; }
    @media (max-width: 767px) {
      .nfeed_cat a {
        display: block; } }

.nfeed_title {
  font-size: 24px;
  color: #142645;
  line-height: 1.3; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .nfeed_title {
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .nfeed_title {
      font-size: 20px;
      margin-bottom: 30px; } }
  .nfeed_title a {
    text-decoration: none; }
  .nfeed_title:hover a {
    color: var(--clr-theme-1); }

/*  Blog Page CSS   */
.blog_title {
  font-size: 36px;
  line-height: 1.27; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_title {
      font-size: 25px; } }
  @media (max-width: 767px) {
    .blog_title {
      font-size: 27px; } }

.blog_content {
  padding: 50px;
  padding-top: 55px;
  padding-bottom: 43px;
  border: 2px solid #f3f3f3;
  border-top: 0; }
  @media (max-width: 767px) {
    .blog_content {
      padding: 40px 25px;
      padding-top: 45px;
      padding-bottom: 33px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_content {
      padding: 40px 30px;
      padding-top: 45px;
      padding-bottom: 33px; } }
  .blog_content p {
    font-size: 16px; }
  .blog_content:is(.noimg_content) {
    border-top: 2px solid #f3f3f3; }

.blog_image {
  position: relative; }

.admin_meta {
  margin: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .admin_meta {
      margin: 0 30px; } }
  @media (max-width: 767px) {
    .admin_meta {
      margin: 0 25px; } }
  .admin_meta:is(.has_abs) {
    position: absolute;
    transform: translateY(50%);
    left: 0;
    right: 0;
    bottom: 0; }

.blog_cat {
  display: inline-block;
  text-decoration: none;
  background-color: #65c9bb;
  color: #fff;
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700; }
  @media (max-width: 767px) {
    .blog_cat {
      font-size: 12px;
      padding: 0 15px; } }
  .blog_cat:hover {
    color: #fff; }
  .blog_cat:is(.theme-1) {
    background-color: var(--clr-theme-1); }

.blog_admin {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04); }
  .blog_admin a {
    text-decoration: none;
    color: #263b5c;
    font-size: 16px;
    font-weight: 700; }
    @media (max-width: 767px) {
      .blog_admin a {
        font-size: 14px; } }
    .blog_admin a > img {
      margin-right: 12px; }
      @media (max-width: 767px) {
        .blog_admin a > img {
          margin-right: 5px;
          max-width: 30px; } }

.blog_meta:is(.has_border_top) {
  border-top: 1px solid #e7e7e7;
  padding-top: 21px;
  margin-top: 27px; }

.blog_meta a {
  text-decoration: none;
  font-size: 14px; }
  .blog_meta a:is(.sep) {
    position: relative;
    margin-right: 30px;
    padding-right: 30px; }
    @media (max-width: 767px) {
      .blog_meta a:is(.sep) {
        margin-right: 5px;
        padding-right: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog_meta a:is(.sep) {
        margin-right: 10px;
        padding-right: 15px; } }
    .blog_meta a:is(.sep):after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      content: "";
      background-color: #bdbdbd;
      width: 1px;
      height: 12px; }
      @media (max-width: 767px) {
        .blog_meta a:is(.sep):after {
          display: none; } }
      @media only screen and (min-width: 576px) and (max-width: 767px) {
        .blog_meta a:is(.sep):after {
          display: block; } }
  .blog_meta a i {
    margin-right: 8px; }
  .blog_meta a:is(.eye) i {
    color: #9e4b47; }
  .blog_meta a:is(.eye):hover {
    color: #9e4b47; }
  .blog_meta a:is(.comments) i {
    color: #ffae00; }
  .blog_meta a:is(.comments):hover {
    color: #ffae00; }
  .blog_meta a:is(.calendar) i {
    color: #f15b43; }
  .blog_meta a:is(.calendar):hover {
    color: #f15b43; }

.video_play {
  height: 80px;
  width: 80px;
  background-color: #fff;
  display: inline-block;
  line-height: 80px;
  text-align: center;
  border-radius: 50%; }
  @media (max-width: 767px) {
    .video_play {
      height: 40px;
      width: 40px;
      line-height: 40px; } }
  .video_play:is(.has_abs) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .video_play i {
    font-weight: 700;
    margin-left: 2px;
    color: #f15b43;
    font-size: 14px; }
    @media (max-width: 767px) {
      .video_play i {
        font-size: 11px; } }
  .video_play:hover {
    background-color: var(--clr-theme-1); }
    .video_play:hover i {
      color: var(--clr-common-white); }

.blog_audio_wrapper .blog_content, .blog_noimg_wrapper .blog_content {
  padding-top: 33px; }

.blog_audio_img {
  position: relative; }
  .blog_audio_img:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #000;
    opacity: 0.7; }

.audio_logo_abs {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; }
  @media (max-width: 767px) {
    .audio_logo_abs img {
      max-width: 40px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .audio_logo_abs img {
      max-width: 70px; } }

.blog_blockquote_content {
  padding: 68px 50px;
  position: relative;
  padding-bottom: 72px;
  z-index: 1; }
  @media (max-width: 767px) {
    .blog_blockquote_content {
      padding: 30px; } }
  .blog_blockquote_content:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-color: var(--clr-theme-1);
    opacity: 0.9;
    z-index: -1; }
  .blog_blockquote_content i {
    font-size: 90px;
    color: #fff;
    opacity: 0.3;
    top: 50%;
    left: 50px;
    position: absolute;
    transform: translateY(-50%);
    display: inline-block; }
    @media (max-width: 767px) {
      .blog_blockquote_content i {
        font-size: 30px;
        top: 35px;
        left: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .blog_blockquote_content i {
        font-size: 35px; } }
  .blog_blockquote_content .blog_title {
    color: #fff;
    padding-left: 120px;
    padding-right: 20px;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .blog_blockquote_content .blog_title {
        padding-left: 30px;
        padding-right: 0; } }

.page_pagination a {
  display: inline-block;
  width: 50px;
  height: 50px;
  background-color: #f6f6f6;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  text-decoration: none;
  margin: 0 5px;
  margin-bottom: 10px; }
  @media (max-width: 767px) {
    .page_pagination a {
      width: 40px;
      height: 40px;
      line-height: 40px; } }
  .page_pagination a:is(.current) {
    background-color: var(--clr-theme-1);
    color: #fff; }
  .page_pagination a:hover {
    background-color: var(--clr-theme-1);
    color: #fff; }

.sidebar_widget {
  padding: 40px;
  padding-top: 38px; }
  @media (max-width: 767px) {
    .sidebar_widget {
      padding: 25px;
      padding-top: 23px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .sidebar_widget {
      padding: 30px;
      padding-top: 28px; } }
  .sidebar_widget:is(.has_border) {
    border: 2px solid #f3f3f3; }

/*  News details css */
.blog_details_wrapper {
  padding: 50px; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .blog_details_wrapper {
      padding: 30px; } }
  @media (max-width: 767px) {
    .blog_details_wrapper {
      padding: 25px; } }
  .blog_details_wrapper:is(.has_border) {
    border: 2px solid #f3f3f3; }

.blog_details_content p {
  font-size: 16px; }

.blog_details_wrapper h5, .blog_details_wrapper .h5 {
  font-size: 30px; }
  .blog_details_wrapper h5:is(.mb-23), .blog_details_wrapper .h5:is(.mb-23) {
    margin-bottom: 23px; }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
  .blog_details_thumb:is(.d-flex) {
    flex-wrap: wrap;
    padding-bottom: 0; } }

.blog_details_thumb:is(.d-flex) .details_thumb {
  flex: 0 0 auto;
  padding-right: 31px; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .blog_details_thumb:is(.d-flex) .details_thumb {
      padding-right: 0px;
      margin-bottom: 20px; } }

@media (max-width: 767px) {
  .blog_details_img {
    margin-bottom: 20px; } }

.details_list {
  padding-left: 0; }
  .details_list li {
    list-style: none;
    padding-left: 29px;
    position: relative; }
    .details_list li::after {
      position: absolute;
      left: 0;
      font-family: 'Font awesome 5 pro';
      top: 50%;
      transform: translateY(-50%);
      content: "\f00c";
      font-size: 16px;
      color: var(--clr-theme-1); }
    .details_list li:not(:last-child) {
      margin-bottom: 6px; }

.details_blockquote {
  background: #f6f6f6;
  padding: 56px 100px;
  position: relative;
  z-index: 1;
  border-radius: 16px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .details_blockquote {
      padding: 50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .details_blockquote {
      padding: 30px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .details_blockquote {
      padding: 50px; } }
  @media (max-width: 767px) {
    .details_blockquote {
      padding: 25px; } }
  .details_blockquote blockquote {
    margin-bottom: 0; }
  .details_blockquote span {
    color: var(--clr-theme-1);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
    display: block; }
    @media (max-width: 767px) {
      .details_blockquote span {
        margin-bottom: 10px; } }
  .details_blockquote h3, .details_blockquote .h3 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    color: var(--clr-body-heading-color);
    line-height: 1.35; }
    @media (max-width: 767px) {
      .details_blockquote h3, .details_blockquote .h3 {
        font-size: 16px; } }
  .details_blockquote > i {
    position: absolute;
    right: 68px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 160px;
    color: #fff;
    z-index: -1; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .details_blockquote > i {
        font-size: 100px;
        top: 90px;
        right: auto;
        left: 35px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .details_blockquote > i {
        font-size: 100px;
        top: 70px;
        right: auto;
        left: 20px; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .details_blockquote > i {
        font-size: 120px;
        top: 100px;
        right: auto;
        left: 50px; } }
    @media (max-width: 767px) {
      .details_blockquote > i {
        font-size: 40px;
        top: 60px;
        right: auto;
        left: 10px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .details_blockquote > i {
        right: auto;
        left: 30px; } }

h5.details_title, .details_title.h5 {
  font-size: 20px; }
  @media (max-width: 767px) {
    h5.details_title, .details_title.h5 {
      margin-bottom: 15px; } }

@media (max-width: 767px) {
  .details_tag {
    margin-bottom: 30px; } }

@media (max-width: 767px) {
  .details_social {
    text-align: start; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .details_social {
    text-align: end; } }

@media (max-width: 767px) {
  .details_social .details_title {
    text-align: start; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .details_social .details_title {
    text-align: end; } }

.details_social a.facebook {
  margin-left: 0; }

.social_share {
  text-align: end; }
  @media (max-width: 767px) {
    .social_share {
      text-align: start; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .social_share {
      text-align: end; } }
  .social_share a {
    margin-left: 26px;
    color: #d3d3d3; }
    @media (max-width: 767px) {
      .social_share a {
        margin-left: 15px; } }
    .social_share a i {
      font-size: 18px;
      line-height: 1; }
    .social_share a:is(.facebook):hover {
      color: #385398; }
    .social_share a:is(.twitter):hover {
      color: #55acee; }
    .social_share a:is(.behance):hover {
      color: #0057FF; }
    .social_share a:is(.youtube):hover {
      color: #C3271A; }
    .social_share a:is(.linkedin):hover {
      color: #0A66C2; }
    .social_share a:is(.instagram):hover {
      color: #CD2D86; }
    .social_share a:is(.viber):hover {
      color: #793BAA; }

.single_post_nav {
  text-decoration: none; }
  @media (max-width: 767px) {
    .single_post_nav {
      display: block; } }
  .single_post_nav span {
    font-size: 14px;
    color: #777777;
    display: block;
    padding-bottom: 8px; }
  .single_post_nav h4, .single_post_nav .h4 {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 28px; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
      .single_post_nav h4, .single_post_nav .h4 {
        font-size: 22px; } }
    @media (max-width: 767px) {
      .single_post_nav h4, .single_post_nav .h4 {
        font-size: 19px;
        text-align: start; } }
  @media (max-width: 767px) {
    .single_post_nav:is(.right_nav) {
      text-align: start;
      margin-top: 30px; }
      .single_post_nav:is(.right_nav) span {
        text-align: start; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_post_nav:is(.right_nav) {
      text-align: end;
      margin-top: 0px; }
      .single_post_nav:is(.right_nav) span {
        text-align: end; }
      .single_post_nav:is(.right_nav) h4, .single_post_nav:is(.right_nav) .h4 {
        text-align: end; } }

.details_postnav {
  position: relative; }
  .details_postnav :is(.post-dot-shape) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 767px) {
      .details_postnav :is(.post-dot-shape) {
        display: none; } }

.related_post_title {
  font-size: 26px; }
  @media (max-width: 767px) {
    .related_post_title {
      margin-bottom: 25px; } }

.related_title {
  font-size: 22px;
  margin-bottom: 15px; }
  .related_title:is(.theme-1):hover {
    color: var(--clr-theme-1); }

.related_post_single .details_content {
  padding: 35px 40px;
  box-shadow: 0px 8px 16px 0px #5d5d5d; }
  @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .related_post_single .details_content {
      padding: 25px; } }
  .related_post_single .details_content p {
    margin-bottom: 0; }

.author_details_name {
  font-size: 25px; }
  @media (max-width: 767px) {
    .author_details_name {
      font-size: 19px; } }

.details_author_box {
  border: 2px solid #f3f3f3;
  padding: 40px;
  margin-bottom: 45px; }
  @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .details_author_box:is(.d-flex) {
      flex-wrap: wrap;
      padding: 25px; } }
  .details_author_box:is(.d-flex) .author_thumb {
    padding-right: 40px;
    flex: 0 0 auto; }
    @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .details_author_box:is(.d-flex) .author_thumb {
        padding-right: 0;
        margin-bottom: 20px; } }
  .details_author_box:is(.d-flex) .details_content {
    margin-top: 6px; }
    .details_author_box:is(.d-flex) .details_content span {
      color: var(--clr-theme-1);
      font-size: 12px;
      font-weight: 700; }
    .details_author_box:is(.d-flex) .details_content h4, .details_author_box:is(.d-flex) .details_content .h4 {
      font-size: 36px;
      margin-bottom: 15px; }
    .details_author_box:is(.d-flex) .details_content p {
      font-size: 14px; }

.comments_box:hover .avater_text span {
  opacity: 1;
  visibility: visible; }

.comments_box:hover .comment_avater img {
  filter: grayscale(100%); }

.latest_comments > ul > li {
  border-bottom: 1px solid #f3f3f3; }

.latest_comments ul {
  padding-left: 0; }
  .latest_comments ul li {
    list-style-type: none; }
    .comment_avater {
      padding-right: 30px; }
      @media (max-width: 767px) {
        .comment_avater {
          padding-right: 15px;
          max-width: 70px; } }
    .avater_text {
      overflow: hidden;
      padding-left: 2px;
      position: relative;
      margin-bottom: 10px; }
      .avater_text h5, .avater_text .h5 {
        font-size: 18px;
        margin-bottom: 4px;
        display: inline-block;
        position: relative; }
        .avater_text h5 span, .avater_text .h5 span {
          position: absolute;
          right: -35px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
          visibility: hidden;
          -webkit-transition: all 0.3s linear 0s;
          -moz-transition: all 0.3s linear 0s;
          -ms-transition: all 0.3s linear 0s;
          -o-transition: all 0.3s linear 0s;
          transition: all 0.3s linear 0s; }
          .avater_text h5 span i, .avater_text .h5 span i {
            font-weight: 700;
            font-size: 12px;
            color: #d6d6d6; }
      .avater_text :is(.replay-btn) {
        position: absolute;
        right: 0;
        top: 0; }
    .replay_btn {
      font-size: 14px;
      padding: 10px 22px;
      line-height: 1;
      display: inline-block;
      border: 2px solid #f3f3f3;
      border-radius: 30px;
      text-decoration: none;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
      .replay_btn:is(.has_abs) {
        position: absolute;
        right: 0;
        top: 0; }
        @media (max-width: 767px) {
          .replay_btn:is(.has_abs) {
            position: static; } }
      .replay_btn i {
        color: var(--clr-theme-2);
        padding-right: 5px;
        -webkit-transition: all 0.3s linear 0s;
        -moz-transition: all 0.3s linear 0s;
        -ms-transition: all 0.3s linear 0s;
        -o-transition: all 0.3s linear 0s;
        transition: all 0.3s linear 0s; }
      .replay_btn:hover {
        color: #fff;
        border-color: var(--clr-theme-2);
        background-color: var(--clr-theme-2); }
        .replay_btn:hover i {
          color: #fff; }

.comment_text {
  overflow: hidden;
  padding-top: 13px; }
  .comment_text p {
    font-size: 14px; }

.comment_text:is(.has-children) {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 27px; }

.children {
  padding-left: 130px; }
  @media (max-width: 767px) {
    .children {
      padding-left: 0; } }
  .children .comment_text {
    padding-bottom: 27px;
    border: none; }

.comment_form_details {
  background: #f6f6f6;
  border-radius: 30px;
  padding: 50px; }
  @media (max-width: 767px) {
    .comment_form_details {
      background-color: transparent;
      padding: 0; } }

.comment_form_single {
  position: relative;
  margin-bottom: 20px; }
  .comment_form_single input {
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-color: #e4e4e4;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
    padding: 0 30px; }
    .comment_form_single input::-webkit-input-placeholder {
      font-size: 14px;
      color: #999999;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .comment_form_single input:-moz-placeholder {
      font-size: 14px;
      color: #999999;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .comment_form_single input::-moz-placeholder {
      font-size: 14px;
      color: #999999;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .comment_form_single input:-ms-input-placeholder {
      font-size: 14px;
      color: #999999;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s; }
    .comment_form_single input:focus::-webkit-input-placeholder {
      opacity: 0;
      visibility: hidden; }
    .comment_form_single input:focus:-moz-placeholder {
      opacity: 0;
      visibility: hidden; }
    .comment_form_single input:focus::-moz-placeholder {
      opacity: 0;
      visibility: hidden; }
    .comment_form_single input:focus:-ms-input-placeholder {
      opacity: 0;
      visibility: hidden; }
  .comment_form_single textarea::-webkit-input-placeholder {
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: #999;
    font-size: 14px; }
  .comment_form_single textarea:-moz-placeholder {
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: #999;
    font-size: 14px; }
  .comment_form_single textarea::-moz-placeholder {
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: #999;
    font-size: 14px; }
  .comment_form_single textarea:-ms-input-placeholder {
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
    color: #999;
    font-size: 14px; }
  .comment_form_single textarea:focus::-webkit-input-placeholder {
    opacity: 0;
    visibility: hidden; }
  .comment_form_single textarea:focus:-moz-placeholder {
    opacity: 0;
    visibility: hidden; }
  .comment_form_single textarea:focus::-moz-placeholder {
    opacity: 0;
    visibility: hidden; }
  .comment_form_single textarea:focus:-ms-input-placeholder {
    opacity: 0;
    visibility: hidden; }
  .comment_form_single label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    font-size: 14px;
    color: var(--clr-theme-1); }

.comment_textarea {
  position: relative;
  height: 150px; }
  .comment_textarea textarea {
    border: 0;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-width: 2px;
    border-color: #e4e4e4;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
    font-size: 14px;
    padding: 24px 30px;
    color: #999999; }
  .comment_textarea label {
    font-size: 14px;
    color: var(--clr-theme-1);
    position: absolute;
    right: 30px;
    top: 35px; }

.comment_details_btn {
  height: 60px;
  border: 0;
  padding: 0 42px;
  border-radius: 30px;
  color: #fff;
  background: #5fb35b;
  font-size: 14px; }
  .comment_details_btn i {
    padding-right: 4px; }

.inner_details_title {
  font-size: 30px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .inner_details_title {
      font-size: 25px; } }
  @media (max-width: 767px) {
    .inner_details_title {
      font-size: 25px; } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
17. Iconbox
*************************
*/
.single_iconbox {
  display: block;
  text-decoration: none; }
  .single_iconbox i {
    width: 170px;
    height: 170px;
    display: inline-block;
    line-height: 190px;
    text-align: center;
    background-color: var(--clr-common-white);
    color: var(--clr-body-heading-color);
    margin-bottom: 23px;
    border-radius: 50%;
    font-size: 90px;
    transition: 0.5s;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
    @media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
      .single_iconbox i {
        width: 120px;
        height: 120px;
        line-height: 130px;
        font-size: 60px; } }
  .single_iconbox:is(.theme-1) i {
    color: var(--clr-theme-1); }
  .single_iconbox:is(.theme-1):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-1); }
  .single_iconbox:is(.theme-1):hover .icon_title {
    color: var(--clr-theme-1); }
  .single_iconbox:is(.theme-2) i {
    color: var(--clr-theme-2); }
  .single_iconbox:is(.theme-2):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-2); }
  .single_iconbox:is(.theme-2):hover .icon_title {
    color: var(--clr-theme-2); }
  .single_iconbox:is(.theme-3) i {
    color: var(--clr-theme-3); }
  .single_iconbox:is(.theme-3):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-3); }
  .single_iconbox:is(.theme-3):hover .icon_title {
    color: var(--clr-theme-3); }
  .single_iconbox:is(.theme-4) i {
    color: var(--clr-theme-4); }
  .single_iconbox:is(.theme-4):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-4); }
  .single_iconbox:is(.theme-4):hover .icon_title {
    color: var(--clr-theme-4); }
  .single_iconbox:is(.theme-5) i {
    color: var(--clr-theme-5); }
  .single_iconbox:is(.theme-5):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-5); }
  .single_iconbox:is(.theme-5):hover .icon_title {
    color: var(--clr-theme-5); }
  .single_iconbox:is(.theme-6) i {
    color: var(--clr-theme-6); }
  .single_iconbox:is(.theme-6):hover i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-6); }
  .single_iconbox:is(.theme-6):hover .icon_title {
    color: var(--clr-theme-6); }
  .single_iconbox:hover i {
    animation: icon-bounce 0.8s 1; }

.icon_title {
  font-size: 20px;
  transition: 0.3s; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .icon_title {
      font-size: 16px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .icon_title {
      font-size: 14px; } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
18. Eventcount
*************************
*/
.eventcount_area {
  margin-top: -82px; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px) {
    .eventcount_area {
      margin-top: -84.5px; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .eventcount_area {
      margin-top: -67.5px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .eventcount_area {
      margin-top: -56px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .eventcount_area {
      margin-top: -63px; } }

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .eventcount_wrapper {
    padding: 25px; } }

.eventcount_img {
  overflow: hidden; }
  .eventcount_img a img {
    width: 100%; }
  .eventcount_img:hover img {
    transform: scale(1.1); }

@media (max-width: 767px) {
  .eventcount_text {
    margin-bottom: 30px; } }

.eventcount_text h5, .eventcount_text .h5 {
  font-size: 24px;
  color: var(--clr-common-white);
  color: #fff;
  margin-bottom: 5px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .eventcount_text h5, .eventcount_text .h5 {
      font-size: 24px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .eventcount_text h5, .eventcount_text .h5 {
      font-size: 20px; } }

.eventcount_text p {
  color: var(--clr-common-white);
  color: #fff; }

.eventcount_text span {
  color: #777; }
  .eventcount_text span:is(.white_txt) {
    color: #fff;
    opacity: 0.6; }

.eventcount_text:is(.heading-clr) h5, .eventcount_text:is(.heading-clr) .h5 {
  color: var(--clr-body-heading-color); }
  .eventcount_text:is(.heading-clr) h5:hover, .eventcount_text:is(.heading-clr) .h5:hover {
    color: var(--clr-theme-2); }

.eventcount_text:is(.heading-clr) span {
  color: var(--clr-body-text-color); }

.eventcount_text:is(.heading-clr) p {
  color: var(--clr-body-text-color); }

.count_down_box {
  padding-right: 25px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .count_down_box {
      padding-right: 0px; } }
  .count_down_box:is(.heading-clr) .count_down {
    color: var(--clr-body-heading-color); }
    .count_down_box:is(.heading-clr) .count_down span {
      color: var(--clr-body-text-color); }
    .count_down_box:is(.heading-clr) .count_down:before {
      background-color: #e9ebee; }
    @media (max-width: 767px) {
      .count_down_box:is(.heading-clr) .count_down {
        margin-bottom: 0; } }

.count_down {
  color: #fff;
  font-size: 45px;
  font-weight: 700;
  display: inline-block;
  width: 135px;
  text-align: center;
  position: relative;
  line-height: 1; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .count_down {
      font-size: 35px;
      width: 100px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .count_down {
      font-size: 35px;
      width: 100px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .count_down {
      font-size: 35px;
      width: 87px; } }
  @media (max-width: 767px) {
    .count_down {
      font-size: 20px;
      width: 65px;
      margin-bottom: 30px; }
      .count_down:first-child:before {
        display: none; }
      .count_down:last-child {
        margin-bottom: 0; } }
  .count_down:before {
    position: absolute;
    left: 0;
    top: 50%;
    width: 1px;
    height: 55px;
    content: "";
    background-color: #3c8df7;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      .count_down:before {
        height: 30px; } }
  .count_down span {
    font-size: 16px;
    display: block;
    font-weight: 400;
    color: #777; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
      .count_down span {
        font-size: 18px; } }
    @media (max-width: 767px) {
      .count_down span {
        font-size: 12px; } }

.tab-border {
  border: 2px solid #f2f2f2; }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .tab-border {
      padding: 30px; } }

@media (max-width: 767px) {
  .eventcount_img {
    margin-bottom: 25px;
    text-align: center; } }

.white_txt :is(.count_down) span {
  color: #fff;
  opacity: 0.6; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
19. Donation
*************************
*/
.s_14 {
  font-size: 14px; }

@media (max-width: 767px) {
  .donation_area {
    padding-top: 190px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .donation_area {
    padding-top: 120px; } }

.donate_progress_wrapper {
  margin-bottom: 45px; }
  .donate_progress_wrapper span:is(.theme-3) {
    color: var(--clr-theme-3); }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .donate_img_wrapper {
    margin-left: 30px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .donate_img_wrapper {
    margin-left: 0px;
    margin-bottom: 50px; } }

@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .donate_img_wrapper:is(.about_img_wrapper) {
    margin-left: 0px; } }

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .donate_img_wrapper:is(.about_img_wrapper) {
    margin-left: 0px;
    margin-bottom: 0; } }

.donate_img_inner {
  display: flex;
  justify-content: space-between; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .donate_img_inner {
      justify-content: flex-start;
      display: block; } }
  .donate_img_inner img {
    border-radius: 10px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .donate_img_inner img:first-child {
        display: none; } }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .donate_img_inner img {
        justify-content: space-around; } }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .donate_img_inner:is(.about_img_inner) img {
      width: 100%; } }
  .donate_img_inner:is(.about_img_inner) img:is(.about_block) {
    display: block; }
  .donate_img_inner .abs_img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 200px; }
    @media (max-width: 767px) {
      .donate_img_inner .abs_img {
        right: 0;
        transform: translateX(-50%);
        top: calc(0% - 100px);
        width: 200px;
        left: 50%; } }
    @media only screen and (min-width: 576px) and (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .donate_img_inner .abs_img {
        right: 0;
        transform: translateY(-50%);
        top: 50%;
        width: 200px;
        left: calc(100% - 150px); } }
    .donate_img_inner .abs_img:is(.event_abs_img) {
      border: 10px solid #fff;
      border-radius: 10px; }

@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about_img_inner img.space_t30 {
    display: none; } }

.donate_title {
  font-size: 60px;
  color: #fff;
  line-height: 1;
  margin-bottom: 12px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .donate_title {
      font-size: 50px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .donate_title {
      font-size: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .donate_title {
      font-size: 40px; } }
  @media (max-width: 767px) {
    .donate_title {
      font-size: 40px; } }

.donation_wrappar {
  margin-top: -3px; }
  .donation_wrappar > span {
    margin-bottom: 25px;
    display: block;
    color: #fff;
    font-size: 16px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .donation_wrappar > span {
        margin-bottom: 15px; } }
  .donation_wrappar span.white {
    color: #fff;
    opacity: 1; }

/* Donation page style CSS */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_donation_img_wrapper {
    padding-right: 15px; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single_donation_img_wrapper {
    padding-right: 0; } }

.single_donation_img {
  overflow: hidden; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .single_donation_img img {
      width: 100%; } }
  .single_donation_img:hover img {
    transform: scale(1.1); }

.donation_border {
  padding-bottom: 25px;
  margin-bottom: 45px;
  border-bottom: 1px solid #dce8eb; }

.donation_submit_box {
  width: 160px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  border-style: solid;
  border-width: 2px;
  border-color: #ebebeb;
  background-color: white;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
  @media (max-width: 767px) {
    .donation_submit_box {
      width: 100%; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .donation_submit_box {
      width: 160px; } }
  .donation_submit_box button[type="submit"] {
    flex: 1;
    border: 2px solid #ebebeb;
    padding: 0 25px;
    border-radius: 30px;
    color: var(--clr-theme-1);
    background-color: transparent;
    margin-left: -2px;
    height: 60px;
    margin-top: -2px;
    font-weight: 700; }
    .donation_submit_box button[type="submit"]:active {
      color: #fff;
      background-color: var(--clr-theme-1);
      border-color: var(--clr-theme-1); }
  .donation_submit_box input[type="text"] {
    border: 0;
    width: 100%;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 0 10px;
    text-align: center;
    font-weight: 700; }
    @media (max-width: 767px) {
      .donation_submit_box input[type="text"] {
        text-align: start; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .donation_submit_box input[type="text"] {
        text-align: center; } }
    .donation_submit_box input[type="text"]::-webkit-input-placeholder {
      color: #142645; }
    .donation_submit_box input[type="text"]:-moz-placeholder {
      color: #142645; }
    .donation_submit_box input[type="text"]::-moz-placeholder {
      color: #142645; }
    .donation_submit_box input[type="text"]:-ms-input-placeholder {
      color: #142645; }
  .donation_submit_box:is(.w_208) {
    width: 208px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .donation_submit_box:is(.w_208) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .donation_submit_box:is(.w_208) {
        width: 208px;
        margin-right: 15px; } }

.input_value {
  padding: 0 35px;
  height: 60px;
  border: 2px solid #ebebeb;
  border-radius: 30px;
  background-color: transparent;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .input_value {
      margin-right: 10px; } }
  .input_value:last-child {
    margin-right: 0; }
  .input_value:hover {
    background-color: var(--clr-theme-1);
    color: var(--clr-common-white);
    border-color: var(--clr-theme-1); }

.single_title {
  font-size: 18px;
  color: #142645;
  margin-bottom: 20px; }

.payment_icons a {
  text-decoration: none;
  display: inline-block;
  margin-right: 20px; }
  @media (max-width: 767px) {
    .payment_icons a {
      margin-right: 15px; } }
  .payment_icons a i {
    font-size: 60px; }
  .payment_icons a:hover {
    color: #f5a807; }

.info_input {
  position: relative;
  display: inline-block; }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .info_input {
      display: inline-block; } }
  @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .info_input {
      display: block; } }
  .info_input input {
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-color: #efefef;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    padding: 0 25px;
    margin-bottom: 20px;
    line-height: 60px; }
    .info_input input::-webkit-input-placeholder {
      color: #999999; }
    .info_input input:-moz-placeholder {
      color: #999999; }
    .info_input input::-moz-placeholder {
      color: #999999; }
    .info_input input:-ms-input-placeholder {
      color: #999999; }
    @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .info_input input {
        width: 100%; } }
  .info_input:is(.input_info_name) {
    margin-right: 15px; }
    @media (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
      .info_input:is(.input_info_name) {
        margin-right: 0; } }
  .info_input i {
    position: absolute;
    left: 30px;
    top: 23px;
    font-size: 14px;
    color: #d0d0d0; }
  .info_input:is(.input_info_name) input {
    padding-left: 50px; }
  .info_input:is(.input_info_email) input {
    padding-left: 50px; }
  .info_input:is(.input_info_email) i {
    top: 24px; }

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .input_info_wrapper .info_input {
    width: calc(50% - 10px); } }

.fs_14 {
  font-size: 14px; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
20. Team
*************************
*/
.member_social a {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: var(--clr-soft-grey);
  border-radius: 10px 10px 0 0;
  text-align: center;
  line-height: 50px;
  color: var(--clr-body-heading-color);
  margin: 0px 3px; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .member_social a {
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin: 0 3px; } }
  .member_social a:is(.facebook):hover {
    background-color: #385398;
    color: var(--clr-common-white); }
  .member_social a:is(.twitter):hover {
    background-color: #55acee;
    color: var(--clr-common-white); }
  .member_social a:is(.behance):hover {
    background-color: #0057FF;
    color: var(--clr-common-white); }
  .member_social a:is(.youtube):hover {
    background-color: #C3271A;
    color: var(--clr-common-white); }
  .member_social a:first-child {
    margin-left: 0; }
  .member_social a:last-child {
    margin-right: 0; }

.single_team {
  background-color: var(--clr-soft-gray-bg);
  padding: 40px 40px 0 40px;
  border-radius: 10px 10px 0 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single_team {
      padding: 25px 25px 0 25px; } }
  @media (max-width: 767px) {
    .single_team {
      padding: 25px 25px 0 25px; } }
  .single_team .designation {
    margin-bottom: 10px;
    display: block;
    color: var(--clr-theme-2);
    font-size: 16px; }
  .single_team .member_name {
    margin-bottom: 25px;
    font-size: 24px; }
    .single_team .member_name:hover {
      color: var(--clr-theme-2); }

.team_thumb {
  margin-bottom: 25px; }

/*  Team single page CSS  */
@media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .team_single_info_wrapper {
    padding-left: 25px; } }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .team_single_info_wrapper {
    padding-left: 0px; } }

.team_person_info a {
  font-size: 18px;
  text-decoration: none;
  display: block;
  margin-bottom: 15px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), (max-width: 767px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .team_person_info a {
      font-size: 16px; } }
  .team_person_info a strong {
    color: var(--clr-body-heading-color);
    transition: 0.3s; }
  .team_person_info a:hover strong {
    color: var(--clr-theme-1); }

.team_info_social a {
  display: inline-block;
  color: #b9b9b9;
  margin-right: 15px; }
  .team_info_social a:is(.facebook):hover {
    color: #385398; }
  .team_info_social a:is(.twitter):hover {
    color: #55acee; }
  .team_info_social a:is(.google):hover {
    color: #F70000; }
  .team_info_social a:is(.pinterest):hover {
    color: #C51F26; }

@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .team_single_img {
    margin-bottom: 30px; } }

.first_letter {
  display: inline-block;
  font-size: 80px;
  background-color: var(--clr-theme-2);
  flex: 0 0 115px;
  text-align: center;
  height: 115px;
  margin-right: 50px;
  color: var(--clr-common-white);
  transform: translateY(8px); }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .first_letter {
      margin-bottom: 20px; } }

.team_person_description:is(.d_flex) {
  display: flex; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .team_person_description:is(.d_flex) {
      flex-wrap: wrap; } }

.team_person_description p {
  font-size: 18px;
  line-height: 1.77; }
  @media (max-width: 767px) {
    .team_person_description p {
      font-size: 16px; } }

.team_progress_wrapper span {
  font-size: 16px;
  color: var(--clr-body-heading-color);
  font-weight: 700; }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
21. Choose
*************************
*/
.space_r60 {
  padding-right: 60px; }
  @media (max-width: 767px) {
    .space_r60 {
      padding-right: 0; } }

.space_l30 {
  transform: translateX(30px); }
  @media (max-width: 767px) {
    .space_l30 {
      transform: translateX(0px); } }

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .chooseus_wrapper {
    margin-bottom: 40px; } }

.play_btn {
  font-size: 14px;
  color: #fff;
  width: 120px;
  height: 120px;
  display: inline-block;
  background-color: var(--clr-theme-1);
  line-height: 120px;
  text-align: center;
  border-radius: 50%; }
  .play_btn:hover {
    color: var(--clr-common-white);
    animation: icon-bounce 0.8s 1; }
  .play_btn:focus {
    color: var(--clr-common-white); }
  .play_btn:is(.focus_theme-1):focus {
    background-color: var(--clr-theme-1); }

.play_btn, .video_play {
  position: relative; }
  .play_btn:after, .play_btn:before, .video_play:after, .video_play:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    animation-name: popupBtn;
    animation-duration: 1.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite; }
  .play_btn:before, .video_play:before {
    animation-delay: .8s; }
  .play_btn:is(.ab_4), .video_play:is(.ab_4) {
    background-color: var(--clr-common-white);
    color: var(--clr-body-heading-color);
    width: 100px;
    height: 100px;
    line-height: 100px; }
    .play_btn:is(.ab_4):hover, .video_play:is(.ab_4):hover {
      color: var(--clr-common-white);
      background-color: var(--clr-theme-1); }
    .play_btn:is(.ab_4):after, .play_btn:is(.ab_4):before, .video_play:is(.ab_4):after, .video_play:is(.ab_4):before {
      border: 3px solid #fff; }

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0; }
  50% {
    transform: scale(1.6);
    opacity: 0.3; }
  100% {
    transform: scale(2.2);
    opacity: 0; } }

.chooseus_area {
  position: relative;
  z-index: 1; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .chooseus_area {
      padding-bottom: 120px; } }
  .chooseus_area:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(../img/bg/bg_trans.png);
    z-index: -1;
    background-size: cover;
    background-position: left bottom; }

.choose_card_text .card_numb {
  font-size: 60px;
  color: #333;
  font-weight: 700;
  display: block;
  line-height: 1;
  margin-bottom: 10px; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .choose_card_text .card_numb {
      font-size: 45px; } }

.choose_card_text span {
  font-size: 17px;
  color: #999; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .choose_card_text span {
      font-size: 14px; } }

.choose_card {
  position: relative;
  background-color: #fff;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 27px 38px;
  padding-left: 123px;
  transition: 0.4s; }
  @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
    .choose_card {
      padding: 27px 30px;
      padding-left: 118px; } }
  .choose_card i {
    font-size: 60px;
    position: absolute;
    left: 38px;
    top: 50%;
    line-height: 1;
    margin-top: -30px; }
    @media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
      .choose_card i {
        left: 30px; } }
  .choose_card:is(.theme-1) i {
    color: var(--clr-theme-1); }
  .choose_card:is(.theme-1):hover {
    border-color: rgba(241, 91, 67, 0.09);
    box-shadow: 0 13px 19px rgba(241, 91, 67, 0.1); }
  .choose_card:is(.theme-2) i {
    color: var(--clr-theme-2); }
  .choose_card:is(.theme-2):hover {
    border-color: rgba(101, 201, 187, 0.09);
    box-shadow: 0 13px 19px rgba(101, 201, 187, 0.1); }
  .choose_card:is(.theme-3) i {
    color: var(--clr-theme-3); }
  .choose_card:is(.theme-3):hover {
    border-color: rgba(255, 154, 57, 0.09);
    box-shadow: 0 13px 19px rgba(255, 154, 57, 0.1); }
  .choose_card:is(.theme-6) i {
    color: var(--clr-theme-6); }
  .choose_card:is(.theme-6):hover {
    border-color: rgba(99, 181, 95, 0.09);
    box-shadow: 0 13px 19px rgba(99, 181, 95, 0.1); }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
22. Circular Progress
*************************
*/
.progress_wrapper {
  width: 100px;
  text-align: center;
  color: #fff;
  padding: 15px;
  border-radius: 50px 50px 0 0;
  display: inline-block;
  position: absolute;
  left: 40px;
  bottom: 0; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .progress_wrapper {
      left: 28px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .progress_wrapper {
      left: 33px; } }
  @media (max-width: 767px) {
    .progress_wrapper {
      left: 27px; } }
  .progress_wrapper:is(.pbar_1) {
    background-color: #5fb35b; }
    .progress_wrapper:is(.pbar_1) .progress_circle:after {
      border-color: #6fbf6b; }
  .progress_wrapper:is(.pbar_2) {
    background-color: #f15b43; }
    .progress_wrapper:is(.pbar_2) .progress_circle:after {
      border-color: #f76c55; }
  .progress_wrapper:is(.pbar_3) {
    background-color: #65c9bb; }
    .progress_wrapper:is(.pbar_3) .progress_circle:after {
      border-color: #83d7cb; }

.progress_circle {
  width: 70px;
  height: 70px;
  line-height: 70px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  margin-bottom: 10px; }
  .progress_circle:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(0.93); }
  .progress_circle > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1; }
  .progress_circle .progress-left {
    left: 1px; }
  .progress_circle .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 6px;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: #fff; }
  .progress_circle .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left; }
  .progress_circle .progress-right {
    right: 0; }
    .progress_circle .progress-right .progress-bar {
      left: -100%;
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right; }
  .progress_circle .progress-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300; }
    .progress_circle .progress-value span {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 700; }

.mission_progress_wrapper {
  display: flex; }
  @media (max-width: 767px) {
    .mission_progress_wrapper {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }
  .mission_progress_wrapper .mission_progress_single {
    flex: 0 0 33.33%; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .mission_progress_wrapper .mission_progress_single {
        flex: 0 0 20%; } }
    @media (max-width: 767px) {
      .mission_progress_wrapper .mission_progress_single {
        margin-bottom: 40px; } }
    @media (max-width: 767px) {
      .mission_progress_wrapper .mission_progress_single:last-child {
        margin-bottom: 0; } }
    .mission_progress_wrapper .mission_progress_single .progress_circle {
      width: 150px;
      height: 150px;
      line-height: 150px;
      margin: 0; }
      .mission_progress_wrapper .mission_progress_single .progress_circle .progress-left .progress-bar {
        border-top-right-radius: 75px;
        border-bottom-right-radius: 75px;
        border-width: 10px; }
      .mission_progress_wrapper .mission_progress_single .progress_circle .progress-right .progress-bar {
        border-top-left-radius: 75px;
        border-bottom-left-radius: 75px;
        border-width: 10px; }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-1):after {
        border-color: var(--clr-theme-1);
        transform: scale(0.95); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-1) .progress-bar {
        border-color: var(--clr-theme-1); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-1) span {
        color: var(--clr-theme-1); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-2):after {
        border-color: var(--clr-theme-2);
        transform: scale(0.95); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-2) .progress-bar {
        border-color: var(--clr-theme-2); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-2) span {
        color: var(--clr-theme-2); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-3):after {
        border-color: var(--clr-theme-3);
        transform: scale(0.95); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-3) .progress-bar {
        border-color: var(--clr-theme-3); }
      .mission_progress_wrapper .mission_progress_single .progress_circle:is(.theme-3) span {
        color: var(--clr-theme-3); }

.progress-value .value {
  font-size: 50px;
  font-weight: 700;
  color: #263b5c; }
  .progress-value .value span {
    font-size: 20px;
    display: inline-block; }

.progress_text {
  padding-left: 18px;
  font-size: 16px;
  margin-top: 20px;
  display: inline-block; }

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.progress_circle[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.5s linear forwards; }

.progress_circle[data-percentage="1"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.5s linear forwards; }

.progress_circle[data-percentage="2"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.5s linear forwards; }

.progress_circle[data-percentage="3"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.5s linear forwards; }

.progress_circle[data-percentage="4"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards; }

.progress_circle[data-percentage="5"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.5s linear forwards; }

.progress_circle[data-percentage="6"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.5s linear forwards; }

.progress_circle[data-percentage="7"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.5s linear forwards; }

.progress_circle[data-percentage="8"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.5s linear forwards; }

.progress_circle[data-percentage="9"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.5s linear forwards; }

.progress_circle[data-percentage="10"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.5s linear forwards; }

.progress_circle[data-percentage="11"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.5s linear forwards; }

.progress_circle[data-percentage="12"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.5s linear forwards; }

.progress_circle[data-percentage="13"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.5s linear forwards; }

.progress_circle[data-percentage="14"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.5s linear forwards; }

.progress_circle[data-percentage="15"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.5s linear forwards; }

.progress_circle[data-percentage="16"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.5s linear forwards; }

.progress_circle[data-percentage="17"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.5s linear forwards; }

.progress_circle[data-percentage="18"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.5s linear forwards; }

.progress_circle[data-percentage="19"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.5s linear forwards; }

.progress_circle[data-percentage="20"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.5s linear forwards; }

.progress_circle[data-percentage="21"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.5s linear forwards; }

.progress_circle[data-percentage="22"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.5s linear forwards; }

.progress_circle[data-percentage="23"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.5s linear forwards; }

.progress_circle[data-percentage="24"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.5s linear forwards; }

.progress_circle[data-percentage="25"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.5s linear forwards; }

.progress_circle[data-percentage="26"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.5s linear forwards; }

.progress_circle[data-percentage="27"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.5s linear forwards; }

.progress_circle[data-percentage="28"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.5s linear forwards; }

.progress_circle[data-percentage="29"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.5s linear forwards; }

.progress_circle[data-percentage="30"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.5s linear forwards; }

.progress_circle[data-percentage="31"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.5s linear forwards; }

.progress_circle[data-percentage="32"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.5s linear forwards; }

.progress_circle[data-percentage="33"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.5s linear forwards; }

.progress_circle[data-percentage="34"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.5s linear forwards; }

.progress_circle[data-percentage="35"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.5s linear forwards; }

.progress_circle[data-percentage="36"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.5s linear forwards; }

.progress_circle[data-percentage="37"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.5s linear forwards; }

.progress_circle[data-percentage="38"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.5s linear forwards; }

.progress_circle[data-percentage="39"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.5s linear forwards; }

.progress_circle[data-percentage="40"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.5s linear forwards; }

.progress_circle[data-percentage="41"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.5s linear forwards; }

.progress_circle[data-percentage="42"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.5s linear forwards; }

.progress_circle[data-percentage="43"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.5s linear forwards; }

.progress_circle[data-percentage="44"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.5s linear forwards; }

.progress_circle[data-percentage="45"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.5s linear forwards; }

.progress_circle[data-percentage="46"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.5s linear forwards; }

.progress_circle[data-percentage="47"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.5s linear forwards; }

.progress_circle[data-percentage="48"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.5s linear forwards; }

.progress_circle[data-percentage="49"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="50"] .progress-left .progress-bar {
  animation: 0; }

.progress_circle[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.5s linear forwards 0.5s; }

.progress_circle[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards; }

.progress_circle[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.5s linear forwards 0.5s; }

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg); } }

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg); } }

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg); } }

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg); } }

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg); } }

@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg); } }

@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg); } }

@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg); } }

@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg); } }

@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg); } }

@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg); } }

@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg); } }

@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg); } }

@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg); } }

@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg); } }

@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg); } }

@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg); } }

@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg); } }

@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg); } }

@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg); } }

@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg); } }

@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg); } }

@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg); } }

@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg); } }

@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg); } }

@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg); } }

@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg); } }

@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg); } }

@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg); } }

@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg); } }

@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg); } }

@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg); } }

@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg); } }

@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg); } }

@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg); } }

@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg); } }

@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg); } }

@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg); } }

@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg); } }

@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg); } }

@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg); } }

@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg); } }

@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg); } }

@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg); } }

@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg); } }

@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg); } }

@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg); } }

@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg); } }

@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg); } }

@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
23. Funfact
*************************
*/
.funfact_wrapper {
  padding: 0 44px; }
  @media (max-width: 767px) {
    .funfact_wrapper {
      padding: 0; } }

.funshape img {
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden; }

.shape_topright, .shape_bottomleft {
  width: 135px;
  height: 135px;
  border-radius: 50%; }

.shape_topleft {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 142px;
  left: 345px;
  border-radius: 50%; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .shape_topleft {
      left: 250px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shape_topleft {
      left: 145px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shape_topleft {
      left: 90px; } }
  @media (max-width: 767px) {
    .shape_topleft {
      display: none; } }

.shape_topright {
  position: absolute;
  top: 65px;
  right: 204px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .shape_topright {
      right: 150px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .shape_topright {
      right: 100px; } }
  @media (max-width: 767px) {
    .shape_topright {
      display: none; } }

.shape_bottomleft {
  position: absolute;
  bottom: 136px;
  left: 92px;
  z-index: -1; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .shape_bottomleft {
      display: none; } }

.shape_bottomright {
  width: 105px;
  height: 105px;
  position: absolute;
  bottom: 165px;
  right: 167px;
  border-radius: 50%;
  z-index: -1; }
  @media only screen and (min-width: 1401px) and (max-width: 1600px), only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .shape_bottomright {
      display: none; } }

.funfact_icon {
  width: 120px;
  height: 120px;
  background-color: #f15b43;
  display: inline-block;
  line-height: 120px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 55px; }

.funfact_sub {
  font-size: 16px;
  color: #ff5500;
  display: block;
  margin-bottom: 8px; }

.funfact_title_section h3, .funfact_title_section .h3 {
  line-height: 1;
  margin-bottom: 10px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .funfact_title_section h3, .funfact_title_section .h3 {
      font-size: 50px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact_title_section h3, .funfact_title_section .h3 {
      font-size: 45px; } }
  @media (max-width: 767px) {
    .funfact_title_section h3, .funfact_title_section .h3 {
      font-size: 23px;
      line-height: 1.2; } }

.funfact_title_section .funfact_text_wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .funfact_title_section .funfact_text_wrapper .funfact_bigtext {
    font-size: 120px;
    color: #f15b43;
    font-weight: 700;
    margin-right: 30px;
    line-height: 1; }
    @media (max-width: 767px) {
      .funfact_title_section .funfact_text_wrapper .funfact_bigtext {
        font-size: 90px;
        margin-right: 15px; } }
  .funfact_title_section .funfact_text_wrapper .funfact_righttext {
    font-size: 30px;
    font-weight: 700;
    color: #142645;
    line-height: 1.2;
    text-align: left; }
    @media (max-width: 767px) {
      .funfact_title_section .funfact_text_wrapper .funfact_righttext {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .funfact_title_section .funfact_text_wrapper .funfact_righttext span {
        display: block; } }

.funfact_upper {
  padding-bottom: 120px;
  position: relative; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact_upper {
      padding-bottom: 60px; } }
  @media (max-width: 767px) {
    .funfact_upper {
      padding-bottom: 40px; } }

.funfact_backtitle {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -85px;
  font-size: 270px;
  font-weight: 700;
  z-index: -1;
  background: -webkit-linear-gradient(#ffffff, #dce8eb);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .funfact_backtitle {
      font-size: 230px;
      bottom: -65px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .funfact_backtitle {
      font-size: 190px;
      bottom: -55px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact_backtitle {
      font-size: 135px;
      bottom: -40px; } }
  @media (max-width: 767px) {
    .funfact_backtitle {
      font-size: 90px;
      bottom: -20px; } }

.funfact_lower {
  box-shadow: 0px 16px 32px 0px rgba(209, 209, 209, 0.3);
  border-radius: 10px;
  background-color: #fff;
  padding: 70px;
  padding-bottom: 35px;
  display: flex;
  margin-top: -15px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .funfact_lower {
      padding: 70px 40px;
      padding-bottom: 35px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact_lower {
      flex-direction: column;
      padding: 70px 40px;
      padding-bottom: 35px; } }
  @media (max-width: 767px) {
    .funfact_lower {
      flex-direction: column;
      padding: 30px 15px;
      padding-bottom: 0; } }
  .funfact_lower .support_search_section:is(.funfact_input) {
    flex: 0 0 460px;
    margin-right: 28px; }
    @media only screen and (min-width: 1200px) and (max-width: 1400px) {
      .funfact_lower .support_search_section:is(.funfact_input) {
        flex: 0 0 375px; } }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .funfact_lower .support_search_section:is(.funfact_input) {
        flex: 0 0 auto;
        margin-right: 0; } }

.funfact_button .g_btn {
  margin-left: 28px;
  padding: 0 45px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .funfact_button .g_btn {
      padding: 0 30px; } }
  @media (max-width: 767px) {
    .funfact_button .g_btn {
      margin-left: 0; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .funfact_button .g_btn {
      padding: 0 30px;
      margin-bottom: 0;
      margin-left: 28px; } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
24. Services
*************************
*/
.services_single {
  text-align: center;
  border: 2px solid #eeeeee;
  padding: 50px;
  border-radius: 10px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .services_single {
      padding: 40px 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .services_single {
      padding: 40px; } }
  .services_single .services_text p {
    font-size: 16px;
    margin-bottom: 35px; }
  .services_single:hover {
    border-color: transparent;
    box-shadow: 0 23px 49px rgba(0, 0, 0, 0.1); }
    .services_single:hover img {
      transform: scale(0.95); }

.services_img {
  margin-bottom: 35px;
  height: 192px;
  overflow: hidden; }
  .services_img img {
    height: 100%; }

.services_title {
  text-transform: capitalize;
  font-size: 24px; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .services_title {
      font-size: 20px; } }
  @media (max-width: 767px) {
    .services_title {
      font-size: 19px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .services_title {
      font-size: 24px; } }
  .services_title:hover a {
    color: var(--clr-theme-1); }
  .services_title a {
    text-decoration: none; }

.service_btn {
  border: 2px solid #e6e6e6;
  text-align: center;
  padding: 0px 38px;
  font-size: 16px;
  font-weight: 700;
  color: var(--clr-body-heading-color);
  text-decoration: none;
  border-radius: 50px;
  display: inline-block;
  transition: 0.3s;
  height: 56px;
  line-height: 52px;
  background-color: transparent; }
  .service_btn:hover {
    background-color: var(--clr-theme-1);
    color: var(--clr-common-white);
    border-color: var(--clr-theme-1); }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
25. Events
*************************
*/
.events_img img {
  border-radius: 10px; }

.events_content {
  position: relative; }
  .events_content:hover .events_date {
    animation: icon-bounce 0.8s 1; }

.events_inner_text:is(.has_abs_card) {
  padding-left: 180px;
  padding-top: 30px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .events_inner_text:is(.has_abs_card) {
      padding-left: 160px; } }
  @media (max-width: 767px) {
    .events_inner_text:is(.has_abs_card) {
      padding-left: 0px; } }

.events_inner_text h4, .events_inner_text .h4 {
  color: #fff;
  font-size: 24px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .events_inner_text h4, .events_inner_text .h4 {
      font-size: 22px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .events_inner_text h4, .events_inner_text .h4 {
      font-size: 18px; } }
  @media (max-width: 767px) {
    .events_inner_text h4, .events_inner_text .h4 {
      font-size: 17px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .events_inner_text h4, .events_inner_text .h4 {
      font-size: 22px; } }
  .events_inner_text h4:hover, .events_inner_text .h4:hover {
    color: var(--clr-theme-1); }
  .events_inner_text h4 a, .events_inner_text .h4 a {
    text-decoration: none; }

.date_card {
  width: 120px;
  height: 120px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .date_card {
      width: 100px;
      height: 100px; } }
  .date_card:is(.feed_card) {
    width: 80px;
    height: 90px; }
    .date_card:is(.feed_card):is(.nhas_abs) {
      position: absolute;
      right: 38px;
      top: -45px;
      z-index: 2; }
      @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
        .date_card:is(.feed_card):is(.nhas_abs) {
          right: 30px; } }
  .date_card:is(.has_abs) {
    position: absolute;
    left: 0;
    top: -20px;
    z-index: 1;
    margin-left: 30px;
    margin-right: 30px; }
    @media (max-width: 767px) {
      .date_card:is(.has_abs) {
        position: static;
        margin: 0;
        margin-top: 30px; } }
  .date_card .events_date {
    font-size: 60px;
    font-weight: 700;
    color: var(--clr-theme-1);
    line-height: 1; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .date_card .events_date {
        font-size: 50px; } }
    .date_card .events_date:is(.feed_date) {
      font-size: 40px; }
  .date_card .events_month {
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
    color: #142645; }

.events_feed_wrapper {
  margin-right: 65px;
  margin-left: 30px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .events_feed_wrapper {
      margin-right: 30px;
      margin-left: 30px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .events_feed_wrapper {
      margin-right: 0px;
      margin-left: 30px; } }
  @media (max-width: 767px) {
    .events_feed_wrapper {
      margin: 0; } }

.events_feed_sigle {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #263c60; }
  @media (max-width: 767px) {
    .events_feed_sigle {
      flex-direction: column;
      align-items: flex-start; } }
  .events_feed_sigle:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0; }
  .events_feed_sigle:hover .events_date {
    animation: icon-bounce 0.8s 1; }

.efeed_card {
  margin-right: 30px;
  background-color: #0c1931; }
  @media (max-width: 767px) {
    .efeed_card {
      margin-bottom: 25px; } }
  .efeed_card .events_month {
    color: #6a7a98; }

.has_abs_card {
  position: relative; }

.category_name {
  font-size: 14px; }
  .category_name:is(.theme-1) {
    color: var(--clr-theme-1); }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
26. Testimonial
*************************
*/
.space_t30 {
  transform: translateY(30px); }
  @media (max-width: 767px) {
    .space_t30 {
      transform: translateY(0); } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .space_t30 {
      transform: translateY(30px); } }

.testimonial_area {
  z-index: 1; }
  @media (max-width: 767px) {
    .testimonial_area {
      padding-bottom: 90px; } }
  .testimonial_area:before {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(../img/bg/bg_trans.png);
    background-size: cover;
    z-index: -1; }

.testimonial_card {
  background-color: #fff;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  border: 2px solid #eeeeee;
  padding: 38px;
  padding-top: 36px;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .testimonial_card {
      padding: 30px;
      padding-top: 28px; } }
  .testimonial_card:hover {
    box-shadow: 0 23px 49px rgba(0, 0, 0, 0.1); }

.testimonial_quote {
  font-size: 18px;
  margin-bottom: 25px; }
  @media (max-width: 767px) {
    .testimonial_quote {
      font-size: 16px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .testimonial_quote {
      font-size: 16px; } }

.testimonial_author {
  display: flex;
  align-items: center; }

.author_img {
  margin-right: 20px; }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .author_img {
      margin-right: 15px; } }

.author_img img {
  border-radius: 50%; }

.review-icon a i {
  font-size: 14px;
  color: #f15b43; }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .review-icon a i {
      font-size: 12px; } }

.name_designation h5, .name_designation .h5 {
  font-size: 20px;
  color: #263b5c;
  margin-bottom: 0; }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .name_designation h5, .name_designation .h5 {
      font-size: 16px; } }
  .name_designation h5:hover, .name_designation .h5:hover {
    color: var(--clr-theme-1); }

.name_designation h5 span, .name_designation .h5 span {
  font-size: 14px;
  color: #777;
  font-weight: 400; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .name_designation h5 span, .name_designation .h5 span {
      font-size: 13px; } }
  @media (max-width: 767px) {
    .name_designation h5 span, .name_designation .h5 span {
      font-size: 13px;
      display: block; } }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
27. Back-to-top
*************************
*/
.progress-wrap {
  position: fixed;
  right: -100px;
  bottom: 50px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  box-shadow: inset 0 0 0 2px var(--clr-theme-1);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  .progress-wrap::after {
    position: absolute;
    content: '\f176';
    font-family: "Font Awesome 5 Pro";
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    color: var(--clr-theme-2);
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  .progress-wrap svg path {
    fill: none; }
  .progress-wrap svg.progress-circle path {
    stroke: var(--clr-theme-2);
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s; }

.progress-wrap.active-progress {
  opacity: 1;
  right: 50px;
  visibility: visible;
  transform: translateY(0); }

.sidebar-gallery .react-photo-gallery--gallery img {
  max-width: 32.1%; }

/* Mixin for Button style*/
/* Mixin for Animated Button*/
@keyframes icon-bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px); }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px); } }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
28. Sidebar
*************************
*/
.header-icon {
  position: relative; }
  .header-icon a i {
    transition: 0.3s; }
  .header-icon a:hover > i.fa-search {
    color: var(--clr-theme-2); }

.feed_widget_img img {
  max-width: 80px; }

.search-button {
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  display: inline-block;
  width: 40px;
  height: 100%;
  background-image: linear-gradient(45deg, transparent, var(--clr-theme-1)); }
  .search-button i {
    color: #fff; }

.offset-widget:is(.offset-logo) {
  border-bottom: 1px solid #eeecec; }

.side-row {
  margin: 0 -7.5px; }
  .side-row .col-4 {
    padding: 0 7.5px; }

.side-map iframe {
  width: 100%;
  height: 200px; }

.info-widget .offset-title {
  font-size: 20px;
  font-weight: 800; }

button.side-info-close {
  font-size: 16px;
  height: 45px;
  width: 45px;
  background-color: var(--clr-theme-1);
  color: #fff;
  border-radius: 50%; }
  button.side-info-close:hover {
    background-color: var(--clr-body-heading-color); }

.mobile_logo img {
  max-width: 150px; }

.offset_search_content {
  position: relative; }
  .offset_search_content input {
    width: 100%;
    border: 0;
    border-bottom: 1px solid #eeecec;
    padding: 5px 0;
    color: 444;
    font-weight: 300; }
    .offset_search_content input::-webkit-input-placeholder {
      color: #444;
      font-weight: 400; }
    .offset_search_content input:-moz-placeholder {
      color: #444;
      font-weight: 400; }
    .offset_search_content input::-moz-placeholder {
      color: #444;
      font-weight: 400; }
    .offset_search_content input:-ms-input-placeholder {
      color: #444;
      font-weight: 400; }

.offset_search_button {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 0;
  background-color: #eeecec;
  color: #444;
  width: 40px;
  height: 100%;
  -webkit-transition: all 0.3s linear 0s;
  -moz-transition: all 0.3s linear 0s;
  -ms-transition: all 0.3s linear 0s;
  -o-transition: all 0.3s linear 0s;
  transition: all 0.3s linear 0s; }
  .offset_search_button:hover {
    color: #fff;
    background-color: var(--clr-theme-1); }

.contact-list > a {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  color: var(--clr-theme-4); }
  .contact-list > a i {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    margin-right: 10px;
    box-shadow: 0px 16px 21px 0px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    flex: 0 0 auto; }
  .contact-list > a:is(.theme-1) i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-1); }
  .contact-list > a:is(.theme-1):hover {
    color: var(--clr-theme-1); }
  .contact-list > a:is(.theme-2) i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-2); }
  .contact-list > a:is(.theme-2):hover {
    color: var(--clr-theme-2); }
  .contact-list > a:is(.theme-3) i {
    color: var(--clr-common-white);
    background-color: var(--clr-theme-3); }
  .contact-list > a:is(.theme-3):hover {
    color: var(--clr-theme-3); }

.contact-list:is(.mobile_sm) a {
  font-size: 16px; }

/*  Single sidebar CSS  */
@media (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .single_sidebar_wrapper {
    padding-left: 0; } }

.about_person_title {
  font-size: 18px; }
  .about_person_title:hover {
    color: var(--clr-theme-1); }

.single_widget {
  padding: 48px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .single_widget {
      padding: 48px 30px; } }
  @media (max-width: 767px) {
    .single_widget {
      padding: 48px 25px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_widget {
      padding: 48px 30px; } }
  .single_widget:is(.has_border) {
    border-style: solid;
    border-width: 2px;
    border-color: #eeeeee;
    background-color: white;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06); }

.widget_title_text {
  font-size: 18px;
  color: #142645; }
  .widget_title_text:is(.has_border) {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ededed; }

.sidebar_title_text {
  font-size: 20px; }
  .sidebar_title_text span {
    padding-right: 5px;
    display: inline-block; }
    .sidebar_title_text span:is(.theme-1) {
      color: var(--clr-theme-1); }
  .sidebar_title_text:is(.has_border) {
    position: relative;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
    margin-bottom: 30px; }

.single_donor_post {
  display: flex;
  align-items: center;
  margin-bottom: 16px; }
  .single_donor_post:last-child {
    margin-bottom: 0; }
  @media (max-width: 767px) {
    .single_donor_post :is(.donar_date) {
      display: block; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_donor_post :is(.donar_date) {
      display: inline-block; } }
  @media (max-width: 767px) {
    .single_donor_post :is(.donar_amount):is(.sep) {
      padding-right: 0;
      margin-right: 0; }
      .single_donor_post :is(.donar_amount):is(.sep):after {
        display: none; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .single_donor_post :is(.donar_amount):is(.sep) {
      padding-right: 10px;
      margin-right: 10px; }
      .single_donor_post :is(.donar_amount):is(.sep):after {
        display: block; } }

.donar_post_img {
  margin-right: 18px; }

.info-widget p {
  font-size: 16px; }

.donar_name {
  font-size: 16px;
  color: #142645;
  line-height: 1.6;
  margin-bottom: 0; }
  .donar_name:hover {
    color: var(--clr-theme-1); }

.donar_amount {
  text-decoration: none;
  font-size: 14px; }
  .donar_amount:is(.sep) {
    position: relative;
    padding-right: 10px;
    margin-right: 10px; }
    .donar_amount:is(.sep):after {
      position: absolute;
      right: 0;
      top: 50%;
      width: 2px;
      height: 12px;
      transform: translateY(-50%);
      background-color: #e1e1e1;
      content: ""; }
  .donar_amount:is(.theme-1) {
    color: var(--clr-theme-1); }

.donar_date {
  text-decoration: none;
  font-size: 14px; }
  .donar_date i {
    margin-right: 5px; }
  .donar_date:is(.theme-2) i {
    color: var(--clr-theme-2); }
  .donar_date:is(.theme-2):hover {
    color: var(--clr-theme-2); }
  .donar_date:is(.sep) {
    position: relative;
    padding-right: 10px;
    margin-right: 10px; }
    .donar_date:is(.sep):after {
      position: absolute;
      right: 0;
      top: 50%;
      width: 2px;
      height: 12px;
      transform: translateY(-50%);
      background-color: #e1e1e1;
      content: ""; }

@media (max-width: 767px) {
  .donar_meta :is(.donar_date):is(.sep) {
    padding-right: 0px;
    margin-right: 0px; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .donar_meta :is(.donar_date):is(.sep) {
    padding-right: 10px;
    margin-right: 10px; } }

@media (max-width: 767px) {
  .donar_meta :is(.donar_date):is(.sep):after {
    display: none; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .donar_meta :is(.donar_date):is(.sep):after {
    display: block; } }

@media (max-width: 767px) {
  .donar_meta :is(.donar_time) {
    display: block; } }

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .donar_meta :is(.donar_time) {
    display: inline-block; } }

/* ############################
################################################
  Nice select CSS customization
##############################
###############################################   */
.nice-select:is(.widget) {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding: 0 30px;
  border-radius: 30px;
  margin-bottom: 16px;
  color: #b8b8b8;
  border: 2px solid #eee; }
  .nice-select:is(.widget):focus {
    outline: 0;
    border: 2px solid #eee; }
  .nice-select:is(.widget):after {
    color: #b8b8b8;
    font-size: 14px; }
  .nice-select:is(.widget):is(.open) .list {
    background-color: var(--clr-theme-4);
    color: #fff; }
  .nice-select:is(.widget) .list {
    background-color: var(--clr-theme-4);
    color: #fff; }
  .nice-select:is(.widget) .option:is(.focus) {
    background-color: var(--clr-theme-1); }
  .nice-select:is(.widget) .option:is(.selected):is(.focus) {
    background-color: var(--clr-theme-1); }
  .nice-select:is(.widget) .option:hover {
    background-color: var(--clr-theme-1); }

.widget_about_img {
  margin-bottom: 18px; }

.about_widget_content p {
  font-size: 16px;
  line-height: 1.63;
  margin-bottom: 23px; }
  @media (max-width: 767px) {
    .about_widget_content p {
      font-size: 14px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .about_widget_content p {
      font-size: 16px; } }

.widget_social a {
  font-size: 14px;
  margin: 0 10px;
  color: #d4d4d4; }
  .widget_social a:is(.facebook):hover {
    color: #385398; }
  .widget_social a:is(.twitter):hover {
    color: #55acee; }
  .widget_social a:is(.behance):hover {
    color: #0057FF; }
  .widget_social a:is(.youtube):hover {
    color: #C3271A; }
  .widget_social a:is(.linkedin):hover {
    color: #0A66C2; }

.search_widget_content {
  position: relative; }
  .search_widget_content input {
    width: 100%;
    height: 60px;
    padding: 0 30px;
    border: 0;
    background-color: #f5f5f5;
    border-radius: 30px;
    color: #828282;
    font-size: 12px;
    padding-right: 70px; }
    .search_widget_content input::-webkit-input-placeholder {
      font-size: 12px;
      color: #828282; }
    .search_widget_content input:-moz-placeholder {
      font-size: 12px;
      color: #828282; }
    .search_widget_content input::-moz-placeholder {
      font-size: 12px;
      color: #828282; }
    .search_widget_content input:-ms-input-placeholder {
      font-size: 12px;
      color: #828282; }
  .search_widget_content button[type="submit"] {
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    height: 60px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: var(--clr-theme-1);
    text-align: center;
    text-align: center;
    color: #fff; }

.single_feed_widget {
  display: flex;
  align-items: center; }
  .single_feed_widget:is(.has_border) {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f1f1f1; }

.feed_widget_img {
  flex: 0 0 auto;
  margin-right: 19px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .feed_widget_img {
      margin-right: 15px; } }
  @media (max-width: 767px) {
    .feed_widget_img {
      margin-right: 10px;
      flex: 0 0 70px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feed_widget_img {
      margin-right: 19px;
      flex: 0 0 auto; } }

.feed_widget_title {
  line-height: 1.37;
  font-size: 16px;
  margin-bottom: 8px; }
  @media (max-width: 767px) {
    .feed_widget_title {
      margin-bottom: 3px;
      font-size: 13px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feed_widget_title {
      margin-bottom: 5px;
      font-size: 16px; } }
  .feed_widget_title:is(.theme-1):hover {
    color: var(--clr-theme-1); }

.feed_widget_date {
  text-decoration: none;
  font-size: 14px; }
  @media (max-width: 767px) {
    .feed_widget_date {
      font-size: 12px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .feed_widget_date {
      font-size: 13px; } }
  .feed_widget_date i {
    display: inline-block;
    margin-right: 6px; }
  .feed_widget_date:is(.theme-1) i {
    color: var(--clr-theme-1); }
  .feed_widget_date:is(.theme-1):hover {
    color: var(--clr-theme-1); }

/* category widget */
.categories_widget ul li {
  list-style: none;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .categories_widget ul li a {
    text-decoration: none;
    position: relative;
    margin-left: 20px; }
    .categories_widget ul li a:after {
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translateY(-50%);
      content: "\f105";
      font-family: 'font awesome 5 pro';
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      color: var(--clr-theme-1); }
    .categories_widget ul li a:before {
      width: 0;
      right: 0;
      left: auto;
      -webkit-transition: all 0.3s linear 0s;
      -moz-transition: all 0.3s linear 0s;
      -ms-transition: all 0.3s linear 0s;
      -o-transition: all 0.3s linear 0s;
      transition: all 0.3s linear 0s;
      bottom: 0;
      height: 1px;
      background-color: var(--clr-theme-1);
      content: "";
      position: absolute; }
    .categories_widget ul li a:hover {
      color: var(--clr-theme-1); }
      .categories_widget ul li a:hover:after {
        padding-left: 5px; }
      .categories_widget ul li a:hover:before {
        left: 0;
        right: auto;
        width: 100%; }
  .categories_widget ul li:last-child {
    margin-bottom: 0; }

/* Instagram Widget */
.instagram_widget_img_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px; }
  .instagram_widget_img_wrapper img {
    width: 100%; }

/* Tagcloud Widget */
.tagcloud {
  margin-bottom: -10px; }
  .tagcloud a {
    height: 30px;
    padding: 0 20px;
    background-color: #f4f4f4;
    line-height: 30px;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 5px;
    text-decoration: none;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 500; }
    .tagcloud a:hover {
      background-color: var(--clr-theme-1);
      color: #fff; }

/* advertise widget */
.advertise_img {
  position: relative; }
  .advertise_img img {
    width: 100%; }

.advertise_abs {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 150px;
  height: 60px;
  background-color: #5fb35b;
  line-height: 60px;
  text-align: center;
  border-top-left-radius: 10px; }
  .advertise_abs span {
    font-size: 18px;
    color: #fff;
    font-weight: 700; }

/* ###########                     ###################
##############     datepicker css  #####################
################                   ##################*/
.nice-select.widget {
  float: none; }

.card-body .form-group .gj-textbox-md {
  border: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  line-height: 1.29;
  font-weight: 500; }

.gj-picker.gj-picker-md.datepicker.gj-unselectable {
  color: #222;
  border: 1px solid #f2f2f2;
  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  background: #fff; }
  .gj-picker.gj-picker-md.datepicker.gj-unselectable table tr td.today div {
    color: #fff;
    border: 1px solid var(--clr-theme-1);
    background-color: var(--clr-theme-1);
    border-radius: 50%; }
    .gj-picker.gj-picker-md.datepicker.gj-unselectable table tr td.today div:hover {
      background: var(--clr-theme-1);
      border-radius: 50%;
      color: #fff;
      border: 1px solid var(--clr-theme-1); }
  .gj-picker.gj-picker-md.datepicker.gj-unselectable table tr td.selected.gj-cursor-pointer div {
    color: #FFF;
    background: var(--clr-theme-1);
    border-radius: 50%; }

.gj-picker.gj-picker-md div[role=navigator] {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e0e0e0;
  -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1); }
  .gj-picker.gj-picker-md div[role=navigator] div[role=period] {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 16px; }
  .gj-picker.gj-picker-md div[role=navigator] div i.gj-icon {
    top: 20px; }

.gj-picker.gj-picker-md table tr td.gj-cursor-pointer div:hover {
  background: var(--clr-theme-1);
  border-radius: 50%;
  color: #fff;
  border: 1px solid var(--clr-theme-1); }

.gj-picker-md table tr td div {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle; }
  @media (max-width: 767px) {
    .gj-picker-md table tr td div {
      width: 30px;
      height: 30px;
      line-height: 30px; } }

.gj-picker-md table tr th div {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle; }
  @media (max-width: 767px) {
    .gj-picker-md table tr th div {
      width: 30px;
      height: 30px;
      line-height: 30px; } }

.gj-picker-md div[role=navigator] div i.material-icons {
  top: 20px; }

.gj-datepicker-md [role="right-icon"] {
  position: absolute;
  right: 35px;
  top: 50%;
  font-size: 14px;
  color: #b8b8b8;
  transform: translateY(-50%); }

.gj-picker {
  transform: translateY(-15px);
  z-index: 9;
  padding: 15px; }

.gj-picker.gj-picker-md div[role=navigator] {
  padding: 0 30px;
  position: relative; }
  @media (max-width: 767px) {
    .gj-picker.gj-picker-md div[role=navigator] {
      padding: 0 15px; } }
  .gj-picker.gj-picker-md div[role=navigator] .fa-angle-left {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
  .gj-picker.gj-picker-md div[role=navigator] .fa-angle-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }

/*
*****
1. variables
*************************
*/
/*    Responsive css   */
/*
*****
29. Footer
*************************
*/
.footer_top_area:is(.footer_default_area) {
  padding-top: 165px;
  overflow: hidden;
  clip-path: polygon(0 120px, 50% 0, 100% 120px, 100% 100%, 0 100%);
  background-size: cover !important;
  background-position: center !important;
  margin-bottom: -2px; }
  @media (max-width: 767px) {
    .footer_top_area:is(.footer_default_area) {
      padding-top: 200px; } }
  .footer_top_area:is(.footer_default_area):before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    content: "";
    background-color: #15253d;
    opacity: 0.9; }
  .footer_top_area:is(.footer_default_area) .footer_links ul li {
    float: left;
    width: 50%; }
    @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
      .footer_top_area:is(.footer_default_area) .footer_links ul li {
        width: 100%; } }
  .footer_top_area:is(.footer_default_area) .footer_widget:is(.pl_37) {
    padding-left: 37px; }
    @media (max-width: 767px) {
      .footer_top_area:is(.footer_default_area) .footer_widget:is(.pl_37) {
        padding-left: 0px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .footer_top_area:is(.footer_default_area) .footer_widget:is(.pl_37) {
        padding-left: 37px; } }
  .footer_top_area:is(.footer_default_area) .footer_widget:is(.pl_17) {
    padding-left: 17px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
      .footer_top_area:is(.footer_default_area) .footer_widget:is(.pl_17) {
        padding-left: 0px; } }
  .footer_locate_link ul li {
    list-style: none;
    margin-bottom: 10px; }
    .footer_locate_link ul li:last-child {
      margin-bottom: 0; }
    .footer_locate_link ul li a {
      font-size: 14px;
      color: #a2aec1;
      text-decoration: none; }
      .footer_locate_link ul li a strong {
        color: #fff;
        margin-right: 5px; }
      .footer_locate_link ul li a i {
        color: #fff;
        width: 40px;
        height: 40px;
        border: 1px solid #495360;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        margin-right: 15px; }
  .footer_locate_link:is(.theme-1) ul li a {
    transition: 0.3s; }
    .footer_locate_link:is(.theme-1) ul li a:hover {
      color: var(--clr-theme-1); }
    .footer_locate_link:is(.theme-1) ul li a i {
      transition: 0.3s; }
      .footer_locate_link:is(.theme-1) ul li a i:hover {
        color: var(--clr-theme-1);
        border-color: var(--clr-common-white); }
  .footer_links ul li {
    list-style: none;
    margin-bottom: 25px; }
    .footer_links ul li a {
      text-decoration: none;
      font-size: 16px;
      color: #a2aec1; }
  .footer_links:is(.theme-1) ul li:hover a {
    color: var(--clr-theme-1); }
  .footer_links:is(.footer_links_2) ul li {
    margin-bottom: 20px; }
    .footer_links:is(.footer_links_2) ul li a {
      font-size: 14px;
      transition: 0.3s; }
    .footer_links:is(.footer_links_2) ul li:hover a {
      color: var(--clr-common-white); }

.footer_top_area:is(.footer_top_2) {
  padding-top: 90px;
  padding-bottom: 70px;
  background-color: #111c2d; }
  .footer_top_area:is(.footer_top_2) .footer_widget:is(.pl_15) {
    padding-left: 15px; }
    @media (max-width: 767px) {
      .footer_top_area:is(.footer_top_2) .footer_widget:is(.pl_15) {
        padding-left: 0; } }
  .footer_top_area:is(.footer_top_2) .footer_widget:is(.pl_25) {
    padding-left: 25px; }
    @media (max-width: 767px) {
      .footer_top_area:is(.footer_top_2) .footer_widget:is(.pl_25) {
        padding-left: 0; } }

.footer_top_area:is(.footer_area_3) {
  background-color: #101e35;
  padding-top: 210px;
  padding-bottom: 30px; }

.footer_top_area .footer_links:is(.w_half) ul li {
  width: 50%;
  float: left; }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer_top_area .footer_links:is(.w_half) ul li {
      width: 100%; } }

.footer_widget {
  color: #a2aec1;
  overflow: hidden; }
  .footer_widget p {
    line-height: 1.7;
    font-size: 16px; }
  .footer_title {
    font-size: 20px;
    color: #fff; }
    .footer_title:is(.footer_title_2) {
      border-bottom: 1px solid #414957;
      padding-bottom: 10px;
      display: inline-block; }

.fnews_img {
  flex: 0 0 auto;
  max-width: 90px;
  object-fit: cover;
  margin-right: 20px; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fnews_img {
      max-width: 80px;
      margin-right: 15px; } }
  .fnews_img a {
    transition: 0.5s; }
    .fnews_img a:hover {
      filter: grayscale(100%); }

.fnews_meta {
  margin-top: -5px; }
  .fnews_meta .fnews_author {
    font-size: 12px;
    color: #a2aec1;
    position: relative;
    padding-right: 10px;
    margin-right: 10px;
    text-decoration: none; }
    .fnews_meta .fnews_author:is(.theme-1):hover {
      color: var(--clr-theme-1); }
    .fnews_meta .fnews_author:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 11px;
      background-color: #2c3a50;
      content: "";
      margin-right: -1px; }
  .fnews_meta .fnews_date {
    font-size: 12px;
    color: #a2aec1; }

.fnews_title {
  font-size: 18px;
  color: #fff;
  line-height: 24px;
  margin-bottom: 0; }
  @media (max-width: 767px), only screen and (min-width: 1200px) and (max-width: 1400px) {
    .fnews_title {
      font-size: 15px; } }
  .fnews_title a {
    text-decoration: none; }
  .fnews_title:is(.theme-1):hover a {
    color: var(--clr-theme-1); }

.footer_copyright_area {
  padding: 30px 0;
  background: #13233c; }
  .footer_copyright_area p {
    color: #72829c;
    margin-bottom: 0; }
    .footer_copyright_area p strong {
      color: white;
      font-weight: 700; }
    .footer_copyright_area p a {
      text-decoration: none; }
      .footer_copyright_area p a:hover {
        color: var(--clr-theme-1); }

.footer_social_2 a {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #1c293d;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  color: var(--clr-common-white);
  margin-right: 10px; }
  .footer_social_2 a:last-child {
    margin-right: 0; }
  .footer_social_2 a:is(.facebook):hover {
    background-color: #385398;
    color: var(--clr-common-white); }
  .footer_social_2 a:is(.twitter):hover {
    background-color: #65c9bb;
    color: var(--clr-common-white); }
  .footer_social_2 a:is(.behance):hover {
    background-color: #0057FF;
    color: var(--clr-common-white); }
  .footer_social_2 a:is(.youtube):hover {
    background-color: #C3271A;
    color: var(--clr-common-white); }
  .footer_social_2 a:first-child {
    margin-left: 0; }
  .footer_social_2 a:last-child {
    margin-right: 0; }

.footer_info_content span {
  font-size: 14px;
  transition: 0.3s; }
  .footer_info_content span:hover {
    color: #fff; }
    .footer_info_content span:hover strong {
      color: var(--clr-theme-1); }
  .footer_info_content span strong {
    margin-right: 5px;
    transition: 0.3s; }

.footer_info_content a {
  text-decoration: none; }

.fcta_sigle {
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-right: 35px;
  padding-left: 170px;
  align-items: center; }
  @media (max-width: 767px) {
    .fcta_sigle {
      display: block;
      padding-left: 25px;
      padding-right: 25px;
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .fcta_sigle {
      display: flex;
      padding-left: 140px;
      padding-right: 35px;
      padding-top: 0px;
      padding-bottom: 0px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .fcta_sigle {
      padding-right: 15px; } }
  .fcta_sigle:is(.has_bg) {
    background-color: #0b1523;
    border-radius: 10px; }
  .fcta_sigle img {
    position: absolute;
    left: 10px;
    bottom: 0; }
    @media (max-width: 767px) {
      .fcta_sigle img {
        position: static; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .fcta_sigle img {
        position: absolute;
        left: 10px;
        bottom: 0; } }
  .fcta_sigle h4, .fcta_sigle .h4 {
    font-size: 18px;
    color: #fff;
    margin-bottom: 8px; }
  .fcta_sigle span {
    color: #95a7c0;
    font-size: 14px; }
  .fcta_sigle .fcta_text {
    padding: 35px 0;
    padding-right: 10px; }
  .fcta_sigle:is(.pad_170) {
    padding-left: 170px; }
    @media (max-width: 767px) {
      .fcta_sigle:is(.pad_170) {
        padding-left: 25px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .fcta_sigle:is(.pad_170) {
        padding-left: 170px; } }

.fcta_button {
  height: 50px; }

.footer_btn {
  height: 60px; }

.subscribe_area {
  margin-bottom: -112px;
  position: relative; }

.footer_subscribe_wrapper {
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 10px;
  padding: 75px 80px;
  padding-bottom: 45px; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer_subscribe_wrapper {
      padding: 60px;
      padding-bottom: 30px; } }
  @media (max-width: 767px) {
    .footer_subscribe_wrapper {
      padding: 30px;
      padding-bottom: 3px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .footer_subscribe_wrapper {
      padding: 50px;
      padding-bottom: 20px; } }

.hotline_wrapper {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .hotline_wrapper {
      align-items: flex-start;
      flex-direction: column; } }
  .hotline_wrapper img {
    margin-right: 28px; }
    @media (max-width: 767px) {
      .hotline_wrapper img {
        margin-bottom: 25px; } }
  .hotline_wrapper:hover a img {
    animation: icon-bounce 0.8s 1; }

.cell_number {
  font-size: 30px;
  display: block;
  color: #142645;
  font-weight: 700;
  text-decoration: none; }
  @media only screen and (min-width: 1200px) and (max-width: 1400px) {
    .cell_number {
      font-size: 23px; } }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .cell_number {
      font-size: 18px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .cell_number {
      font-size: 26px; } }
  @media (max-width: 767px) {
    .cell_number {
      font-size: 15px; } }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .cell_number {
      font-size: 25px; } }
  .cell_number a {
    text-decoration: none; }
    .cell_number a:hover {
      color: var(--clr-theme-1); }

.subscribe_form {
  position: relative; }
  @media (max-width: 767px) {
    .subscribe_form {
      text-align: start; } }
  .subscribe_form input {
    width: 100%;
    height: 56px;
    border-radius: 50px;
    padding-left: 38px;
    border: 2px solid #e8e8e8;
    padding-right: 190px; }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      .subscribe_form input {
        padding-left: 25px; } }
    @media (max-width: 767px) {
      .subscribe_form input {
        padding-right: 0px;
        margin-bottom: 25px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .subscribe_form input {
        padding-right: 150px;
        margin-bottom: 0px; } }
    .subscribe_form input::placeholder {
      color: #c6c6c6;
      font-size: 16px; }
  .subscribe_form:is(.height_70) input {
    height: 70px;
    line-height: 70px;
    padding-right: 225px; }
    @media (max-width: 767px) {
      .subscribe_form:is(.height_70) input {
        padding-right: 38px; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .subscribe_form:is(.height_70) input {
        padding-right: 220px; } }
  .subscribe_form:is(.height_70) .submit_btn {
    height: 70px; }

.submit_btn {
  height: 56px;
  padding: 0 40px;
  border-radius: 50px;
  background-color: var(--clr-theme-1);
  color: #fff;
  transition: 0.3s; }
  .submit_btn:hover {
    background-color: var(--clr-theme-2); }
  .submit_btn:is(.abs_submit) {
    position: absolute;
    right: 0;
    top: 0; }
    @media (max-width: 767px) {
      .submit_btn:is(.abs_submit) {
        position: static; } }
    @media only screen and (min-width: 576px) and (max-width: 767px) {
      .submit_btn:is(.abs_submit) {
        position: absolute; } }
  .submit_btn i:is(.i_left) {
    margin-right: 5px; }



/* added at last  */
/* @media (max-width: 1535px) and (min-width: 1400px) {
  .movetor{
   left: 80px;
  }
} */