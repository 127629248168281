@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  .button-bg-transition {
    @apply relative inline-block bg-green-500 border border-green-500 text-white font-bold py-2 px-4 rounded overflow-hidden;
    background-image: linear-gradient(
      to right,
      green 0%,
      green 50%,
      orange 50%
    );
    background-size: 200% 100%;
    transition: background-position 0.5s ease, color 0.5s ease;
  }

  @import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

  body {
    margin: 0;
    /* font-family: "Public Sans", sans-serif !important; */
    /* font-family: Cairo; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .publicSans {
    font-family: "Public Sans", sans-serif !important;
  }

  .cairo {
    font-family: "Cairo", sans-serif;
  }

  .manrope {
    font-family: "Manrope", sans-serif;
  }

  .button-bg-transition::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to right, green 50%, orange 50%);
    background-size: 200% 100%;
    transition: background-position 0.5s ease;
    z-index: -1;
  }

  .button-bg-transition:hover::before {
    background-position: -100% 0;
  }

  .button-bg-transition:hover {
    color: black; /* Change text color on hover if needed */
  }

}
