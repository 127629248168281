@import '../abstracts/mixins';
@import '../abstracts/colors';



/*
*****
27. Back-to-top
*************************
*/

.progress-wrap {
	position: fixed;
	right: -100px;
	bottom: 50px;
	height: 46px;
	width: 46px;
	cursor: pointer;
	display: block;
	border-radius: 50px;
	box-shadow: inset 0 0 0 2px var(--clr-theme-1);
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	transform: translateY(15px);
    @include transition(.3s);
	&::after {
		position: absolute;
		content: '\f176';
		font-family: "Font Awesome 5 Pro";
		text-align: center;
		line-height: 46px;
		font-size: 20px;
		color: var(--clr-theme-2);
		left: 0;
		top: 0;
		height: 46px;
		width: 46px;
		cursor: pointer;
		display: block;
		z-index: 1;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}
	svg {
		path {
			fill: none;
		}
	}
	svg.progress-circle {
		path {
			stroke: var(--clr-theme-2);
			stroke-width: 4;
			box-sizing: border-box;
            @include transition(0.3s);
		}
	}
}
.progress-wrap.active-progress {
	opacity: 1;
    right: 50px;
    visibility: visible;
	transform: translateY(0);
}
