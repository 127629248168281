.nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
    .nice-select::after {
      content: "\f107";
      font-family: 'Font Awesome 5 Pro';
      position: absolute;
      right: 35px;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
      font-size: 16px;
    }

    .donate_section .nice-select::after {
      right: 19px;
    }
    .event_single .nice-select::after {
      right: 19px;
    }

    @media only screen and (min-width: 0px) and (max-width: 576px) {
      .nice-select::after {
        right: 23px;
      }


    }

    
    @media only screen and (min-width: 577x) and (max-width: 767px) {
      .nice-select::after {
        right: 35px;
      }
    }
    .nice-select.open::after {
      top: 50%;
      transform: translate(0, -50%) rotate(180deg);
    }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    -webkit-transform: scale(1) translateY(0);
        -ms-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0); }

  
  .support_search_section .nice-select:active, .support_search_section .nice-select.open, .support_search_section .nice-select:focus, .support_search_section .nice-select:hover {
    border-color: transparent;
  }
  
  .support_search_section.donate_section .nice-select:active, .support_search_section.donate_section .nice-select.open, .support_search_section.donate_section .nice-select:focus, .support_search_section.donate_section .nice-select:hover {
    border-color: #59ad58;
  }


  .nice-select.open .list, .nice-select .list {
    width: 100%;
    background: var(--clr-theme-4);
  }
  .donate_section .nice-select.open .list, .donate_section .nice-select .list {
    width: 100%;
    background: #3d7d3c;
  }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
        -ms-transform-origin: 50% 0;
            transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
        -ms-transform: scale(0.75) translateY(-21px);
            transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }


    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: var(--clr-body-heading-color) }

    .donate_section .nice-select .option:hover, .donate_section .nice-select .option.focus, .donate_section .nice-select .option.selected.focus {
        background-color: #59ad58; }


    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }
