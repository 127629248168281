
/*! #######################################################################

  MeanMenu 2.0.7
  --------

  To be used with jquery.meanmenu.js by Chris Wharton (http://www.meanthemes.com/plugins/meanmenu/)

####################################################################### */

/* hide the link until viewport size is reached */
a.meanmenu-reveal {
	display: none;
  }
  
  /* when under viewport size, .mean-container is added to body */
  .mean-container .mean-bar {
	float: left;
	width: 100%;
	position: relative;
	background: #0c1923;
	padding: 4px 0;
	min-height: 42px;
	z-index: 999999;
  }
  
  .mean-container a.meanmenu-reveal {
	width: 22px;
	height: 22px;
	padding: 13px 13px 11px 13px;
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	color: #fff;
	text-decoration: none;
	font-size: 16px;
	text-indent: -9999em;
	line-height: 22px;
	font-size: 1px;
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
  }
  
  .mean-container a.meanmenu-reveal span {
	display: block;
	background: #fff;
	height: 3px;
	margin-top: 3px;
  }
  
  .mean-container .mean-nav {
	float: left;
	width: 100%;
	background: #0c1923;
	margin-top: 44px;
  }
  
  .mean-container .mean-nav ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
  }
  
  .mean-container .mean-nav ul li {
	position: relative;
	float: left;
	width: 100%;
  }
  
  .mean-container .mean-nav ul li a {
	display: block;
	float: left;
	width: 90%;
	padding: 1em 5%;
	margin: 0;
	text-align: left;
	color: #fff;
	border-top: 1px solid #383838;
	border-top: 1px solid rgba(255,255,255,0.5);
	text-decoration: none;
	text-transform: uppercase;
  }
  
  .mean-container .mean-nav ul li li a {
	width: 80%;
	padding: 1em 10%;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255,255,255,0.25);
	opacity: 0.75;
	filter: alpha(opacity=75);
	text-shadow: none !important;
	visibility: visible;
  }
  
  .mean-container .mean-nav ul li.mean-last a {
	border-bottom: none;
	margin-bottom: 0;
  }
  
  .mean-container .mean-nav ul li li li a {
	width: 70%;
	padding: 1em 15%;
  }
  
  .mean-container .mean-nav ul li li li li a {
	width: 60%;
	padding: 1em 20%;
  }
  
  .mean-container .mean-nav ul li li li li li a {
	width: 50%;
	padding: 1em 25%;
  }
  
  .mean-container .mean-nav ul li a:hover {
	background: #252525;
	background: rgba(255,255,255,0.1);
  }
  
  .mean-container .mean-nav ul li a.mean-expand {
	margin-top: 1px;
	text-align: center;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
	font-weight: 700;
	background: rgba(255,255,255,0.1);
  }
  
  .mean-container .mean-nav ul li a.mean-expand:hover {
	background: rgba(0,0,0,0.9);
  }
  
  .mean-container .mean-push {
	float: left;
	width: 100%;
	padding: 0;
	margin: 0;
	clear: both;
  }
  
  .mean-nav .wrapper {
	width: 100%;
	padding: 0;
	margin: 0;
  }
  
  /* Fix for box sizing on Foundation Framework etc. */
  .mean-container .mean-bar, .mean-container .mean-bar * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
  }
  
  
  .mean-remove {
	display: none !important;
  }
  
  
  /* mobile menu css */
  .open-mobile-menu a {
	display: block;
	width: 50px;
	height: 50px;
	background: #fff;
	text-align: center;
	line-height: 50px;
	font-size: 20px;
	color: #00235A;
	position: relative;
	z-index: 9;
  }
  .open-mobile-menu a:hover {
	background: #ff5e14;
	color: #fff;
  }
  .open-mobile-menu a.active {
	background: #FF5E14;
  }.side-menu-icon i {
	color: #fff;
	font-size: 32px;
	cursor: pointer;
  }
  .side-info {
	background: #fff;
	width: 300px;
	height: 100%;
	position: fixed;
	z-index: 99;
	right: -100%;
	top: 0;
	padding: 15px 30px;
	transition: .6s;
	overflow-y: scroll;
  }
  .side-info.info-open {
	right: 0;
  }
  .side-info-close {
	background: none;
	border: 0;
	color: #222;
	font-size: 20px;
	padding: 0;
  }
  .side-info-content h1 {
	color: #fff;
	font-size: 29px;
  }
  .offcanvas-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: #000;
	height: 100%;
	width: 100%;
	z-index: 2;
	opacity: 0;
	visibility: hidden;
  }
  .offcanvas-overlay.overlay-open {
	opacity: .5;
	visibility: visible;
  }
  
  /* mean menu customize */
  .mobile-menu.mean-container {
	overflow: hidden;
  }
  .mean-container a.meanmenu-reveal {
	display: none;
  }
  .mean-container .mean-nav {
	background: none;
	margin-top: 0;
  }
  .mean-container .mean-bar {
	padding: 0;
	min-height: auto;
	background: none;
  }
  .mean-container .mean-nav > ul {
	padding: 0;
	margin: 0;
	width: 100%;
	list-style-type: none;
	display: block !important;
  }
  .mean-container a.meanmenu-reveal {
	display: none !important;
  }
  .mean-container .mean-nav ul li a {
	width: 100%;
	padding: 10px 0;
	color: #101a23;
	border-top: 1px solid #ebebeb;
	font-size: 14px;
	font-weight: 700;
  }
  .mean-container .mean-nav ul li a.mean-expand {
	margin-top: 0px;
	padding: 0 !important;
	line-height: 14px;
	border: 1px solid #ebebeb !important;
	height: 30px;
	width: 30px;
	color: #353535;
	line-height: 28px;
	top: 6px;
	font-weight: 400;
  }
  .mean-container .mean-nav ul li a.mean-expand:hover,.mean-container .mean-nav ul li a.mean-clicked {
	background: #F15B43;
	border-color: #F15B43 !important;
	color: #fff;
  }
  .mean-container .mean-nav > ul > li:first-child a{
	border-top: 0;
  }
  .mean-container .mean-nav ul ul {
	padding-left: 15px;
  }
  .mean-container .mean-nav ul li ul li a {
	width: 100%;
	padding: 12px 0;
	border-top: 1px solid #f1f1f1;
	border-top: 1px solid rgba(255,255,255,0.25);
	opacity: 1;
	filter: alpha(opacity=100);
	text-shadow: none !important;
	visibility: visible;
	border-bottom: 1px solid #ebebeb;
	line-height: 1;
	font-size: 12px;
	color: #101a23;
  }
  .mean-container .mean-nav ul li ul li:last-child a{
	border: 0;
  }
  .mean-container .mean-nav ul li a:hover{
	color: #F15B43;
  }
  
  /*contact-infos*/
  .offset-logo img {
	max-width: 190px;
  }
  .contact-infos h4 {
	font-size: 27px;
	margin-bottom: 20px;
  }
  .contact-infos ul li {
	font-size: 15px;
	margin-bottom: 15px;
	list-style: none;
  }
  .contact-infos ul li a {
	color: #00235a;
  }
  .contact-infos ul li i {
	color: #F15B43;
	vertical-align: middle;
	width: 25px;
	display: inline-block;
  }
  .sidebar__menu--social {
	margin-top: 25px;
  }
  .sidebar__menu--social a::before {
	position: absolute;
	content: "";
	top: 0px;
	left: 0px;
	height: 100%;
	width: 0%;
	background: #F15B43;
	z-index: -1;
	transform: rotate(90deg);
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
  }
  .sidebar__menu--social a:hover::before {
	width: 100%;
  }
  div.sidebar__menu--social a {
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	font-size: 13px;
	display: inline-block;
	margin-right: 1px;
	background: #393f53;
	color: #ffffff;
	position: relative;
	z-index: 1;
	overflow: hidden;
	border-radius: 5px;
  }
  .side-btn .site__btn1 {
	padding: 16px 50px;
  }


@media (min-width: 992px) {
	.side-info {
		width: 465px;
		padding: 45px;
	}	
}
  