.hamburger-menu .dropdown-menu {
  display: block !important;


}

.dropdown-menu a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin-bottom: 11px;
}


a.header-hide-tabs {
  display: block !important;
  /* Show by default on smaller than sm */
  color: #4a5568;
  /* text-gray-700 */
  padding-left: 0.75rem !important;
  /* px-3 */
  padding-right: 0.75rem !important;
  /* px-3 */
  padding-top: 0.5rem !important;
  /* py-2 */
  padding-bottom: 0.5rem !important;
  /* py-2 */
  border-radius: 0.375rem !important;
  /* rounded-md */
  font-size: 0.875rem;
  /* text-sm */
  font-weight: 500;
  /* font-medium */
}

a.header-hide-tabs:hover {
  color: #1a202c;
  /* hover:text-gray-900 */
}


.submitButtonLoader .sl-spinner2{
  height: 30px;
}

@media (min-width: 640px) {
  a.header-hide-tabs {
    display: none;
  }
}

.dropdown{
  position: relative;
}

.dropdown-list{
  position: absolute;
  z-index: 999;
}

/* Add this to your CSS file */
.blocked-day {
  background-color: #f0f0f0;
  pointer-events: none;
  color: #d0d0d0;
}
 

.hamburger-btn:hover{
  background: #edc76f !important  ;
}


.Projects .slick-slider{
  width: 100% !important;
}

body  {
  font-family: "Manrope", sans-serif !important;
}

body.ar {
  font-family: "Cairo", sans-serif;
}


.publicSans {
  font-family: "Public Sans", sans-serif !important;
}

.cairo{
  font-family: "Cairo", sans-serif;
}
.ProjectGuide i{
  color: #4599a0 !important;
transform: scale(2) !important;
}



._Charity .flex img{
  height: 100%;
}
::-moz-selection { /* Code for Firefox */
  background: rgba(50, 125, 255, 0.5); /* 50% transparent yellow background */
}

::selection {
  background: rgba(50, 125, 255, 0.5); /* 50% transparent yellow background */
}
.MuiPagination-root{
  direction: ltr !important;
}
