


@import '../abstracts/variables';
@import '../abstracts/colors';

/*
*****
06. Breadcrumb
*************************
*/

.breadcrumb_area {
    padding-top: 10px;
    padding-bottom: 132px;
}


.breadcrumb_overlay {
    position: relative;
    background-size: cover;
    background-position: center center;
    z-index: 1;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: #000;
        opacity: 0.5;
        z-index: -1;
    }
}

.breadcrumb-list {
    & li {
        display: inline-block;
        position: relative;
        padding-right: 18px;
        margin-right: 20px;
        &:before {
            position: absolute;
            right: 0;
            top: 50%;
            width: 2px;
            height: 15px;
            content: "";
            background-color: #adadad;
            transform: translateY(-50%);
        }
        &:last-child {
            padding-right: 0;
            margin-right: 0;
            & a {
                color: var(--clr-theme-1);
            }

            &:before {
                display: none;
            }
        }
        & a {
            text-decoration: none;
            color: #fff;
            font-size: 16px;
        }

        & span {
            color: var(--clr-theme-1);
            font-size: 16px;
        }
    }

    &:is(.volunteer_breadcrumb) {
        & li {
            & span.active {
                color: #ffae00;
            }
        }
    }

    
}
.breadcrumb_title {
    & h2 {
        font-size: 80px;
        color: #fff;

        
        @media #{$xl, $lg} {
            font-size: 70px;
        }

        @media #{$md} {
            font-size: 60px;
        }
        @media #{$xs} {
            font-size: 38px;
        }
        
        @media #{$sm} {
            font-size: 50px;
        }
    }
}