@import '../abstracts/colors';
@import '../abstracts/variables';


/*
*****
18. Eventcount
*************************
*/

.eventcount_area {
    margin-top: -82px;

    @media #{$xxl} {
        margin-top: -84.5px;
    }

    
    @media #{$xl} {
        margin-top: -67.5px;
    }

    @media #{$lg} {
        margin-top: -56px;
    }

    @media #{$md} {
        margin-top: -63px;
    }


}
.eventcount_wrapper {
    @media #{$md, $xs} {
        padding: 25px;
    }
}

.eventcount_img {
    overflow: hidden;

    & a {
        & img {
            width: 100%;
        }
    }
    &:hover {
        & img {
            transform: scale(1.1);
        }
    }
}


.eventcount_text {

    @media #{$xs} {
        margin-bottom: 30px;
    }


    & h5 {
        font-size: 24px;
        color: var(--clr-common-white);
        color: #fff;
        margin-bottom: 5px;

        @media #{$xl, $lg} {
            font-size: 24px;
        }

        @media #{$md, $xs} {
            font-size: 20px;
        }
    }
    & p {
        color: var(--clr-common-white);
        color: #fff;
    }

    & span {
        color: #777;
        &:is(.white_txt) {
            color: #fff;
            opacity: 0.6;
        }
    }

    &:is(.heading-clr) {
        & h5 {
            color: var(--clr-body-heading-color);
            &:hover {
                color: var(--clr-theme-2);
            }
        }
        & span {
            color: var(--clr-body-text-color);
        }
        & p {
            color: var(--clr-body-text-color);
        }
    }
}

.count_down_box {
    padding-right: 25px;

    @media #{$lg, $md, $xs} {
        padding-right: 0px;
    }

    &:is(.heading-clr) {
        & .count_down {
            color: var(--clr-body-heading-color);

            & span {
                color: var(--clr-body-text-color);
            }

            &:before {
                background-color: #e9ebee;
            }


            @media #{$xs} {
                margin-bottom: 0;
            }
        }
    }


}



.count_down {
    color: #fff;
    font-size: 45px;
    font-weight: 700;
    display: inline-block;
    width: 135px;
    text-align: center;
    position: relative;
    line-height: 1;

    @media #{$xl} {
        font-size: 35px;
        width: 100px;
    }

    @media #{$lg} {
        font-size: 35px;
        width: 100px;
    }

    @media #{$md} {
        font-size: 35px;
        width: 87px;
    }

    
    @media #{$xs} {
        font-size: 20px;
        width: 65px;
        margin-bottom: 30px;
        &:first-child {
            &:before {
                display: none;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:before {
        position: absolute;
        left: 0;
        top: 50%;
        width: 1px;
        height: 55px;
        content: "";
        background-color: #3c8df7;
        transform: translateY(-50%);

        @media #{$xs} {
            height: 30px;
        }
    }

    & span {
        font-size: 16px;
        display: block;
        font-weight: 400;
        color: #777;

        @media #{$xl, $lg, $md} {
            font-size: 18px;
        }
        @media #{$xs} {
            font-size: 12px;
        }
    }
}

.tab-border {
    border: 2px solid #f2f2f2;
    @media #{$md, $xs} {
        padding: 30px;
    }
}
.eventcount_img {
    @media #{$xs} {
        margin-bottom: 25px;
        text-align: center;
    }
}



.white_txt {
    & :is(.count_down) {
        & span {
            color: #fff;
            opacity: 0.6;
        }
    }
}