@import '../abstracts/colors';
@import '../abstracts/variables';
@import '../components/buttons';
@import '../abstracts/mixins';



/*
*****
10. About
*************************
*/


.about_area {
    &:is(.about_area_2) {
        @media #{$xs} {
            padding-top: 190px;
        }
    }
}

.about_img_wrapper {
    @media #{$xs, $sm, $md, $lg} {
        margin-right: 0;
    }
}

.about_list {
    & li {
        list-style: none;
        position: relative;
        padding-left: 37px;
        font-size: 16px;
        font-weight: 700;
        line-height: 50px;
        color: var(--clr-body-heading-color);
        transition: 0.3s;
        &:before {
            position: absolute;
            left: 0;
            top: 50%;
            content: "\f00c";
            font-family: 'Font Awesome 5 Pro';
            display: inline-block;
            transform: translateY(-50%);
            color: var(--clr-theme-2);
            font-weight: 400;
          }
    
      }

      &:is(.about_single_list) {
        & li {
            
            &:before {
                font-weight: 300;
                color: var(--clr-theme-1);

            }
        }
      }
}

.about_review {
    background-size: cover;
    position: relative;
    z-index: 1;
    padding: 60px 30px;
    width: 250px;
    @media #{$lg} {
        padding: 50px 10px;
        width: auto;
    }
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-color: var(--clr-common-bg-overlay);
        opacity: 0.9;
    }
    & .author {
        font-size: 14px;
        margin-bottom: 10px;
    }
    & .review_text {
        line-height: 1.4;
        margin-bottom: 0;
        font-size: 20px;

    }
}
.about_review * {
	position: relative;
	color: #fff;
}

.about_wrapper {
    @media #{$md, $sm, $xs} {
        padding-left: 0;
    }

    & p {
        &:is(.n_mb20) {
            margin-bottom: 20px;
        }
    }
}


.about_img {
    position: relative;
    text-align: center;
    z-index: 1;
    display: block;
    overflow: hidden;
    z-index: 1;
    &:before {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        background-image: -moz-linear-gradient( 90deg, rgba(0,0,0,0.70196) 0%, rgba(0,0,0,0) 100%);
        background-image: -webkit-linear-gradient( 90deg, rgba(0,0,0,0.70196) 0%, rgba(0,0,0,0) 100%);
        background-image: -ms-linear-gradient( 90deg, rgba(0,0,0,0.70196) 0%, rgba(0,0,0,0) 100%);
    }
    & .about_img_text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8%;
        z-index: 3;
        & i {
            font-size: 80px;
            color: #fff;
            line-height: 1.1;

            @media #{$lg, $xs} {
                font-size: 60px;
            }
          }
        & .img_text {
            font-size: 16px;
            color: var(--clr-common-white);

          }

          &:is(.theme-2) {
              & i {
                  color: var(--clr-theme-2);
              }

                & .img_text {
                    &:hover {
                        color: var(--clr-theme-2);
                    }
                    
                }
          }

          &:is(.theme-no1) {
            & i {
                color: #fecc00;
            }

            & .img_text {
                &:hover {
                    color: #fecc00;
                }
                
            }
          }

          &:is(.theme-no) {
            & i {
                color: #ffb974;
            }

            & .img_text {
                &:hover {
                    color: #ffb974;
                }
                
            }
          }
    }

    & img {
        transform: scale(1);
        transition: 0.5s;
    }

    &:hover {
        & > img {
            transform: scale(1.1);
        }
    }
  }

.about_wrapper_home {
    @media #{$xs, $md} {
        padding-left: 0;
    }
}
  
  /*   About are  2  */



.about_summary {
    margin-bottom: 50px;

    @media #{$lg} {
        margin-bottom: 30px;
    }
    & p {
        font-size: 22px;
        font-weight: 300;
        margin-bottom: 0;
        position: relative;
        padding-left: 34px;
        line-height: 1.54;

        @media #{$lg, $md} {
            font-size: 18px;
        }

        @media #{$xs} {
            font-size: 16px;
            padding-left: 25px;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: 100%;
            content: "";
            background-color: #eee;
        }

        & span {
            &:is(.theme-1) {
                color: var(--clr-theme-1);
                font-weight: 700;
            }

            &:is(.theme-2) {
                color: var(--clr-theme-2);
                font-weight: 700;
            }

            &:is(.theme-4) {
                color: var(--clr-theme-4);
                font-weight: 700;
            }

        }
    }
}



.about_img_wrapper_2 {
    position: relative;
    @media #{$lg} {
        margin-left: 0;
    }
    @media #{$md} {
        margin-bottom: 40px;
    }
    @media #{$xs} {
        margin-bottom: 40px;
        margin-left: 0;
    }


}

.about_champ_card {

    background-color: #5fb35b;
    color: #fff;
    padding: 40px 25px;
    padding-bottom: 35px;
    text-align: center;
    border: 10px solid #fff;
    border-radius: 5px;
    width: 180px;

    @media #{$lg} {
        padding: 20px 15px;
        padding-bottom: 15px;
    }

    @media #{$md} {
        border-color: #f1f1f1;
    }
    @media #{$xs} {
        padding: 20px 15px;
        padding-bottom: 15px;
    }

    &:is(.about_abs2) {
        position: absolute;
        left: -100px;
        bottom: 51px;
        z-index: 2;
        
        @media #{$lg} {
            left: 51px;
            bottom: -51px;
        }
        @media #{$xs} {
            left: 50%;
            bottom: auto;
            top: -90px;
            margin-left: -90px;
        }
    }

    & i {
        font-size: 70px;
        line-height: 1;
    }

    & span {
        font-size: 14px;
    }

    &:is(.about_abs_single) {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 200px;
        bottom: auto;

        @media #{$md, $sm} {
            left: 50%;
            border-color: #f1f1f1;
        }
    }
}


.abs_numb {
    font-size: 60px;
    color: #fff;
    position: relative;
    line-height: 1;
    margin-bottom: 0;

    & span {
        font-size: 18px;
        position: absolute;
        bottom: 10px;
    }
}



/* about single */
.about_features_area {
    @media #{$xl, $lg, $md, $xs} {
        padding-top: 105px;
    }
}

.about_wrapper {
    & > p {
        line-height: 1.67;
    }
}
.about_white_bg {
    background-color: #fff;
    max-width: 1520px;
    margin: 0 auto;
    padding-bottom: 150px;
    margin-bottom: -100px;
    position: relative;

    @media #{$xl, $lg, $md, $xs} {
        margin-bottom: 0;
        padding-bottom: 120px;
    }

}
.about_team_img {
    @media #{$xs, $md, $lg} {
        margin-bottom: 40px;
        padding-right: 0;
    }
}
.about_signle_img {
    position: relative;
    display: inline-block;
    & .small_single {
        position: absolute;
        left: 295px;
        top: 50%;
        transform: translateY(-50%);

        @media #{$xl} {
            left: 185px;
            display: block;
        }

        @media #{$xs} {
            display: none;
        }
        
        @media #{$sm} {
            left: 155px;
            display: block;
        }
    }
}




.about_feature_icon {
    position: relative;
    margin-bottom: 35px;

    & i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 120px;
        line-height: 1;
        background: -webkit-linear-gradient(#999, #fff);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }
}
   

.about_feature_wrapper {
    padding: 45px 30px;
    padding-top: 48px;
    background-color: #fff;
    text-align: center;
    border: 2px solid #e9e9e9;

    @include transition(0.5s);


    &:hover {

        box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);

        & .feature_number {
            animation: icon-bounce 0.8s 1;
        }

        & .about_feature_title {
            &:is(.theme-1) {
                color: var(--clr-theme-1);
            }
            &:is(.theme-2) {
                color: var(--clr-theme-2);
            }
            &:is(.theme-3) {
                color: var(--clr-theme-3);
            }
        }
    }
}

  

.about_feature_title {
    font-size: 20px;
    @include transition(0.3s);
}

.about_feature_text {
    & p {
        font-size: 16px;
        margin-bottom: 0;
    }
}








