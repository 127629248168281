@import '../abstracts/colors';
@import '../abstracts/variables';
@import '../abstracts/mixins';

/*
*****
12. Cause
*************************
*/


.donate_progress_wrapper {
    & label {
        font-size: 16px;
        margin-bottom: 10px;
        color: #fff;
        & span {
            font-size: 28px;
            font-weight: 700;
        }

        &:is(.event_lab) {
            color: #a4b1c4;
        }
    }
}


.progress {

    &:is(.cause_progress) {
        background-color: transparent;
        height: 4px;
        border-radius: 0;
        overflow: visible;
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: var(--clr-theme-1);
            opacity: 0.2;
        }
        & .progress-bar {
            background-color: var(--clr-theme-1);
            position: relative;
            overflow: visible;
            & span {
                position: absolute;
                font-size: 16px;
                background-color: var(--clr-theme-1);
                right: 0;
                font-size: 14px;
                color: var(--clr-common-white);
                top: 0;
                background-color: var(--clr-theme-1);
                width: 42px;
                height: 26px;
                line-height: 26px;
            }
          }
    }


    &:is(.donate_progress) {
        background-color: transparent;
        height: 15px;
        border-radius: 50px;
        overflow: visible;
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: var(--clr-common-white);
            opacity: 0.2;
            border-radius: 50px;
        }
        & .progress-bar {
            background-color: #3d7d3c;
            position: relative;
            overflow: visible;
            border-radius: 50px 0 0 50px;
            position: relative;
            z-index: 1;
            opacity: 1;
            &:is(.theme-3) {
                background-color: var(--clr-theme-3);
            }
        }



    }
      

    &:is(.feature_progress) {

        background-color: transparent;
        height: 10px;
        overflow: visible;
        position: relative;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background-color: #fff0f1;
        }
        & .progress-bar {
            background-color: var(--clr-theme-1);
            overflow: visible;
            border-radius: 0;
            position: relative;
            z-index: 1;
            opacity: 1;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);
          }

    }


    &:is(.team_progress) {
        margin-top: 15px;
        height: 4px;
        background-color: #f1f1f1;
        & .progress-bar {
            &:is(.theme-1) {
                background-color: var(--clr-theme-1);
            }
            &:is(.theme-2) {
                background-color: var(--clr-theme-2);
            }
            &:is(.theme-6) {
                background-color: var(--clr-theme-6);
            }
        }
    }


  }

.progress_value {
    float: right;
    &:is(.theme-1) {
        color: var(--clr-theme-1);
    }
    &:is(.theme-2) {
        color: var(--clr-theme-2);
    }
    &:is(.theme-6) {
        color: var(--clr-theme-6);
    }
}

.support_bg {
    box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.04);
}


.single_cause_content {
    padding: 35px 37px;
    padding-top: 27px;
    border: 2px solid;
    border-top: 0;
    border-color: rgb(238, 238, 238);;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
    background-color: var(--clr-common-white);
    @media #{$lg} {
        padding: 30px;
        padding-top: 25px;
    }
    @media #{$md} {
        padding: 30px;
        padding-top: 25px;
    }
    @media #{$xs} {
        padding: 25px;
        padding-top: 20px;
    }

    &:is(.single_cause_content_2) {
        padding: 38px;
        padding-top: 33px;

        @media #{$lg} {
            padding: 26px;
            padding-top: 22px;
        }

        @media #{$md} {
            padding: 33px;
            padding-top: 28px;
        }
        @media #{$xs} {
            padding: 25px;
            padding-top: 20px;
        }
    }

  }

.single_cause {
    .sub_title {
        font-size: 18px;
        color: var(--clr-theme-2);

      }
      & .title {
        font-size: 24px;
        line-height: 1.42;
        font-weight: 500;


        @media #{$xl} {
            font-size: 21px;
        }
        @media #{$lg} {
            font-size: 18px;
        }
        @media #{$md} {
            font-size: 21px;
        }
        @media #{$md} {
            font-size: 20px;
        }
        @media #{$xs} {
            font-size: 20px;
        }
        & a {
            text-decoration: none;
            &:hover {
                color: var(--clr-theme-1);
            }
        }
      }

      &:hover {
        & a {
            &:after {
                animation: shine 800ms;
            }

            & img {
                filter: grayscale(90%);
                transform: scale(1.1);
            }
        }
    }
}

.single_cause_img {
    overflow: hidden;
}


.img_effect_white {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    & a {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: -75%;
            z-index: 2;
            display: block;
            width: 50%;
            height: 100%;
            background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.3) 100%);
            transform: skewX(-25deg);
        }

        & img {
            transition: 0.5s;
            width: 100%;
        }
    }

}


.support_img_single, .single_nfeed, .single_team, .events_img, .nfeed_single_3, .about_video_image_wrapper {
    &:hover {
        & a {
            &:after {
                animation: shine .8s;
            }
            & img {
                filter: grayscale(90%);
                transform: scale(1.1);
            }
        }
    }
}



.events_img {
    border-radius: 10px;

}



@-webkit-keyframes shine {
    100% {
     left:125%
    }
   }
   @keyframes shine {
    100% {
     left:125%
    }
}

  



.single_meta {
    padding-right: 45px;
    @media #{$xl} {
        padding-right: 30px;
    }
    @media #{$lg} {
        padding-right: 20px;
    }
    @media #{$md} {
        padding-right: 20px;
    }
    @media #{$xs} {
        padding-right: 19px;
    }
    @media #{$sm} {
        padding-right: 45px;
    }

    &:last-child {
        padding-right: 0;
      }
    & .meta_text {
        font-size: 14px;
        color: var(--clr-common-grey);
        display: block;
        line-height: 2;
        @media #{$lg} {
            font-size: 13px;
        }
        @media #{$md} {
            font-size: 14px;
        }
        & i {
            display: inline-block;
            margin-right: 6px;
            cursor: pointer;
            transition: 0.3s;
        }
        &:is(.clr_theme1) {
            &:hover {
                & i {
                    color: var(--clr-theme-1);
                }
            }
        }
        &:is(.clr_theme2) {
            &:hover {
                & i {
                    color: var(--clr-theme-2);
                }
            }
        }
        &:is(.clr_theme3) {
            &:hover {
                & i {
                    color: var(--clr-theme-3);
                }
            }
        }

        &:is(.red_clr) {
            &:hover {
                color: #d13942;
            }
        }

        
      }
      & .meta_price {
        font-size: 18px;
        color: var(--clr-theme-1);
        font-weight: 700;
        @media #{$lg} {
            font-size: 15px;
        }
        @media #{$md} {
            font-size: 17px;
        }
        @media #{$xs} {
            font-size: 15px;
        }
        &:is(.clr_theme1) {
            color: var(--clr-theme-1);
        }
        &:is(.clr_theme2) {
            color: var(--clr-theme-2);
        }
        &:is(.clr_theme3) {
            color: var(--clr-theme-3);
        }

        &:is(.red_clr) {
            color: #d13942;
        }

        &:is(.single_price) {
            font-size: 24px;
        }

    }

  }



  /* Cause tab button style */

  .tab_buttons {
      justify-content: center;
      & button {
            background-color: #fff;
            box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            padding: 0 45px;
            height: 60px;
            line-height: 60px;
            margin-right: 30px;
            border-radius: 30px;
            font-size: 16px;
            color: #777;
            margin-bottom: 15px;

            @media #{$xs} {
                margin-right: 20px;
                padding: 0 33px;
                height: 45px;
                line-height: 45px;
                font-size: 14px;
            }

            &:is(.active) {
                background-color: var(--clr-body-heading-color);
                color: #fff;
            }

            &:hover {
                background-color: var(--clr-body-heading-color);
                color: #fff;
            }

            &:last-child {
                margin-right: 0;
            }

        }

        &:is(.feature_buttons) {
            & button {
                box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.04);
            } 
        }

    }


/*  Feature cause 3  */
  

.feature_single {
    display: flex;
    align-items: center;
    background-color: #fff;
    box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.06);

    @media #{$md} {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        padding: 70px;
    }

    @media #{$xs} {
        display: block;
        flex-direction: column;
        align-items: flex-start;
        padding: 50px 30px;
    }

    @media #{$sm} {
        display: block;
        padding: 50px 40px;
    }


    & .feature_single_img {
        flex: 0 0 498px;


        @media #{$lg} {
            flex: 0 0 420px;
        }
        @media #{$md, $xs} {
            flex: 0 0 auto;
            margin-bottom: 30px;
        }
    }

    & .feature_single_content {
        padding: 70px;
        padding-right: 100px;
        padding-top: 65px;

        @media #{$xl} {
            padding-right: 70px;
        }
        @media #{$lg} {
            padding: 0 40px;
            padding-right: 40px;
        }

        @media #{$md, $xs} {
            padding: 0;
        }

        & h4 {
            font-size: 35px;
            color: #142645;
            line-height: 1.2;

            @media #{$lg} {
                font-size: 26px;
            }

            @media #{$xs} {
                font-size: 23px;
            }
        }
        & p {
            font-size: 16px;
        }
    }
}

.feature_cause_wrapper {
padding: 0 44px;
margin-top: 25px;
margin-bottom: 43px;

    @media #{$xs} {
        padding: 0 1px;
    }
    @media #{$sm} {
        padding: 0 25px;
    }

}

.feature_buttons {
    display: flex;

    &:is(.widget_buttons) {
        justify-content: center;

        & :is(.reply_btn) {
            & i {
                transform: rotateY(180deg);
            }
        }

        & .hbtn_1 {
            padding: 0 35px;
        }
    }

    & .reply_btn {
        @media #{$xs} {
            display: none;
        }
        @media #{$sm} {
            display: block;
        }
    }
  }

.reply_btn {
    height: 60px;
    width: 60px;
    line-height: 60px;
    border: 1px solid #e7e7e7;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    margin-left: 15px;
    @include transition(0.3s);

    &:is(.theme-2) {
        &:hover {
            border-color: var(--clr-theme-2);
            background-color: var(--clr-theme-2);
            color: var(--clr-common-white);
        }
    }

    &:hover {
        border-color: var(--clr-theme-1);
        background-color: var(--clr-theme-1);
        color: var(--clr-common-white);
    }
  }


  .feature_meta {
      &:is(.feature_border) {
        padding-right: 110px;
        position: relative;

        @media #{$xl} {
            padding-right: 85px;
        }
        @media #{$lg} {
            padding-right: 70px;
        }
        @media #{$xs} {
            padding-right: 19px;
        }

        @media #{$sm} {
            padding-right: 25px;
        }

        &:after {
            position: absolute;
            right: 25px;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            background-color: #dce8eb;
            width: 60px;
            height: 2px;
            @media #{$xl} {
                right: 13px;
            }
            @media #{$lg} {
                width: 50px;
                right: 10px;
            }
            @media #{$xs} {
                display: none;
            }


          }
      }
  }


  /* cause single page css */

.single_details_content {
    border: 2px solid #eeeeee;
    border-top: 0;
    padding: 80px;
    padding-top: 70px;
    & p {
        font-size: 16px;
        line-height: 1.75;
    }

    @media #{$xxl} {
        padding: 60px 40px;
        padding-top: 50px;
    }

    @media #{$xs} {
        padding: 40px 25px;
        padding-top: 30px;
    }
    @media #{$md, $lg, $xl} {
        padding: 40px 30px;
        padding-top: 30px;
    }
}
.details_title {
    font-size: 36px;
    line-height: 1.28;

    @media #{$xs} {
        font-size: 20px;
    }
}

.sub_title_details {
    font-size: 14px;
    color: var(--clr-theme-1);
    display: block;
    line-height: 2.2;
    margin-bottom: 8px;
    text-decoration: none;
}

.single_border {
    border-bottom: 1px solid #dce8eb;
}

.submit_info_wrapper {
    @media #{$xs} {
        flex-wrap: wrap;
    }
    @media #{$sm} {
        flex-wrap: nowrap;
    }
}


.single_person_quote {
    text-align: center;
    border: 2px solid #eeeeee;
    padding: 58px 70px;
    border-radius: 10px;
    margin-bottom: 45px;
    position: relative;

    @media #{$xs} {
        padding: 58px 15px;
        margin-bottom: 45px;
    }

    & i {
        position: absolute;
        left: 0;
        right: 0;
        font-size: 290px;
        top: 0;
        background-color: transparent;
        color: #eeee;
        z-index: -1;
        @media #{$xs} {
            font-size: 220px;
        }
    }

    &:hover {
        & .person_image {
            & img {
                animation: icon-bounce 0.8s 1;
            }
        }
    }
}
.person_image {
    margin-bottom: 30px;
    & img {
        border-radius: 50%;
    }
}
.person_quote_text {
    & p {
        font-size: 20px;
        line-height: 1.7;
        @media #{$xs} {
            font-size: 14px;
        }
    }
}

.person_nam {
    font-size: 18px;
    color: #111c2d;
    line-height: 1.88;
    margin-bottom: 0;
    &:is(.f_size24) {
        font-size: 24px;
        line-height: 1.5;
    }

    &:hover {
        color: var(--clr-theme-1);
    }
}

.person_surname {
    font-size: 14px;
    color: var(--clr-theme-1);
}


.page_pagination_withimg {
    display: flex;
    justify-content: space-between;

    @media #{$xs} {
        flex-wrap: wrap;

    }
    @media #{$sm} {
        flex-wrap: nowrap;

    }
}

.img_pagination {
    display: flex;
    align-items: center;
    text-decoration: none;
    transition: 0.3s;

    & .left_img {
        margin-right: 25px;

        @media #{$xs, $md, $lg, $xl} {
            margin-right: 15px;
        }       
        @media #{$sm} {
            margin-bottom: 15px;
        }
        
    }
    & .right_img {
        margin-left: 25px;

        @media #{$md, $lg, $xl} {
            margin-left: 15px;
        }
        @media #{$xs} {
            margin-left: 0;
            margin-right: 15px;
        }
        @media #{$sm} {
            margin-bottom: 15px;
            margin-right: 0;
        }

    }

    &:hover {
        & img {
            filter: grayscale(100%);
        }
    }

    &:is(.img_pagination_left) {
        @media #{$xs} {
            margin-bottom: 30px;
        }

        @media #{$sm} {
            margin-bottom: 0px;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &:is(.img_pagination_right) {

        @media #{$xs} {
            flex-direction: row-reverse;
        }

        @media #{$sm} {
            margin-bottom: 0px;
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }
}



.sub_pagination {
    font-size: 14px;
    color: var(--clr-common-grey);
    display: block;
    margin-bottom: 5px;
}

.pagination_title {
    font-size: 24px;
    color: var(--clr-theme-7);
    margin-bottom: 0;

    @media #{$xl} {
        font-size: 18px;
    }
    @media #{$xs, $md, $lg} {
        font-size: 16px;
    }
}


.widget_person_img {
    position: relative;
    display: inline-block;
    margin-bottom: 16px;
}


.check_sign {
    display: inline-block;
    width: 28px;
    height: 28px;
    background-color: var(--clr-theme-8);
    color: var(--clr-common-white);
    font-size: 14px;
    line-height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 7px;
}

.person_designation {
    &:is(.widget_mb25) {
        margin-bottom: 25px;
    }
}

